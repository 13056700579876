<template>
  <div class="row">
    <div class="submenu col-xl-2 d-none d-xl-block">
      <div class="width-wrap sticky-top">
        <div class="row top-spacer-25">
          <div class="col-lg-12">
            <a href="/#/training/dashboard">
              <div class="menu-entry">{{ $t("Dashboard") }}</div></a
            >
          </div>
          <div class="col-lg-12">
            <a href="/#/training/kalender">
              <div class="menu-entry">{{ $t("Kalender") }}</div></a
            >
          </div>

          <div class="col-lg-12">
            <a href="/#/training/liste">
              <div class="menu-entry active_submenu">
                {{ $t("Trainingspläne") }}
              </div>
            </a>
          </div>

          <div class="col-lg-12">
            <a href="/#/training/FragDenCoach">
              <div class="menu-entry">{{ $t("Wissen") }}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-10">
      <div class="row">
        <div class="col-12 top-spacer-10" v-if="warnPremiumExpiration" style="text-align: center; color: #fff">
          <div
            class="col-12"
            style="
              background-size: 200% auto;
              padding: 20px;
              background: #000;
              border-bottom-right-radius: 10px;
              border-bottom-left-radius: 10px;
            "
          >
            <br />

            {{
              $t(
                "Hinweis: Dein Zugang ist abgelaufen. Um dauerhaft alle Funktionen nutzen zu können, kannst du über die App im Profil ein Abo abschließen."
              )
            }}

            <h2>
              {{ $t(`Die Trainingspläne stehen nur unseren zahlenden Club Mitgliedern zur Verfügung.`) }}
            </h2>
          </div>
        </div>

        <div class="col-lg-12 d-none d-md-block top-spacer-10"></div>

        <div class="col-lg-12 top-spacer-15">
          <div class="row">
            <div class="col-lg-12">
              <!--
              <div>
                <div
                  class="subimg"
                  :style="{
                    height: '170px',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    background:
                      'url(' +
                      require('@/assets/images/bike.webp') +
                      ') 30% 30%',
                  }"
                ></div>
              </div>-->
              <div class="white_wrapper top-spacer-15" style="padding-top: 30px">
                <!--
                <h3>
                  <span class="highlight">{{$t("Deine Trainingspläne")}}</span>
                </h3>
-->
                <div class="top-spacer-25 styled-table extra-styled-table" style="border: 1px solid #ddd">
                  <div v-if="activeTrainingplans.length !== 0 || !loaded" class="white_wrapper" style="padding: 20px">
                    <h5 style="text-align: center" v-if="activeTrainingplans.length !== 0">
                      {{ $t("Aktivierte Trainingspläne") }}
                    </h5>
                    <div class="row" v-if="!loaded">
                      <div class="col-lg-12 top-spacer-25 centered" style="margin-bottom: 25px">
                        <div class="spinner-border text-primary" role="status"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="activeTrainingplans.length == 0 && loaded"
                    class="white_wrapper"
                    style="padding-bottom: 15px; padding-top: 30px"
                  >
                    <h5>
                      {{ $t("Zurzeit verwendest du keinen Trainingsplan") }}
                    </h5>
                    <p style="font-size: 16px">
                      {{
                        $t(
                          `Du kannst über das Formular unten jederzeit einen oder mehrere Trainingspläne zu beliebigen Zeitpunkten verwenden. Alle Pläne sind in deiner Mitgliedschaft bereits inbegriffen.`
                        )
                      }}
                    </p>
                  </div>
                  <div class="row" v-for="plan in activeTrainingplans" :key="plan._id" style="text-align: center">
                    <div class="col-lg-4">
                      <h4 style="margin-top: 0.5rem">
                        <strong
                          ><a :href="'/#/training/vorschau/' + plan.trainingsplan._id">{{
                            plan.trainingsplan.title
                          }}</a></strong
                        >
                      </h4>
                    </div>
                    <div class="col-lg-2" style="margin-top: 0.5rem">
                      <h4>{{ plan.trainingsplan.volume }}</h4>
                    </div>
                    <div class="col-lg-3">
                      <h4 style="margin-top: 0.5rem">
                        {{ $moment(plan.start).format("DD.MM.YYYY") }} -
                        {{ $moment(plan.end).format("DD.MM.YYYY") }}
                      </h4>
                    </div>
                    <div class="col-lg-3">
                      <button @click="deactivatePlan(plan._id)">
                        {{ $t("Entfernen") }}
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  class="top-spacer-50 styled-table extra-styled-table"
                  style="border: 1px solid #ddd; text-align: center"
                >
                  <div class="row">
                    <div class="col-lg-12 top-spacer-25">
                      <h4>
                        {{ $t("Für welchen Sport suchst du einen Trainingsplan?") }}
                      </h4>
                    </div>

                    <div
                      class="col-lg-3 col-6 top-spacer-25 pointer"
                      @click="
                        sportFilter = 'Triathlon';
                        planFilter = '';
                        goToPlan();
                      "
                    >
                      <img
                        src="https://assets.pushinglimits.club/d-tri2.webp"
                        style="width: 100%; border-radius: 10px"
                      />
                      <h4 class="top-spacer-10">
                        {{ $t("Triathlon") }}
                      </h4>
                    </div>
                    <div
                      class="col-lg-3 col-6 top-spacer-25 pointer"
                      @click="
                        sportFilter = 'Radfahren';
                        planFilter = '';

                        goToPlan();
                      "
                    >
                      <img
                        src="https://assets.pushinglimits.club/d-cycle2.webp"
                        style="width: 100%; border-radius: 10px"
                      />
                      <h4 class="top-spacer-10">
                        {{ $t("Radfahren") }}
                      </h4>
                    </div>
                    <div
                      class="col-lg-3 col-6 top-spacer-25 pointer"
                      @click="
                        sportFilter = 'Laufen';
                        planFilter = '';

                        goToPlan();
                      "
                    >
                      <img
                        src="https://assets.pushinglimits.club/d-run.webp"
                        style="width: 100%; border-radius: 10px"
                      />
                      <h4 class="top-spacer-10">
                        {{ $t("Laufen") }}
                      </h4>
                    </div>
                    <div
                      class="col-lg-3 col-6 top-spacer-25 pointer"
                      @click="
                        sportFilter = 'Schwimmen';
                        planFilter = '';

                        goToPlan();
                      "
                    >
                      <img
                        src="https://assets.pushinglimits.club/d-swim2.webp"
                        style="width: 100%; border-radius: 10px"
                      />
                      <h4 class="top-spacer-10">
                        {{ $t("Schwimmen") }}
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="top-spacer-50" style="text-align: left">
                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_tp_anwenden = !show_tp_anwenden"
                  >
                    <div class="row">
                      <div class="col-10">
                        Trainingspläne richtig anwenden (Base, Build, Peak)
                        <span style="color: blue">(Neu)</span>
                      </div>

                      <div class="col-2">
                        <i v-if="show_tp_anwenden" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_tp_anwenden" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <iframe
                      v-if="show_tp_anwenden"
                      class="top-spacer-25"
                      width="100%"
                      height="500px"
                      src="https://www.youtube-nocookie.com/embed/8ggr0JBUrQk"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_niveau_video = !show_niveau_video"
                  >
                    <div class="row">
                      <div class="col-10">Wie schätze ich mein Niveau richtig ein?</div>

                      <div class="col-2">
                        <i v-if="show_niveau_video" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_niveau_video" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <iframe
                      v-if="show_niveau_video"
                      class="top-spacer-25"
                      width="100%"
                      height="500px"
                      src="https://www.youtube-nocookie.com/embed/h-Cp2G0qj3M"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_angehen_video = !show_angehen_video"
                  >
                    <div class="row">
                      <div class="col-10">Wie gehe ich einen Trainingsplan an?</div>

                      <div class="col-2">
                        <i v-if="show_angehen_video" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_angehen_video" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <iframe
                      v-if="show_angehen_video"
                      class="top-spacer-25"
                      width="100%"
                      height="500px"
                      src="https://www.youtube-nocookie.com/embed/HdFk_pDowM8"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_reihenfolge = !show_reihenfolge"
                  >
                    <div class="row">
                      <div class="col-10">In welcher Reihenfolge trainiere ich die Pläne?</div>

                      <div class="col-2">
                        <i v-if="show_reihenfolge" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_reihenfolge" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <div v-if="show_reihenfolge" class="top-spacer-25">
                      Viele Pläne sind in drei Phasen aufgeteilt:
                      <strong>Base, Build und Peak</strong>. Optimalerweise trainierst du diese in der Reihenfolge ab.
                      Der Peakplan sollte mit dem Wettkampf enden und enhält dann bereits eine Tapering-Phase. Einige
                      Pläne (zum Beispiel der 12 Wochen Mitteldistanz Peak Plan) haben zwei Wettkämpfe enthalten (einen
                      nach 8 und einen nach 12 Wochen). Schaue gerne auch in die Beschreibung der Pläne hinein.
                    </div>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_vorbereiten_video = !show_vorbereiten_video"
                  >
                    <div class="row">
                      <div class="col-10">Wie bereite ich ein Training vor und nach?</div>

                      <div class="col-2">
                        <i v-if="show_vorbereiten_video" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_vorbereiten_video" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <iframe
                      v-if="show_vorbereiten_video"
                      class="top-spacer-25"
                      width="100%"
                      height="500px"
                      src="https://www.youtube-nocookie.com/embed/DNEKHMAsmm8"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_mehr_plaene = !show_mehr_plaene"
                  >
                    <div class="row">
                      <div class="col-10">Kommen noch mehr Pläne und wann kommen diese?</div>

                      <div class="col-2">
                        <i v-if="show_mehr_plaene" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_mehr_plaene" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <div class="top-spacer-25" v-if="show_mehr_plaene">
                      Ja, wir arbeiten kontinuierlich an neuen Plänen. :-)
                    </div>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_wechseln = !show_wechseln"
                  >
                    <div class="row">
                      <div class="col-10">
                        Kann ich die Pläne auch wechseln (z.B. das Level von Anfänger auf Fortgeschritten)?
                      </div>

                      <div class="col-2">
                        <i v-if="show_wechseln" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_wechseln" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <div class="top-spacer-25" v-if="show_wechseln">
                      Du kannst jederzeit zwischen allen Plänen wechseln und sogar mehrere Pläne gleichzeitig aktiveren.
                      Das soll dir ermöglichen, genau den richtigen Plan mit der richtigen Schwierigkeit zu finden oder
                      auch ganz andere Ziele (z.B. einen Herbstmarathon) anzugehen.
                    </div>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_trainingslager = !show_trainingslager"
                  >
                    <div class="row">
                      <div class="col-10">Wie plane ich ein Trainingslager?</div>

                      <div class="col-2">
                        <i v-if="show_trainingslager" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_trainingslager" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <iframe
                      v-if="show_trainingslager"
                      class="top-spacer-25"
                      width="100%"
                      height="500px"
                      src="https://www.youtube-nocookie.com/embed/YY1yx9IW36o"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_trainingslager_richtig = !show_trainingslager_richtig"
                  >
                    <div class="row">
                      <div class="col-10">
                        Trainingslager Pläne richtig anwenden!
                        <span style="color: blue">(Neu)</span>
                      </div>

                      <div class="col-2">
                        <i
                          v-if="show_trainingslager_richtig"
                          class="fa fa-chevron-up pull-right"
                          aria-hidden="true"
                        ></i>
                        <i
                          v-if="!show_trainingslager_richtig"
                          class="fa fa-chevron-down pull-right"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>

                    <iframe
                      v-if="show_trainingslager_richtig"
                      class="top-spacer-25"
                      width="100%"
                      height="500px"
                      src="https://www.youtube-nocookie.com/embed/lUrVEOOYtcA"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </h4>
                </div>

                <div class="col-lg-12 top-spacer-25">
                  <div v-if="sportFilter == 'Triathlon'">
                    <h4>Welches Ziel hast du?</h4>
                    <div class="row top-spacer-25">
                      <div
                        class="col-lg-3 pointer col-6"
                        @click="
                          planFilter = 'olymp';
                          goToPlan();
                        "
                      >
                        <img
                          src="@/assets/images/sprint.webp"
                          style="
                            width: 100%;
                            height: 180px;
                            object-fit: cover;
                            object-position: top;
                            border-radius: 10px;
                          "
                        />
                        <h4 class="top-spacer-10">
                          {{ $t("Olympische / Sprint") }}
                        </h4>
                      </div>
                      <div
                        class="col-lg-3 pointer col-6"
                        @click="
                          planFilter = 'mittel';
                          goToPlan();
                        "
                      >
                        <img
                          src="@/assets/images/md.webp"
                          style="
                            width: 100%;
                            height: 180px;
                            object-fit: cover;
                            object-position: center;
                            border-radius: 10px;
                          "
                        />
                        <h4 class="top-spacer-10">
                          {{ $t("Halbdistanz") }}
                        </h4>
                      </div>
                      <div
                        class="col-lg-3 pointer col-6"
                        @click="
                          planFilter = 'lang';
                          goToPlan();
                        "
                      >
                        <img
                          src="@/assets/images/ld.webp"
                          style="
                            width: 100%;
                            height: 180px;
                            object-fit: cover;
                            object-position: top;
                            border-radius: 10px;
                          "
                        />
                        <h4 class="top-spacer-10">
                          {{ $t("Langdistanz") }}
                        </h4>
                        <div class="col-lg-12"><br /></div>
                      </div>
                      <div
                        class="col-lg-3 pointer col-6"
                        @click="
                          planFilter = 'trainingslager';
                          goToPlan();
                        "
                      >
                        <img
                          src="@/assets/images/trainingslager.webp"
                          style="width: 100%; height: 180px; object-fit: cover; border-radius: 10px"
                        />
                        <h4 class="top-spacer-10">
                          {{ $t("Trainingslager / Sonstige") }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row top-spacer-75" ref="plan">
                  <div class="col-lg-6 mb-3 mb-lg-0">
                    <div class="input-group">
                      <span
                        class="input-group-text"
                        style="
                          border-top-left-radius: 10px;
                          border-bottom-left-radius: 10px;
                          border-top-right-radius: 0;
                          border-bottom-right-radius: 0;
                        "
                      >
                        <i class="fa fa-search"></i>
                      </span>
                      <input
                        type="text"
                        v-model="searchQuery"
                        @input="applyFilters"
                        placeholder="Suche nach Trainingsplänen..."
                        class="form-control rounded-end"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-6 pr-1">
                        <select class="form-select" v-model="sportFilter" @change="applyFilters" style="width: 100%">
                          <option value="all">Alle Sportarten</option>
                          <option value="Triathlon">Triathlon</option>
                          <option value="Radfahren">Radfahren</option>
                          <option value="Laufen">Laufen</option>
                          <option value="Schwimmen">Schwimmen</option>
                        </select>
                      </div>
                      <div class="col-6 pl-1">
                        <select class="form-select" v-model="levelFilter" @change="applyFilters" style="width: 100%">
                          <option value="all">Alle Level</option>
                          <option value="Anfänger">Anfänger</option>
                          <option value="Fortgeschritten">Fortgeschritten</option>
                          <option value="Profi">Profi</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="styled-table extra-styled-table top-spacer-15 colored-row"
                  style="border: 1px solid #ddd; text-align: left"
                >
                  <div
                    class="row"
                    v-for="(trainingplan, index) in filteredTrainingplans"
                    :key="trainingplan._id"
                    :ref="`plan-${index}`"
                  >
                    <div class="col-xl-2 col-lg-3 col-lg-3 col-12">
                      <h4 style="margin-top: 0.5rem">
                        <span style="margin-right: 5px">
                          <span v-if="trainingplan.sport == 'Kraft'"
                            ><img src="@/assets/images/strength.png" style="width: 22px"
                          /></span>
                          <span v-if="trainingplan.sport == 'Laufen'"
                            ><img src="@/assets/images/run.png" style="width: 22px"
                          /></span>
                          <span v-if="trainingplan.sport == 'Schwimmen'"
                            ><img src="@/assets/images/swim.png" style="width: 22px"
                          /></span>
                          <span v-if="trainingplan.sport == 'Radfahren'"
                            ><img src="@/assets/images/bike.png" style="width: 22px"
                          /></span>
                          <span v-if="trainingplan.sport == 'Triathlon'"
                            ><img src="@/assets/images/tri.png" style="width: 22px"
                          /></span>
                        </span>
                        {{ trainingplan.volume }}<br />
                      </h4>
                    </div>
                    <div class="col-xl-6 col-lg-12 col-12">
                      <h4 style="margin-top: 0.5rem">
                        <strong
                          ><a :href="'/#/training/vorschau/' + trainingplan._id">{{ trainingplan.title }}</a></strong
                        ><br />
                        <small
                          >⌀
                          {{
                            $moment
                              .utc()
                              .startOf("day")
                              .add(trainingplan.averageWeeklyTrainingHours * 60, "minutes")
                              .format("HH[h]mm")
                          }}
                          pro Woche
                        </small>
                      </h4>
                    </div>
                    <!--                    <div class="col-xl-2 col-lg-3 col-12">
                      <h4 style="margin-top: 0.5rem">
                        {{ trainingplan.volume }}
                      </h4>
                    </div>-->
                    <div class="col-xl-2 col-lg-6 col-6">
                      <a :href="'/#/training/vorschau/' + trainingplan._id"
                        ><button style="width: 100%; min-width: 80px">Ansehen</button></a
                      >
                    </div>
                    <div class="col-xl-2 col-lg-6 col-6">
                      <button style="width: 100%; min-width: 80px" class="closer" @click="toggleShowApplyPlan(index)">
                        Verwenden
                      </button>
                    </div>
                    <div
                      class="col-lg-12 top-spacer-15"
                      style="background: #eee; padding-top: 15px"
                      v-if="showApplyPlan[index]"
                    >
                      <div class="row">
                        <div class="col-lg-4" style="margin-bottom: 10px">
                          <select
                            style="width: 100%"
                            v-model="applyPlanMode[index]"
                            @change="updateCalculatedDate(index)"
                          >
                            <option selected value="begin">Beginn am</option>
                            <option value="end">Ende am</option>
                          </select>
                        </div>
                        <div class="col-lg-4" style="margin-bottom: 10px">
                          <input
                            style="width: 100%"
                            type="date"
                            value="TT.MM.JJJJ"
                            placeholder="TT.MM.JJJJ"
                            v-model="applyPlanDate[index]"
                            @input="updateCalculatedDate(index)"
                          />
                          <div class="row" v-if="calculatedDate[index]">
                            <div class="col-lg-12">
                              <p class="centered">
                                <strong>{{ applyPlanMode[index] === "begin" ? "Ende" : "Beginn" }} des Plans:</strong>
                                {{ calculatedDate[index] }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4" style="margin-bottom: 10px">
                          <button
                            @click="insertPlan(trainingplan._id, index)"
                            :disabled="isInserting === trainingplan._id || isPlanActive(trainingplan._id)"
                            :class="{ 'active-plan': isPlanActive(trainingplan._id) }"
                            class="plan-action-button"
                          >
                            <span v-if="isInserting !== trainingplan._id && !isPlanActive(trainingplan._id)"
                              >Plan einfügen</span
                            >
                            <span v-else-if="isPlanActive(trainingplan._id)">Aktiv</span>
                            <span
                              v-else
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </button>
                        </div>
                      </div>

                      <!-- Add this section where you want to display the chain of plans -->
                      <div v-if="getRelatedPlans(trainingplan).length > 1">
                        <h5>Zusammenhängende Pläne:</h5>
                        <div class="plan-chain">
                          <div
                            v-for="(relatedPlan, planIndex) in getRelatedPlans(trainingplan)"
                            :key="relatedPlan._id"
                            class="chain-plan"
                            :class="[
                              getPlanClass(planIndex, getRelatedPlans(trainingplan).length),
                              { 'selected-plan': relatedPlan._id === trainingplan._id },
                            ]"
                          >
                            <h6>{{ getPlanPosition(planIndex, getRelatedPlans(trainingplan).length) }}</h6>
                            <p>{{ relatedPlan.title }}</p>
                            <p>
                              {{
                                calculatedPlanDates[relatedPlan._id] || calculatePlanDates(relatedPlan._id, planIndex)
                              }}
                            </p>
                            <div v-if="isPlanDateInPast(relatedPlan._id)" class="past-plan-note">
                              Dieser Plan liegt in der Vergangenheit
                            </div>
                            <button
                              v-else
                              @click="insertPlan(relatedPlan._id, index)"
                              :disabled="isInserting === relatedPlan._id || isPlanActive(relatedPlan._id)"
                              :class="{ 'active-plan': isPlanActive(relatedPlan._id) }"
                              class="plan-action-button"
                            >
                              <span v-if="isInserting !== relatedPlan._id && !isPlanActive(relatedPlan._id)"
                                >Einfügen</span
                              >
                              <span v-else-if="isPlanActive(relatedPlan._id)">Aktiv</span>
                              <span
                                v-else
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </button>

                            <!-- Add this section to display overlapping plans -->
                            <div v-if="getOverlappingPlans(relatedPlan._id).length > 0" class="overlapping-plans">
                              <p><strong>Überlappende Pläne:</strong></p>
                              <ul>
                                <li v-for="overlapPlan in getOverlappingPlans(relatedPlan._id)" :key="overlapPlan._id">
                                  {{ overlapPlan.trainingsplan.title }} ({{
                                    $moment(overlapPlan.start).format("DD.MM.YYYY")
                                  }}
                                  - {{ $moment(overlapPlan.end).format("DD.MM.YYYY") }})
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 d-block d-md-none top-spacer-25"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="top-spacer-50"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  metaInfo: {
    title: "Trainingspläne",
  },
  name: "Trainingspläne",
  components: {},
  props: {
    msg: String,
  },
  data: function () {
    return {
      planFilter: "",
      show_vorbereiten_video: false,
      show_angehen_video: false,
      show_niveau_video: false,
      show_wechseln: false,
      show_mehr_plaene: false,
      show_trainingslager: false,
      show_trainingslager_richtig: false,
      show_tp_anwenden: false,
      show_reihenfolge: false,
      loaded: false,
      activeTrainingplans: [],
      showApplyPlan: {},
      applyPlanMode: {},
      applyPlanDate: {},
      sessionId: undefined,
      trainingplans: [],
      filteredTrainingplans: [],

      warnPremiumExpiration: false,
      sportFilter: "all",
      levelFilter: "all",
      competitionDate: null,
      searchQuery: "",
      currentIndex: null,
      calculatedDate: {},
      calculatedPlanDates: {},
      isInserting: false,
    };
  },
  computed: {
    relatedPlans() {
      const filteredPlans = this.trainingplans.filter(
        (plan) =>
          plan.title.toLowerCase().includes(this.planFilter.toLowerCase()) && (plan.previousPlanId || plan.nextPlanId)
      );

      const planGroups = [];
      const processedPlans = new Set();

      filteredPlans.forEach((plan) => {
        if (processedPlans.has(plan._id)) return;

        const group = [];
        let currentPlan = plan;

        // Traverse to the start of the chain
        while (currentPlan.previousPlanId && !processedPlans.has(currentPlan.previousPlanId)) {
          const prevPlan = this.trainingplans.find((p) => p._id === currentPlan.previousPlanId);
          if (!prevPlan || !prevPlan.title.toLowerCase().includes(this.planFilter.toLowerCase())) break;
          currentPlan = prevPlan;
        }

        // Traverse forward and build the group
        while (currentPlan && !processedPlans.has(currentPlan._id)) {
          if (!currentPlan.title.toLowerCase().includes(this.planFilter.toLowerCase())) break;

          group.push(currentPlan);
          processedPlans.add(currentPlan._id);

          if (currentPlan.nextPlanId) {
            currentPlan = this.trainingplans.find((p) => p._id === currentPlan.nextPlanId);
          } else {
            currentPlan = null;
          }
        }

        if (group.length > 1) {
          // Only add groups with more than one plan
          planGroups.push(group);
        }
      });

      return planGroups;
    },
  },
  methods: {
    toggleShowApplyPlan(index) {
      // Reset all showApplyPlan, applyPlanDate, and calculatedDate values
      for (let i in this.showApplyPlan) {
        this.$set(this.showApplyPlan, i, false);
        this.$set(this.applyPlanDate, i, null);
        this.$set(this.calculatedDate, i, null);
      }

      // Reset calculatedPlanDates
      this.calculatedPlanDates = {};

      // Set the selected plan to true and initialize its date
      this.$set(this.showApplyPlan, index, true);
      this.$set(this.applyPlanDate, index, this.$moment().format("YYYY-MM-DD"));

      this.currentIndex = index;
      this.updateCalculatedDate(index);

      // Scroll to make the "Verwenden" button visible
      this.$nextTick(() => {
        const planElement = this.$refs[`plan-${index}`][0];
        if (planElement) {
          const rect = planElement.getBoundingClientRect();
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const targetScrollPosition = rect.top + scrollTop - 70;

          window.scrollTo({
            top: targetScrollPosition,
            behavior: "smooth",
          });
        }
      });
    },

    goToPlan() {
      this.applyFilters();
      const element = this.$refs["plan"];
      const top = element.offsetTop;
      window.scrollTo({ left: 0, top: top, behavior: "smooth" });
    },

    async deactivatePlan(planId) {
      try {
        await axios.delete(this.$host + "/trainingsplan/deactivate/" + planId);
        await this.loadActiveTrainingplan();
      } catch (error) {
        console.log(error);
      }
    },

    async loadActiveTrainingplan() {
      this.loaded = false;
      try {
        const response = await axios.get(this.$host + "/trainingsplan/list/user");
        this.activeTrainingplans = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded = true;
      }
    },

    applyFilters() {
      const searchWords = this.searchQuery
        .toLowerCase()
        .split(/\s+/)
        .filter((word) => word.length > 0);

      this.filteredTrainingplans = this.trainingplans.filter((tp) => {
        const searchFields = [tp.title.toLowerCase(), tp.volume.toLowerCase(), tp.sport.toLowerCase()];
        const matchesSearch = searchWords.every((word) => searchFields.some((field) => field.includes(word)));
        const matchesSport = this.sportFilter === "all" || tp.sport === this.sportFilter;
        const matchesLevel = this.levelFilter === "all" || tp.volume === this.levelFilter;
        const matchesPlanFilter =
          this.planFilter === "" || tp.title.toLowerCase().includes(this.planFilter.toLowerCase());
        return matchesSearch && matchesSport && matchesLevel && matchesPlanFilter;
      });

      this.filteredTrainingplans.sort((a, b) => {
        return a.volume.localeCompare(b.volume) || a.title.localeCompare(b.title);
      });
    },

    formatDate(date) {
      return this.$moment(date).format("DD.MM.YYYY");
    },

    updatePlanDates(planGroup) {
      if (!this.competitionDate) return;

      let currentDate = this.$moment(this.competitionDate);
      for (let i = planGroup.length - 1; i >= 0; i--) {
        const plan = planGroup[i];
        const duration = plan.durationWeeks * 7; // assuming duration is in weeks
        plan.endDate = currentDate.format("YYYY-MM-DD");
        plan.startDate = currentDate.subtract(duration, "days").format("YYYY-MM-DD");
        currentDate = this.$moment(plan.startDate).subtract(1, "day");
      }
    },

    async addAllPlans(planGroup) {
      for (const plan of planGroup) {
        if (plan.startDate && plan.endDate) {
          try {
            await axios.post(this.$host + "/trainingsplan/activate", {
              id: plan._id,
              applyPlanMode: "begin",
              date: plan.startDate,
            });
          } catch (error) {
            console.error("Error adding plan:", error);
          }
        }
      }
      await this.loadActiveTrainingplan();
      this.$scrollToTop();
    },

    getPlanTitle(planId) {
      const plan = this.trainingplans.find((p) => p._id === planId);
      return plan ? plan.title : "Unbekannter Plan";
    },

    getRelatedPlans(plan) {
      const relatedPlans = [];
      let currentPlan = plan;
      const processedPlanIds = new Set();

      while (currentPlan.previousPlanId && !processedPlanIds.has(currentPlan.previousPlanId)) {
        const prevPlan = this.trainingplans.find((p) => p._id === currentPlan.previousPlanId);
        if (!prevPlan) break;
        processedPlanIds.add(currentPlan._id);

        currentPlan = prevPlan;
        relatedPlans.push(currentPlan);
      }

      // reverse the array
      relatedPlans.reverse();

      relatedPlans.push(plan);
      currentPlan = plan;

      while (currentPlan.nextPlanId && !processedPlanIds.has(currentPlan.nextPlanId)) {
        const nextPlan = this.trainingplans.find((p) => p._id === currentPlan.nextPlanId);
        if (!nextPlan) break;
        processedPlanIds.add(currentPlan._id);

        currentPlan = nextPlan;
        relatedPlans.push(currentPlan);
      }

      return relatedPlans;
    },

    getPlanPosition(index, total) {
      if (index === 0) return "Erster Plan:";
      if (index === total - 1) return "Letzter Plan:";
      return `Plan ${index + 1}:`;
    },

    getPlanClass(index, total) {
      if (index === 0) return "first-plan";
      if (index === total - 1) return "last-plan";
      if (index < Math.floor(total / 2)) return "early-middle-plan";
      return "late-middle-plan";
    },

    calculatePlanDates(planId, position) {
      const plan = this.trainingplans.find((p) => p._id === planId);
      if (!plan) return "";

      const relatedPlans = this.getRelatedPlans(plan);
      const index = this.currentIndex !== undefined ? this.currentIndex : position;
      const selectedPlanIndex = relatedPlans.findIndex((p) => p._id === planId);
      console.log(
        "Selected plan index:",
        selectedPlanIndex,
        index,
        this.currentIndex,
        relatedPlans[selectedPlanIndex].title,
        relatedPlans[selectedPlanIndex]._id,
        planId
      );
      if (!this.applyPlanDate[index]) {
        this.$set(this.applyPlanDate, index, this.$moment().format("YYYY-MM-DD"));
      }

      let anchorDate = this.$moment(this.applyPlanDate[index]);
      let dates = {};

      if (this.applyPlanMode[index] === "begin") {
        // Calculate for the selected plan
        let startDate = anchorDate.clone();
        let endDate = startDate.clone().add(plan.weeks * 7 - 1, "days");
        dates[planId] = `${startDate.format("DD.MM.YYYY")} - ${endDate.format("DD.MM.YYYY")}`;

        // Calculate for plans after the selected plan
        let currentDate = endDate.clone().add(1, "days");
        for (let i = selectedPlanIndex + 1; i < relatedPlans.length; i++) {
          startDate = currentDate.clone();
          endDate = startDate.clone().add(relatedPlans[i].weeks * 7 - 1, "days");
          dates[relatedPlans[i]._id] = `${startDate.format("DD.MM.YYYY")} - ${endDate.format("DD.MM.YYYY")}`;
          currentDate = endDate.clone().add(1, "days");
        }

        // Calculate for plans before the selected plan
        currentDate = anchorDate.clone().subtract(1, "days");
        for (let i = selectedPlanIndex - 1; i >= 0; i--) {
          endDate = currentDate.clone();
          startDate = endDate.clone().subtract(relatedPlans[i].weeks * 7 - 1, "days");
          dates[relatedPlans[i]._id] = `${startDate.format("DD.MM.YYYY")} - ${endDate.format("DD.MM.YYYY")}`;
          currentDate = startDate.clone().subtract(1, "days");
        }
      } else {
        // Calculate for the selected plan
        let endDate = anchorDate.clone();
        let startDate = endDate.clone().subtract(plan.weeks * 7 - 1, "days");
        dates[planId] = `${startDate.format("DD.MM.YYYY")} - ${endDate.format("DD.MM.YYYY")}`;

        // Calculate for plans before the selected plan
        let currentDate = startDate.clone().subtract(1, "days");
        for (let i = selectedPlanIndex - 1; i >= 0; i--) {
          endDate = currentDate.clone();
          startDate = endDate.clone().subtract(relatedPlans[i].weeks * 7 - 1, "days");
          dates[relatedPlans[i]._id] = `${startDate.format("DD.MM.YYYY")} - ${endDate.format("DD.MM.YYYY")}`;
          currentDate = startDate.clone().subtract(1, "days");
        }

        // Calculate for plans after the selected plan
        currentDate = anchorDate.clone().add(1, "days");
        for (let i = selectedPlanIndex + 1; i < relatedPlans.length; i++) {
          startDate = currentDate.clone();
          endDate = startDate.clone().add(relatedPlans[i].weeks * 7 - 1, "days");
          dates[relatedPlans[i]._id] = `${startDate.format("DD.MM.YYYY")} - ${endDate.format("DD.MM.YYYY")}`;
          currentDate = endDate.clone().add(1, "days");
        }
      }

      this.calculatedPlanDates = dates;
      this.forceUpdate();
      return dates[planId] || "";
    },

    updateCalculatedDate(index) {
      const plan = this.filteredTrainingplans[index];

      if (!plan || !this.applyPlanDate[index]) return;

      const durationInDays = plan.weeks * 7 - 1; // Subtract 1 to get the correct end date
      let calculatedDate;

      if (this.applyPlanMode[index] === "begin") {
        calculatedDate = this.$moment(this.applyPlanDate[index]).add(durationInDays, "days");
      } else {
        calculatedDate = this.$moment(this.applyPlanDate[index]).subtract(durationInDays, "days");
      }

      this.$set(this.calculatedDate, index, calculatedDate.format("DD.MM.YYYY"));

      // Recalculate dates for all related plans
      const relatedPlans = this.getRelatedPlans(plan);
      relatedPlans.forEach((relatedPlan, position) => {
        this.calculatePlanDates(plan._id, position);
      });
      this.forceUpdate();
    },

    forceUpdate() {
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },

    isPlanActive(planId) {
      const planDates = this.calculatedPlanDates[planId];
      if (!planDates) return false;

      const [startDate, endDate] = planDates.split(" - ");
      const planStart = this.$moment(startDate, "DD.MM.YYYY");
      const planEnd = this.$moment(endDate, "DD.MM.YYYY");

      return this.activeTrainingplans.some(
        (activePlan) =>
          activePlan.trainingsplan._id === planId &&
          this.$moment(activePlan.start).isSame(planStart, "day") &&
          this.$moment(activePlan.end).isSame(planEnd, "day")
      );
    },

    isPlanDateInPast(planId) {
      const planDates = this.calculatedPlanDates[planId];
      if (!planDates) return false;

      const [startDate] = planDates.split(" - ");
      return this.$moment(startDate, "DD.MM.YYYY").isBefore(this.$moment(), "day");
    },

    getOverlappingPlans(planId) {
      const planDates = this.calculatedPlanDates[planId];
      if (!planDates) return [];

      const [startDate, endDate] = planDates.split(" - ");
      const planStart = this.$moment(startDate, "DD.MM.YYYY");
      const planEnd = this.$moment(endDate, "DD.MM.YYYY");

      return this.activeTrainingplans.filter((activePlan) => {
        const activeStart = this.$moment(activePlan.start);
        const activeEnd = this.$moment(activePlan.end);
        return (
          activePlan.trainingsplan._id !== planId &&
          ((activeStart.isSameOrBefore(planEnd) && activeEnd.isSameOrAfter(planStart)) ||
            (planStart.isSameOrBefore(activeEnd) && planEnd.isSameOrAfter(activeStart)))
        );
      });
    },

    async insertPlan(planId, index) {
      this.isInserting = planId; // Use planId to track which plan is being inserted
      const scrollPosition = window.pageYOffset;

      try {
        this.currentIndex = index;
        const plan = this.trainingplans.find((p) => p._id === planId);
        if (!plan) return;

        const planDates = this.calculatedPlanDates[planId];
        if (!planDates) return;

        const [startDate] = planDates.split(" - ");

        await axios.post(this.$host + "/trainingsplan/activate", {
          id: planId,
          applyPlanMode: "begin",
          date: this.$moment(startDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
        });

        // Update the active training plans
        await this.loadActiveTrainingplan();
      } catch (error) {
        console.error("Error adding plan:", error);
      } finally {
        this.isInserting = false;
        this.$nextTick(() => {
          window.scrollTo(0, scrollPosition);
        });
      }
    },
  },

  watch: {
    // Remove watches for sportFilter, planFilter, and levelFilter
  },

  mounted: async function () {
    this.loaded = false;

    try {
      const userResponse = await axios.get(this.$host + "/user/me");
      const user = userResponse.data;

      if (
        !this.$moment(user.premiumAccountUntil).isAfter(this.$moment()) &&
        !this.$moment(user.freeAccountUntil).isAfter(this.$moment())
      ) {
        this.warnPremiumExpiration = true;
      }

      const plansResponse = await axios.get(this.$host + "/trainingsplan/list_active");
      this.trainingplans = plansResponse.data;

      this.applyFilters(); // Apply filters after loading the training plans
      await this.loadActiveTrainingplan();
    } catch (error) {
      console.log(error);
    } finally {
      this.loaded = true;
    }
  },

  created: function () {
    // Initialize applyPlanMode for up to 30 plans
    for (let i = 0; i < 150; i++) {
      this.applyPlanMode[i] = "begin";
    }
  },

  destroyed() {
    //window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.button-b {
  padding: 5px 15px;
  background: #000;
  color: #fff;
  border-radius: 5px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 10px;
}

.overflow-auto {
  -webkit-overflow-scrolling: touch;
}

.section-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.plan-groups {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.plan-group {
  background: linear-gradient(135deg, #f6f9fc 0%, #f1f5f9 100%);
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.plan-group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.plan-group-heading {
  font-size: 1.2rem;
  color: #2c3e50;
  margin: 0;
}

.plan-group-actions {
  display: flex;
  gap: 0.5rem;
}

.date-input {
  padding: 0.3rem 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
}

.btn-add-all {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0.3rem 0.8rem;
  border-radius: 6px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-add-all:hover {
  background-color: #45a049;
}

.plan-chain-wrapper {
  width: 100%;
  overflow-x: auto;
}

.plan-chain {
  display: flex;
  gap: 1rem;
  padding: 0.5rem 0;
  min-width: 100%;
}

.plan-card {
  flex: 1 0 200px;
  max-width: 300px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.plan-card-content {
  padding: 0.75rem;
  flex-grow: 1;
}

.plan-card-title {
  font-size: 1rem;
  color: #2c3e50;
  margin-bottom: 0.25rem;
}

.plan-card-category {
  font-size: 0.8rem;
  color: #64748b;
  margin-bottom: 0.25rem;
}

.plan-card-dates {
  font-size: 0.8rem;
  color: #3b82f6;
  margin-bottom: 0.5rem;
}

.btn-view {
  display: inline-block;
  background-color: #3b82f6;
  color: white;
  padding: 0.3rem 0.8rem;
  border-radius: 6px;
  font-size: 0.8rem;
  text-decoration: none;
  transition: background-color 0.3s;
}

.btn-view:hover {
  background-color: #2563eb;
}

.plan-card-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  color: #64748b;
  font-size: 0.8rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.plan-chain-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.plan-chain-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.input-group-text {
  background-color: #f8f9fa;
  border-right: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.form-control {
  border-left: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
  border-left: none;
}

.input-group .form-control:focus + .input-group-text {
  border-color: #ced4da;
}

.form-select {
  border-radius: 10px;
}

@media (max-width: 991px) {
  .col-6.pr-1 {
    padding-right: 5px;
  }

  .col-6.pl-1 {
    padding-left: 5px;
  }
}

@media (max-width: 768px) {
  .plan-group-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .plan-group-actions {
    margin-top: 1rem;
    width: 100%;
  }

  .competition-date-picker {
    width: 100%;
  }

  .date-input {
    width: 100%;
  }

  .btn-add-all {
    width: 100%;
    margin-top: 0.5rem;
  }
}

.plan-chain {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 1rem;
  padding: 1rem 0;
}

.chain-plan {
  flex: 0 0 auto;
  width: 220px;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.first-plan {
  border-left: 4px solid #28a745; /* Green */
}

.early-middle-plan {
  border-left: 4px solid #ffc107; /* Yellow */
}

.late-middle-plan {
  border-left: 4px solid #17a2b8; /* Cyan */
}

.last-plan {
  border-left: 4px solid #dc3545; /* Red */
}

.selected-plan {
  background-color: #e6f7ff; /* Light blue background */
  border: 2px solid #1890ff; /* Blue border */
  box-shadow: 0 0 10px rgba(24, 144, 255, 0.3); /* Subtle blue glow */
}

.plan-action-button {
  width: 100%;
  min-height: 38px; /* Ensure consistent height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-plan {
  background-color: #28a745;
  color: white;
  border-color: #28a745;
}

.active-plan:hover,
.active-plan:focus {
  background-color: #218838;
  border-color: #1e7e34;
}

.active-plan:disabled {
  background-color: #28a745;
  border-color: #28a745;
  opacity: 0.65;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.past-plan-note {
  color: #dc3545;
  font-style: italic;
  margin-top: 0.5rem;
}

.overlapping-plans {
  margin-top: 1rem;
  font-size: 0.9em;
  color: #6c757d;
}

.overlapping-plans ul {
  padding-left: 1.2rem;
  margin-bottom: 0;
}

.overlapping-plans li {
  margin-bottom: 0.3rem;
}
</style>
