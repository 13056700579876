<template>
  <div class="workout-svg-container" @click="$emit('click')" v-if="workoutFile && workoutFile.length != 0">
    <svg class="workout-svg" :viewBox="`0 0 ${getTotalDuration(workoutFile)} ${svgHeight}`" preserveAspectRatio="none">
      <g v-for="(segment, segmentIndex) in workoutFile" :key="segment.id">
        <template v-if="segment.type === 'SteadyState'">
          <rect
            :x="getSegmentX(workoutFile, segmentIndex)"
            :y="300 - (segment.Power * 300) / maxPower"
            :width="segment.Duration"
            :height="Math.max(10, (segment.Power * 300) / maxPower)"
            :style="'fill:' + colorForPower(segment.Power)"
          />
        </template>
        <template v-else-if="segment.type === 'Ramp'">
          <polygon
            :points="getRampPoints(workoutFile, segmentIndex, segment, 300)"
            :style="'fill:' + colorForPower((segment.PowerLow + segment.PowerHigh) / 2)"
          />
        </template>
        <template v-else-if="segment.type === 'IntervalsT' || segment.type === 'IntervalsRest'">
          <g v-for="repeatIndex in segment.Repeat" :key="`${segment.id}-${repeatIndex}`">
            <rect
              :x="
                getSegmentX(workoutFile, segmentIndex) + (repeatIndex - 1) * (segment.OnDuration + segment.OffDuration)
              "
              :y="300 - (segment.OnPower * 300) / maxPower"
              :width="segment.OnDuration"
              :height="Math.max(10, (segment.OnPower * 300) / maxPower)"
              :style="'fill:' + colorForPower(segment.OnPower)"
            />
            <rect
              :x="
                getSegmentX(workoutFile, segmentIndex) +
                (repeatIndex - 1) * (segment.OnDuration + segment.OffDuration) +
                segment.OnDuration
              "
              :y="300 - (segment.OffPower * 300) / maxPower"
              :width="segment.OffDuration"
              :height="Math.max(10, (segment.OffPower * 300) / maxPower)"
              :style="'fill:' + colorForPower(segment.OffPower)"
            />
          </g>
        </template>
        <template v-else-if="segment.type === 'FreeRide' || segment.type === 'Rest'">
          <rect
            :x="getSegmentX(workoutFile, segmentIndex)"
            y="0"
            :width="segment.Duration"
            height="160"
            style="fill: rgba(94, 110, 255, 0.5)"
          />
        </template>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "WorkoutSvg",
  props: {
    workoutFile: {
      type: Array,
      required: true,
    },
    svgHeight: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      maxPower: 120, // You might want to make this a prop or compute it based on workoutFile
    };
  },
  computed: {
    scaleFactor() {
      return 1; // Remove scaling factor as we're using the actual duration
    },
  },
  methods: {
    getTotalDuration(workoutFile) {
      return workoutFile.reduce((total, segment) => {
        if (segment.type === "IntervalsT") {
          return total + (segment.OnDuration + segment.OffDuration) * segment.Repeat;
        }
        return total + (segment.Duration || segment.Distance / 15);
      }, 0);
    },
    getSegmentX(workoutFile, index) {
      let x = 0;
      for (let i = 0; i < index; i++) {
        const segment = workoutFile[i];
        if (segment.type === "IntervalsT") {
          x += (segment.OnDuration + segment.OffDuration) * segment.Repeat;
        } else {
          x += segment.Duration || segment.Distance / 15;
        }
      }
      return x;
    },
    colorForPower(power) {
      if (power > 105) return "darkred";
      if (power > 100) return "brown";
      if (power > 95) return "firebrick";
      if (power > 88) return "darkgreen";
      if (power > 83) return "green";
      return "rgba(94, 110, 255, 0.97)";
    },
    getRampPoints(workoutFile, index, segment, height = 200) {
      const x = this.getSegmentX(workoutFile, index);
      const y1 = height - (segment.PowerLow * height) / this.maxPower;
      const y2 = height - (segment.PowerHigh * height) / this.maxPower;
      return `${x},${y1} ${x + segment.Duration},${y2} ${x + segment.Duration},300 ${x},300`;
    },
  },
};
</script>

<style scoped>
.workout-svg-container {
  width: 100%;
  height: 60px;
  transition: opacity 0.3s ease;
  background-color: #f0f0f0;
}

svg {
  transform: scaleY(1);
}

.workout-svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .workout-svg-container {
    height: 30px;
  }
}
</style>
