<template>
  <div style="overflow-x: hidden; margin: -15px; margin-top: 0">
    <div
      class="desktop-border-radius"
      :style="{
        height: '100%',
        width: '100%',
        minHeight: '650px',

        background: 'url(https://assets.pushinglimits.club/swimdalle.webp) no-repeat ' + 'top/cover ',
      }"
    >
      <div class="row">
        <div class="col-lg-5 col-xl-4 col-md-7">
          <div class="white_wrapper light-shadow" style="margin: 25px">
            <div class="centered">
              <img
                class="mainlogo"
                alt="PL logo"
                style="box-shadow: none; width: 180px"
                v-bind:src="
                  $client.getClient() == 'pushinglimits'
                    ? require('@/assets/images/PL-Club-Logo.png')
                    : require('@/assets/images/' + $client.getClient() + '.jpg')
                "
              />
            </div>

            <div v-if="loginError" class="top-spacer-25">
              <h5>
                <span style="color: red">{{ $t("loginError") }}</span>
                <hr />
                <small>{{ $t("passwordNote") }}</small>
              </h5>
            </div>
            <form class="login top-spacer-25" @submit.prevent="login">
              <h2>{{ $t("loginHeading") }}</h2>
              <input
                class="top-spacer-15"
                required
                v-model="email"
                ref="email"
                name="email"
                type="email"
                placeholder="deine@email.de"
              />
              <input
                class="top-spacer-10"
                required
                ref="password"
                name="pw"
                v-model="password"
                type="password"
                placeholder="Passwort"
              />

              <button style="width: 300px" class="top-spacer-10" type="submit">Login</button>
              <br /><br />
              <strong
                ><a href="/#/passwortreset">{{ $t("Passwort vergessen?") }}</a>
                &nbsp;/&nbsp;
                <a href="/#/register">{{ $t("Noch keinen Account?") }}</a></strong
              >
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 top-spacer-50">
        <h3>{{ $t("Noch keinen Account?") }}</h3>
        <a href="/#/register"
          ><button style="width: 350px">
            {{ $t("Neuen Account anlegen!") }}
          </button></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Capacitor } from "@capacitor/core";

export default {
  metaInfo: {
    title: "Login",
  },
  data() {
    return {
      platform: Capacitor.getPlatform(),
      email: "",
      password: "",
      loginError: false,
    };
  },
  methods: {
    login: function () {
      const _self = this;
      let email = this.email || this.$refs.email.value;
      let password = this.password || this.$refs.password.value;

      if (this.platform != "web") {
        this.$store
          .dispatch("login", { email, password })
          .then(() => {
            this.$parent.updateStore();
            this.$router.push("/training/dashboard");
          })
          .catch(function (err) {
            _self.loginError = true;
            console.log(err);
          });
      } else {
        this.$store
          .dispatch("session_login", { email, password })
          .then(() => this.$router.push("/training/dashboard"))
          .catch(function (err) {
            _self.loginError = true;
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
input {
  width: 300px !important;
}

.mainlogo {
  max-width: 200px;
}

a {
  color: blue;
}
</style>
