<template>
  <div>
    <div v-if="selectedCalendarWorkout != undefined && isMenuOpen" class="overlay" @click="hideOverlay()"></div>
    <div v-if="selectedCalendarWorkout != undefined && isMenuOpen" class="overlay-box" @keydown.esc="hideOverlay()">
      <div style="text-align: right; z-index: 9999999999999999; position: sticky; top: 45px">
        <button
          style="
            min-width: 40px;
            min-height: 40px;
            background-color: rgba(0, 0, 0);
            border: 0;
            color: #fff;
            padding: 2px;
            margin-right: 20px;
            border-radius: 50px;
            transform: translate3d(0, 0, 0);
          "
          class="closer"
          type="submit"
          @click="hideOverlay()"
        >
          X
        </button>
      </div>
      <div class="row">
        <div class="col-lg-12" style="margin: 0; border-bottom: 0">
          <div class="clearfix" style="padding: 10px">
            <h3 class="top-spacer-25">{{ $t("Einheit bearbeiten") }}</h3>
          </div>

          <div style="padding: 30px; padding-top: 10px">
            <new-workout
              :key="selectedCalendarWorkout._id"
              v-bind:thresholds="thresholds"
              v-bind:workout="selectedCalendarWorkout"
              @closed="hideOverlay"
              @wasSaved="hideOverlay"
              @workoutDeleted="workoutDeleted"
            ></new-workout>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="submenu col-xl-2 d-none d-xl-block">
        <div class="width-wrap sticky-top">
          <div class="row top-spacer-25">
            <div class="col-lg-12">
              <a href="/#/training/dashboard">
                <div class="menu-entry active_submenu">
                  {{ $t("Dashboard") }}
                </div></a
              >
            </div>
            <div class="col-lg-12">
              <a href="/#/training/kalender">
                <div class="menu-entry">{{ $t("Kalender") }}</div></a
              >
            </div>

            <div class="col-lg-12">
              <a href="/#/training/liste">
                <div class="menu-entry">{{ $t("Trainingspläne") }}</div>
              </a>
            </div>

            <div class="col-lg-12">
              <a href="/#/training/FragDenCoach">
                <div class="menu-entry">{{ $t("Wissen") }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-10 col-12">
        <div class="md-block xs-none top-spacer-15"></div>

        <div class="row">
          <vue-pull-refresh :on-refresh="onRefresh" :config="pullDownConfig" style="overflow-x: hidden; width: 100%">
            <div class="col-lg-12 dashboard-top">
              <div class="row">
                <div class="col-xl-7">
                  <div class="white_wrapper_tighter" v-if="information.length > 0" style="margin-bottom: 25px">
                    <h4>{{ $t("HINWEIS") }}</h4>
                    {{ information
                    }}<!-- Ggfs. wichtige Nachricht vom Server geladen-->
                  </div>

                  <div v-if="findNewFtp() > 0 && thresholds && findNewFtp() != thresholds.ftp">
                    <div class="white_wrapper_tighter" style="padding: 25px; padding-bottom: 10px; margin-bottom: 25px">
                      <div class="col-lg-12">
                        <div style="border-radius: 10px; margin-bottom: 50px">
                          <h4>{{ $t("Neue FTP erkannt:") }} {{ findNewFtp() }}w</h4>
                          <button v-if="!ftpUpdated" @click="setNewFtp(findNewFtp())" style="padding: 2px 5px">
                            {{ $t("Speichern") }}
                          </button>
                          <br class="d-block d-md-none" />
                          &nbsp;<br class="d-block d-md-none" />
                          <button
                            v-if="!ftpUpdated"
                            @click="unsetNewFtp(findNewFtpWorkout())"
                            style="padding: 2px 5px"
                            class="delete"
                          >
                            {{ $t("Verwerfen") }}
                          </button>
                          <span v-if="ftpUpdated" style="color: green"
                            ><h4>{{ $t("Neue FTP wurde gesetzt!") }}</h4></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="white_wrapper_tighter">
                    <div class="d-flex justify-content-between align-items-center">
                      <h4 style="text-align: left; text-decoration: underline">{{ $t("Heutiger Plan") }}</h4>
                      <!--
                      <button @click="showAICoachPreferences = !showAICoachPreferences" class="btn btn-sm">
                        <img
                          style="width: 25px"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEhklEQVR4nO2aX4hVVRTGf+OYpnYdgzI0skgyifKpiFSKGGMqCIOQlMKgh7AXy16KCgptnGkiLKJAepAigsAp7H9kBD0oSREZlhKmNY7FBDVZOhbYjQ3fgcXh/Fn7nHvvhPjBgcPea699vn333uvba184g9MPVwObgI+AEeAYcAr4EXgROB+YC7wE/KS6P4AfgLeA9aqfNKwA9gDNkuc7YH+JzQQwAEzvJIEpwPPAv/qI34CtwB3AQmA2MA1YAuwzH7tPZaGuAVyiNq8CJ2XzOXBup4hsVaf/ABuBcwps7zJE1hTYLRSJJvCJBqutWKnOwjq43mF/uSGyqMQ2DEjyC95DmxBG7CngqGN0LeYYIj0O+9tl+yvwHHAtLcIUTZ9kDofnYORPn7Tz9ncwtRF8AFxMDUwFtptdZVBTJRYxRBJcCmzQdp1sKL1UxLNy8gtwTVUnFYnYdbNN7Y8Dy4nEcm2vE6l5GrbOp7VWxvQefrlWEAl+hrQ+RhVXQn9dwBb5GNG6c2OXGj6ZKh/MCGYDLSISBiXte7PquoFPVfaCl8QSMy9npupGVbcUuLUgUmcR8T63AMv0HvpLcIXkzQmvpNkkJ0EnkUNkmSRKO4j0GiJHUr52qPx+D5GdMg57ehoDJVOriEgZsqZtf8rmXpUHsVmKIzJekFE3TR8+qsU+qPnbCiLJYh/TN/SrP1LTK/g64CEyIeMZHmPHR9fZftNoyNefOHAiQlKkUTT3W4GZJqaUIommV/0PiSySr0Me43dlvKpFnWcRsYHVBr8yrJWv4VJL4GEZvxYRfWOJDBYEvyIMyzYcj91niPGMo2dR9I0hYgNrVvDLwjwd5kJQnE+kRImNvl4iY8ZPElhDWREeld17ROChGtG36tQKZXnoVtYl2N0WI51H1OixCtHXQ2RqSiUMlKjohjkGvyNFXIpHTIMq0ddDxFOXxkVGddyNA1/KOPoQ02YiNvnxGQ4ck3FRmicNj6otaudFj+x/9xgfl3FIdXaKiLf9LL3/5fmoPbG7Q41RjyVymd7DDlaKB2T8RUTap5XTp6j9g3oPB6xSnA18qwZvOBPLnSAy3yiC1d7G4VB1WI32AneW/DqdIDJSNTccsnsfGkfhvuO6jLhi9VdWeiiPiFeANs3zuoJjJWyUk+8zOhrKWJRDTiJeAbo/JzUVjZfl6L6MOiv+enPEX7OmAN1QoDSi8JUcBYlQlB7KE5HNmgJ0Qc4AReOAHJ3nTA/1O6eWV4DONgnDWnhbjsIxsyg9lCciyxZ7mQDtU/vddYmsNj/tlRXa19l+L9Rlami/jproMqnKkCp6RtcMjTYQmaW7kRW61hg3avcsWoDpygWfSs3po45DjodIl7nWa6ae7bFXCR4sVlz52IzWDY6EWlnm8kbZnFTc2Kn1E/6M0HasUuff5OxodqGG56YcmwuMtlvJJCDonff1AT8Dj+t2q0ej32f0WpId7FPdHMmdJ0xQfdN7Fm8HGoq4RQFvh9nC855hLfRJx83AK9oqw9r5W6p5vVI53Xr/WnXjst2mHeoMTjv8B53HcCW4EiPaAAAAAElFTkSuQmCC"
                        />
                       KI Coach
                      </button>-->
                      <AICoachPreferences v-if="showAICoachPreferences" />

                      <button @click="collapseTodaysWorkouts" class="btn btn-sm">
                        <i :class="['fa', showToday ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
                        {{ showToday ? $t("Einklappen") : $t("Ausklappen") }}
                      </button>
                    </div>

                    <b-collapse id="todaysWorkouts" v-model="showToday">
                      <div class="weather-forecast-container">
                        <h4 style="text-align: center; width: 100%">{{ $t("Wettervorhersage") }}</h4>

                        <weather-forecast
                          v-if="weather_prediction_all[todayDate]"
                          :forecast="{ [todayDate]: weather_prediction_all[todayDate] }"
                          :single-day="true"
                        />
                      </div>

                      <div class="feeling-section" v-if="todaysWorkouts.length" style="margin-bottom: 25px">
                        <h4>
                          {{ $t("Wie fühlst du dich heute?") }}
                          <!--<i class="fa fa-info-circle" @click="toggleCoachInfo" style="cursor: pointer"></i>-->
                        </h4>
                        <b-modal v-model="showCoachInfo" title="Coach Informationen" ok-only>
                          <div v-if="coachResults">
                            {{ coachResults }}
                            <h5>Empfehlungen:</h5>
                            <ul>
                              <li v-for="(message, index) in coachResults.loadMessages" :key="index">{{ message }}</li>
                            </ul>
                            <h5>Warnungen:</h5>
                            <ul>
                              <li v-for="(warning, index) in coachResults.warnings" :key="index">{{ warning }}</li>
                            </ul>
                            <h5>Statistiken:</h5>
                            <ul>
                              <li>Gefühl: {{ coachResults.feeling }}/5</li>
                              <li>Ramp-Wert: {{ coachResults.rampValue.toFixed(2) }}</li>
                              <li>Optimale Belastung: {{ coachResults.optimalLoad.toFixed(2) }}</li>
                              <li>Empfohlene Intensität: {{ coachResults.intensity }}</li>
                              <li>LIT Anteil: {{ coachResults.percentageLit.toFixed(2) }}%</li>
                              <li>HIT Anteil: {{ coachResults.percentageHit.toFixed(2) }}%</li>
                              <li>LIT Anteil (24h): {{ coachResults.percentageLit24h.toFixed(2) }}%</li>
                              <li>HIT Anteil (24h): {{ coachResults.percentageHit24h.toFixed(2) }}%</li>
                            </ul>
                          </div>
                        </b-modal>
                        <div class="feeling-buttons">
                          <button
                            v-for="feeling in feelings"
                            :key="feeling"
                            @click="selectFeeling(feeling)"
                            :class="['feeling-button', { active: $parent.selectedFeeling === feeling }]"
                          >
                            {{ $t(feeling) }}
                          </button>
                        </div>
                      </div>

                      <div v-if="!loaded" class="spinner-border text-primary top-spacer-15" role="status"></div>

                      <div v-if="coachResults && !coachLoading">
                        <adjusted-workout-plan
                          v-if="todaysWorkoutsAlternative.length && runThreshold"
                          :workouts="todaysWorkoutsAlternative"
                          :coach-results="coachResults"
                          :thresholds="thresholds"
                          :is-mobile="isMobile"
                          :feeling-button="$parent.selectedFeeling"
                          :runThreshold="runThreshold"
                          @show-overlay="showOverlay"
                          @adjust-workout="adjustWorkout"
                          @accepted-alternative="updateData"
                          @workouts-deleted="updateData"
                        />
                      </div>

                      <div class="col-lg-12 centered" v-if="todaysWorkouts.length == 0 && thresholds && loaded">
                        {{
                          $t(
                            `Heute noch keine Einheiten geplant. Öffne den Kalender und lege dir Einheiten in deinen Plan oder verwende direkt einen unserer Trainingspläne oder den KI-Coach.`
                          )
                        }}
                      </div>
                      <div class="row" v-if="todaysWorkouts.length > 0 && thresholds">
                        <div v-for="value in todaysWorkouts" :key="value._id" class="col-12 mb-5 top-spacer-25">
                          <div class="row d-md-none">
                            <div class="col-12">
                              <h4 @click="showOverlay(value._id)" style="text-align: center; cursor: pointer">
                                {{ value.name }}
                              </h4>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 col-md-4 mb-3">
                              <div
                                class="workout-svg-container-wrapper d-md-none"
                                @click="showOverlay(value._id)"
                                style="cursor: pointer; display: flex; justify-content: center; align-items: center"
                              >
                                <div
                                  class="workout-svg-container"
                                  v-if="
                                    value.sport != 'Kraft' &&
                                    value.sport != 'Schwimmen' &&
                                    value.workoutFile &&
                                    value.workoutFile.length != 0
                                  "
                                  style="max-width: 300px; width: 100%"
                                >
                                  <workout-svg
                                    v-if="
                                      value.sport != 'Kraft' &&
                                      value.sport != 'Schwimmen' &&
                                      value.workoutFile &&
                                      value.workoutFile.length != 0
                                    "
                                    :workout-file="value.workoutFile"
                                    @click="showOverlay(value._id)"
                                    style="width: 100%; height: 60px"
                                  />
                                </div>
                              </div>

                              <intensity-distribution-view
                                :workout="value"
                                :thresholds="thresholds"
                                @click="showOverlay(value._id)"
                                class="d-md-none top-spacer-15"
                              />
                              <intensity-distribution-view
                                :workout="value"
                                :thresholds="thresholds"
                                @click="showOverlay(value._id)"
                                class="d-none d-md-block"
                              />
                              <!-- Add the Radraum link here -->
                              <hr />
                              <a style="width: 200px" class="btn btn-primary btn-sm" @click="showOverlay(value._id)">
                                Details
                              </a>

                              <div v-if="value.sport === 'Radfahren'" class="mt-2">
                                <a
                                  :href="`/#/radraum/${value._id}`"
                                  class="btn btn-secondary btn-sm"
                                  style="width: 200px"
                                >
                                  {{ $t("Im Radraum fahren") }}
                                </a>
                              </div>
                            </div>
                            <div class="col-12 col-md-8">
                              <div>
                                <div v-if="value.sport == 'Kraft'" class="top-spacer-25"></div>
                                <div class="row d-none d-md-block">
                                  <div class="col-12">
                                    <h4 @click="showOverlay(value._id)" style="text-align: center; cursor: pointer">
                                      {{ value.name }}
                                    </h4>
                                  </div>
                                </div>

                                <div
                                  class="workout-svg-container-wrapper d-none d-md-block"
                                  @click="showOverlay(value._id)"
                                  style="cursor: pointer; display: flex; justify-content: center; align-items: center"
                                >
                                  <div
                                    class="workout-svg-container"
                                    v-if="
                                      value.sport != 'Kraft' &&
                                      value.sport != 'Schwimmen' &&
                                      value.workoutFile &&
                                      value.workoutFile.length != 0
                                    "
                                    style="width: 100%"
                                  >
                                    <workout-svg
                                      v-if="
                                        value.sport != 'Kraft' &&
                                        value.sport != 'Schwimmen' &&
                                        value.workoutFile &&
                                        value.workoutFile.length != 0
                                      "
                                      :workout-file="value.workoutFile"
                                      @click="showOverlay(value._id)"
                                      style="width: 100%; height: 80px"
                                    />
                                  </div>
                                </div>

                                <div class="table-responsive top-spacer-25">
                                  <table class="table table-sm">
                                    <tbody>
                                      <tr v-if="value.sport">
                                        <th scope="row">Sport</th>
                                        <td>{{ value.sport }}</td>
                                      </tr>
                                      <tr v-if="value.durationShould">
                                        <th scope="row">Dauer (Soll)</th>
                                        <td>{{ formatDuration(value.durationShould) }}</td>
                                      </tr>
                                      <tr v-if="value.distanceShould">
                                        <th scope="row">Distanz (Soll)</th>
                                        <td>{{ value.distanceShould }} km</td>
                                      </tr>
                                      <tr v-if="value.paceShould">
                                        <th scope="row">Tempo (Soll)</th>
                                        <td>
                                          <template v-if="value.sport === 'Radfahren'">
                                            {{ formatSpeed(value.paceShould) }} km/h
                                          </template>
                                          <template v-else> {{ formatPace(value.paceShould) }} /km </template>
                                        </td>
                                      </tr>
                                      <!-- Add power (Leistung) target value -->
                                      <tr v-if="value.powerShould">
                                        <th scope="row">Leistung (Soll)</th>
                                        <td>{{ value.powerShould }} W</td>
                                      </tr>
                                      <!-- Add power (Leistung) actual value -->
                                      <tr v-if="value.powerIs">
                                        <th scope="row">Leistung (Ist)</th>
                                        <td>{{ value.powerIs }} W</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                                <div class="table-responsive top-spacer-25" v-if="value.sport != 'Kraft'">
                                  <table class="table table-md table-bordered" style="text-align: left">
                                    <thead>
                                      <tr>
                                        <th scope="col" style="width: 15%">Typ</th>
                                        <th scope="col" style="width: 30%">Segment</th>
                                        <th scope="col" style="width: 30%">Zielwerte</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(segment, index) in value.workoutFile" :key="segment.id + index">
                                        <td>
                                          <span v-if="segment.type == 'Ramp'">Rampe</span>
                                          <span v-if="segment.type == 'SteadyState'">Steady State</span>
                                          <span v-if="segment.type == 'IntervalsT' || segment.type == 'IntervalsRest'"
                                            >Intervalle</span
                                          >
                                          <span v-if="segment.type == 'FreeRide'">Beliebig</span>
                                          <span v-if="segment.type == 'Rest'">Pause</span>
                                        </td>
                                        <td>
                                          <span v-if="value.sport !== 'Schwimmen'">
                                            <span v-if="segment.type == 'Ramp'">
                                              {{
                                                segment.Distance
                                                  ? `${segment.Distance}m`
                                                  : formatDuration(segment.Duration)
                                              }}
                                              @ {{ segment.PowerLow }}% - {{ segment.PowerHigh }}%
                                            </span>
                                            <span v-else-if="segment.type == 'SteadyState'">
                                              {{
                                                segment.Distance
                                                  ? `${segment.Distance}m`
                                                  : formatDuration(segment.Duration)
                                              }}
                                              @ {{ segment.Power }}%
                                            </span>
                                            <span
                                              v-else-if="
                                                segment.type == 'IntervalsT' || segment.type == 'IntervalsRest'
                                              "
                                            >
                                              <strong>{{ segment.Repeat }}x</strong><br />
                                              {{
                                                segment.OnDistance
                                                  ? `${segment.OnDistance}m`
                                                  : formatDuration(segment.OnDuration)
                                              }}
                                              @ {{ segment.OnPower }}% <br />
                                              {{
                                                segment.OffDistance
                                                  ? `${segment.OffDistance}m`
                                                  : formatDuration(segment.OffDuration)
                                              }}
                                              @ {{ segment.OffPower }}%
                                            </span>
                                            <span v-else-if="segment.type == 'FreeRide' || segment.type == 'Rest'">
                                              {{ formatDuration(segment.Duration) }}
                                              {{ segment.Distance ? `- ${segment.Distance}m` : "" }}
                                            </span>
                                          </span>
                                          <span v-else>
                                            <span v-if="segment.type == 'SteadyState'"> {{ segment.Distance }}m </span>
                                            <span
                                              v-else-if="
                                                segment.type == 'IntervalsT' || segment.type == 'IntervalsRest'
                                              "
                                            >
                                              {{ segment.Repeat }}x{{ segment.Distance }}m
                                              <span v-if="segment.Rest">/ Pause: {{ segment.Rest }}s</span>
                                            </span>
                                            <span v-else-if="segment.type == 'Rest'">
                                              {{ formatDuration(segment.Duration) }} Rest
                                            </span>
                                          </span>
                                        </td>
                                        <td>
                                          <span v-if="value.sport == 'Radfahren'">
                                            <span v-if="segment.type == 'Ramp'">
                                              {{ Math.round((segment.PowerLow * thresholds.ftp) / 100) }}w -
                                              {{ Math.round((segment.PowerHigh * thresholds.ftp) / 100) }}w
                                            </span>
                                            <span v-else-if="segment.type == 'SteadyState'">
                                              {{ Math.round((segment.Power * thresholds.ftp) / 100) }}w
                                            </span>
                                            <span
                                              v-else-if="
                                                segment.type == 'IntervalsT' || segment.type == 'IntervalsRest'
                                              "
                                            >
                                              {{ Math.round((segment.OnPower * thresholds.ftp) / 100) }}w /
                                              {{ Math.round((segment.OffPower * thresholds.ftp) / 100) }}w
                                            </span>
                                          </span>
                                          <span v-else-if="value.sport == 'Laufen'">
                                            <span v-if="segment.type == 'Ramp'">
                                              {{ formatPace(thresholds.threshold_run / (segment.PowerLow / 100)) }}
                                              -
                                              {{ formatPace(thresholds.threshold_run / (segment.PowerHigh / 100)) }}
                                              /km
                                            </span>
                                            <span v-else-if="segment.type == 'SteadyState'">
                                              {{ formatPace(thresholds.threshold_run / (segment.Power / 100)) }} /km
                                            </span>
                                            <span
                                              v-else-if="
                                                segment.type == 'IntervalsT' || segment.type == 'IntervalsRest'
                                              "
                                            >
                                              {{ formatPace(thresholds.threshold_run / (segment.OnPower / 100)) }} /
                                              {{ formatPace(thresholds.threshold_run / (segment.OffPower / 100)) }}
                                              /km
                                            </span>
                                          </span>
                                          <span v-else-if="value.sport == 'Schwimmen'">
                                            <span
                                              v-if="segment.type == 'SteadyState' || segment.type == 'IntervalsRest'"
                                            >
                                              <span v-if="segment.Description">{{ segment.Description }}</span>
                                            </span>
                                          </span>
                                          <br v-if="segment.type != 'FreeRide' && segment.type != 'Rest'" />
                                          <small
                                            v-if="
                                              segment.type != 'FreeRide' &&
                                              segment.type != 'Rest' &&
                                              value.sport != 'Schwimmen'
                                            "
                                          >
                                            <div v-if="segment.OnPower">
                                              {{ Math.round(intensityToHeartRate(segment.OnPower).low) }}-{{
                                                Math.round(intensityToHeartRate(segment.OnPower).high)
                                              }}
                                              bpm und {{ Math.round(intensityToHeartRate(segment.OffPower).low) }}-{{
                                                Math.round(intensityToHeartRate(segment.OffPower).high)
                                              }}
                                              bpm
                                            </div>

                                            <div v-else>
                                              {{
                                                Math.round(intensityToHeartRate(segment.Power || segment.PowerLow).low)
                                              }}
                                              bis
                                              {{
                                                Math.round(
                                                  intensityToHeartRate(segment.Power || segment.PowerHigh).high
                                                )
                                              }}
                                              bpm
                                            </div>
                                          </small>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                                <!-- Add the helpful videos section here -->
                                <div v-if="value.videoUrls && value.videoUrls.length" class="top-spacer-25">
                                  <hr />
                                  <div class="top-spacer-15"></div>
                                  <b-accordion :id="'accordion-' + value._id" class="custom-accordion">
                                    <b-card
                                      no-body
                                      v-for="(video, index) in value.videoUrls"
                                      :key="video.uri"
                                      class="mb-1"
                                    >
                                      <b-card-header
                                        header-tag="header"
                                        class="p-0"
                                        role="tab"
                                        v-b-toggle="'accordion-' + value._id + '-' + index"
                                      >
                                        <div class="accordion-button d-flex justify-content-between align-items-center">
                                          <span>{{ video.title }}</span>
                                          <i class="fa fa-chevron-up"></i>
                                        </div>
                                      </b-card-header>
                                      <b-collapse
                                        :id="'accordion-' + value._id + '-' + index"
                                        accordion="accordion-videos"
                                        role="tabpanel"
                                      >
                                        <b-card-body>
                                          <div class="embed-responsive embed-responsive-16by9">
                                            <iframe
                                              class="embed-responsive-item"
                                              :src="video.uri"
                                              allowfullscreen
                                            ></iframe>
                                          </div>
                                        </b-card-body>
                                      </b-collapse>
                                    </b-card>
                                  </b-accordion>
                                  <hr />
                                </div>

                                <div style="text-align: left">
                                  <div v-if="!expandedDescriptions[value._id]">
                                    {{
                                      value.description.length > 300
                                        ? value.description.slice(0, 300) + "..."
                                        : value.description
                                    }}
                                    <span v-if="value.description.length > 300">
                                      <br />
                                      <div style="text-align: center">
                                        <button @click="toggleDescription(value._id)" class="btn p-0">
                                          <i class="fa fa-chevron-down"></i>
                                        </button>
                                      </div>
                                    </span>
                                  </div>
                                  <div v-if="expandedDescriptions[value._id] && value.description.length > 300">
                                    {{ value.description }}
                                    <div style="text-align: center">
                                      <button @click="toggleDescription(value._id)" class="btn p-0">
                                        <i class="fa fa-chevron-up"></i>
                                      </button>
                                    </div>
                                  </div>

                                  <div class="row" v-if="value.description.includes('Lauf ABC')">
                                    <div class="col-lg-12 top-spacer-25">
                                      <iframe
                                        width="100%"
                                        height="315"
                                        v-bind:src="getRandomYoutubeVideo()"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                      ></iframe>
                                    </div>
                                    <br /><br />
                                  </div>

                                  <div class="row" v-if="value.youtubeEmbedLink">
                                    <div class="col-lg-12 top-spacer-25">
                                      <iframe
                                        width="100%"
                                        height="315"
                                        v-bind:src="youtubeToNoCookie(value.youtubeEmbedLink)"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                      ></iframe>
                                    </div>
                                    <br /><br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                  <div class="white_wrapper_tighter top-spacer-25">
                    <h4 style="margin-bottom: 25px">{{ $t("Letzte Einheiten") }}</h4>
                    <div v-if="!loaded" class="spinner-border text-primary top-spacer-15" role="status"></div>
                    <div v-else-if="previousWorkouts.length === 0" class="col-lg-12 centered">
                      {{ $t(`Noch keine Einheiten absolviert. Auf geht's, lass dein Training beginnen!`) }}
                    </div>
                    <div v-else class="row">
                      <div
                        v-for="(workout, index) in previousWorkouts.slice(0, 3)"
                        :key="workout._id"
                        class="col-12 mb-3"
                      >
                        <div class="row">
                          <div class="col-12 col-md-4 mb-3 d-none d-md-block">
                            <intensity-distribution-view
                              :workout="workout"
                              :thresholds="thresholds"
                              @click="showOverlay(workout._id)"
                            />

                            <!-- Add the route visualization here -->
                            <!--<route-visualization v-if="workout.sport !== 'Kraft'" :workout="workout" />-->
                          </div>
                          <div class="col-12 col-md-8">
                            <h4 @click="showOverlay(workout._id)" style="cursor: pointer">{{ workout.name }}</h4>
                            <div class="d-flex justify-content-center" style="margin-bottom: 15px">
                              <div
                                class="workout-svg-container top-spacer-15"
                                v-if="
                                  workout.sport != 'Kraft' &&
                                  workout.sport != 'Schwimmen' &&
                                  workout.workoutFile &&
                                  workout.workoutFile.length != 0
                                "
                                style="max-width: 300px; width: 100%"
                              >
                                <workout-svg
                                  :workout-file="workout.workoutFile"
                                  @click="showOverlay(workout._id)"
                                  style="width: 100%; height: 30px"
                                />
                              </div>
                            </div>
                            <div class="table-responsive">
                              <table class="table table-sm">
                                <tbody>
                                  <tr v-if="workout.sport">
                                    <th scope="row">Sport</th>
                                    <td>{{ workout.sport }}</td>
                                  </tr>
                                  <tr v-if="workout.durationIs || workout.durationShould">
                                    <th scope="row">Dauer</th>
                                    <td>
                                      {{ formatDuration(workout.durationIs) }}
                                      <span v-if="workout.durationShould" class="text-muted">
                                        (Soll: {{ formatDuration(workout.durationShould) }})
                                      </span>
                                    </td>
                                  </tr>
                                  <tr v-if="workout.distance || workout.distanceShould">
                                    <th scope="row">Distanz</th>
                                    <td>
                                      {{ workout.distance }} km
                                      <span v-if="workout.distanceShould" class="text-muted">
                                        (Soll: {{ computeDistanceShould(workout) }} km)
                                      </span>
                                    </td>
                                  </tr>
                                  <tr v-if="workout.paceIs || workout.paceShould">
                                    <th scope="row">Tempo</th>
                                    <td>
                                      <template v-if="workout.sport === 'Radfahren'">
                                        {{ formatSpeed(workout.paceIs) }} km/h
                                        <span v-if="workout.paceShould" class="text-muted">
                                          (Soll: {{ formatSpeed(workout.paceShould) }} km/h)
                                        </span>
                                      </template>
                                      <template v-else>
                                        {{ formatPace(workout.paceIs) }} /km
                                        <span v-if="workout.paceShould" class="text-muted">
                                          (Soll: {{ formatPace(workout.paceShould) }} /km)
                                        </span>
                                      </template>
                                    </td>
                                  </tr>
                                  <tr v-if="workout.heartRateAvg && workout.heartRateMax">
                                    <th scope="row">Herzfrequenz</th>
                                    <td>
                                      Ø {{ workout.heartRateAvg }} bpm
                                      <span v-if="workout.heartRateMax" class="text-muted">
                                        (Max: {{ workout.heartRateMax }} bpm)
                                      </span>
                                    </td>
                                  </tr>
                                  <tr v-if="workout.powerInWatts">
                                    <th scope="row">Leistung</th>
                                    <td>
                                      {{ workout.powerInWatts }} W
                                      <span v-if="workout.powerInWattsShould" class="text-muted">
                                        (Soll: {{ workout.powerInWattsShould }} W)
                                      </span>
                                    </td>
                                  </tr>
                                  <tr v-if="workout.cadenceAvg">
                                    <th scope="row">Trittfrequenz</th>
                                    <td>
                                      Ø {{ workout.cadenceAvg }} rpm
                                      <span v-if="workout.cadenceMax" class="text-muted">
                                        (Max: {{ workout.cadenceMax }} rpm)
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-between">
                              <button
                                class="btn btn-secondary btn-sm flex-grow-1 mr-2 small-btn"
                                @click.prevent="showOverlay(workout._id)"
                              >
                                {{ $t("Bearbeiten") }}
                              </button>
                              <a
                                v-if="workout.activityFile || workout.summaryId"
                                :href="`/#/auswertung/workout/details/${workout._id}`"
                                class="btn btn-info btn-sm flex-grow-1 small-btn"
                              >
                                {{ $t("Auswertung") }}
                              </a>
                            </div>
                          </div>
                        </div>
                        <hr v-if="index < 2" class="workout-separator" />
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="wettkampfWorkouts && wettkampfWorkouts.length > 0 && thresholds"
                    class="white_wrapper_tighter top-spacer-25"
                    style="padding: 25px"
                  >
                    <h4>{{ $t("Wettkämpfe") }}</h4>

                    <div class="row" v-if="wettkampfWorkouts && wettkampfWorkouts.length >= 0">
                      <div v-for="value in wettkampfWorkouts" :key="value._id" class="col-md-4 top-spacer-10">
                        <div class="row">
                          <div class="col-4 d-md-none">
                            <strong style="display: block" class="top-spacer-15"
                              >{{ $moment(value.date).format("DD.MM.YYYY") }}
                              <br />
                              Noch
                              {{ $moment(value.date).diff($moment().startOf("d"), "d") }}
                              {{ $t("Tage") }}
                            </strong>
                          </div>
                          <div class="col-8 col-md-12">
                            <strong style="display: block" class="d-none d-md-block top-spacer-15"
                              >{{ $moment(value.date).format("DD.MM.YYYY") }}<br />
                              <div v-if="$moment(value.date).diff($moment().startOf('d'), 'd') > 1">
                                Noch
                                {{ $moment(value.date).diff($moment().startOf("d"), "d") }}
                                {{ $t("Tage") }}
                              </div>

                              <div v-if="$moment(value.date).diff($moment().startOf('d'), 'd') == 0">
                                {{ $t("Heute") }}
                              </div>

                              <div v-if="$moment(value.date).diff($moment().startOf('d'), 'd') == 1">
                                {{ $t("Morgen") }}
                              </div>
                            </strong>

                            <workout-entry
                              class="top-spacer-5"
                              @closed="hideOverlay"
                              @wasSaved="hideOverlay"
                              @wasClicked="showOverlay"
                              v-bind:workoutsOfDay="{ workouts: [value] }"
                              v-bind:thresholds="thresholds"
                              :key="key + 'entry_wettkaempfe'"
                            >
                            </workout-entry>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <threshold-run-chart :done="workouts42d" :key="'thresh-run' + workouts42d.length" />
                  </div>
                  <div>
                    <threshold-bike-chart :done="workouts42d" :key="'thresh-bike' + workouts42d.length" />
                  </div>

                  <div class="white_wrapper_tighter top-spacer-25 d-none d-md-block">
                    <h4>{{ $t("Mal was probieren?") }}</h4>
                    <div class="row">
                      <div class="col-6 top-spacer-25" v-for="recipe in recipes" :key="recipe._id">
                        <a v-bind:href="'/#/esszimmer/rezept/' + recipe._id">
                          <h2
                            style="
                              font-size: 20px;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              max-width: 100%;
                            "
                            class="centered"
                          >
                            {{ recipe.ueberschrift }}
                          </h2>
                          <div class="recipe-parent" v-if="recipe.imageIds[0]">
                            <!-- -small hinters bild-->
                            <img
                              class="recipe-img"
                              style="width: 100%; border-radius: 10px"
                              :src="`https://assets.pushinglimits.club/${recipe.imageIds[0]}-small`"
                            />
                          </div>
                        </a>
                      </div>
                      <div class="col-lg-12 centered top-spacer-25">
                        <button @click="getRandomRecipes" style="width: 100%">
                          {{ $t("Andere Rezepte") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-5" v-if="!warnPremiumExpiration">
                  <div class="d-block d-md-none top-spacer-25"></div>
                  <div class="white_wrapper_tighter">
                    <h4>{{ $t("Bleib im grünen Bereich!") }}</h4>

                    <stress-chart-form-ramp
                      v-bind:done="workouts"
                      :key="key + 'belastung'"
                      v-bind:fromDay="$moment().add(-14, 'd').startOf('day')"
                      v-bind:until="$moment()"
                      v-bind:showCurrentVal="true"
                      v-if="!(workouts42d.length == 0 && !thresholds)"
                    />

                    <small
                      >{{
                        $t(
                          `Für einen langsamen und stetigen Aufbau solltest du dich hauptsächlich im unteren grünen Bereich bewegen. Benötigt Daten von 6 Wochen.`
                        )
                      }}
                    </small>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 top-spacer-25">
                      <div>
                        <div class="top-spacer-25 d-block d-lg-none"></div>

                        <div class="row">
                          <div class="col-lg-12">
                            <div>
                              <div>
                                <div v-if="loaded && (!workouts || workouts.length == 0)">
                                  <div class="white_wrapper_tighter">
                                    <h4>
                                      {{ $t("Keine Daten der letzten zwei Wochen verfügbar") }}
                                      :-(
                                    </h4>
                                    {{
                                      $t(
                                        `Ist aber kein Problem - lade einfach Trainingseinheiten hoch, verbinde dich in deinem Profil mit Garmin, Polar oder Wahoo oder fang an zu trainieren!`
                                      )
                                    }}
                                  </div>
                                </div>
                                <stress-chart-fitness
                                  v-bind:todayText="'Heute'"
                                  v-bind:done="workouts42d"
                                  :key="workouts42d.length + 'fitness' + workouts.length"
                                  v-bind:fromDay="$moment().add(-28, 'd').startOf('day')"
                                  v-bind:until="$moment()"
                                  v-if="workouts && workouts.length"
                                />
                              </div>
                            </div>

                            <div class="white_wrapper_tighter top-spacer-25">
                              <div class="col-lg-12" v-if="!workouts42d || workouts42d.length == 0">
                                Keine Daten für Trainingsdauer, -intensität und -belastung oder Distanzen. Lade zum
                                Start einige Trainingseinheiten hoch und hier erscheinen Auswertungen :-)
                              </div>

                              <workout-analysis-pss-chart
                                class="top-spacer-50"
                                style="height: 180px"
                                v-bind:done="workouts42d"
                                v-bind:from="$moment().add(-42, 'd').startOf('day')"
                                v-bind:until="$moment()"
                                :key="key + 'pss'"
                                v-if="workouts42d && workouts42d.length"
                              />

                              <workout-analysis-intensity-chart
                                class="top-spacer-50"
                                style="height: 180px"
                                v-bind:done="workouts42d"
                                v-bind:from="$moment().add(-42, 'd').startOf('day')"
                                v-bind:until="$moment()"
                                :key="key + 'intensity'"
                                v-if="workouts42d && workouts42d.length"
                              />

                              <workout-analysis-load-chart
                                class="top-spacer-50"
                                style="height: 180px"
                                v-bind:done="workouts42d"
                                v-bind:from="$moment().add(-42, 'd').startOf('day')"
                                v-bind:until="$moment()"
                                :key="key + 'load'"
                                v-if="workouts42d && workouts42d.length"
                              />

                              <workout-analysis-distance-chart
                                class="top-spacer-50"
                                style="height: 180px"
                                v-bind:done="workouts42d"
                                v-bind:from="$moment().add(-42, 'd').startOf('day')"
                                v-bind:until="$moment()"
                                :key="key + 'distance'"
                                v-if="workouts42d && workouts42d.length"
                              />
                            </div>

                            <div class="white_wrapper_tighter top-spacer-25 d-block d-md-none">
                              <h4>{{ $t("Mal was probieren?") }}</h4>
                              <div class="row">
                                <div class="col-6 top-spacer-25" v-for="recipe in recipes" :key="recipe._id">
                                  <a v-bind:href="'/#/esszimmer/rezept/' + recipe._id">
                                    <h2
                                      style="
                                        font-size: 20px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        max-width: 100%;
                                      "
                                      class="centered"
                                    >
                                      {{ recipe.ueberschrift }}
                                    </h2>
                                    <div class="recipe-parent" v-if="recipe.imageIds[0]">
                                      <!-- -small hinters bild-->
                                      <img
                                        class="recipe-img"
                                        style="width: 100%; border-radius: 10px"
                                        :src="`https://assets.pushinglimits.club/${recipe.imageIds[0]}-small`"
                                      />
                                    </div>
                                  </a>
                                </div>
                                <div class="col-lg-12 centered top-spacer-25">
                                  <button @click="getRandomRecipes" style="width: 100%">
                                    {{ $t("Andere Rezepte") }}
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div class="white_wrapper_tighter top-spacer-25">
                              {{ $t("Möchtest du deine Workout-Vorlagen anpassen?") }}<br /><br />
                              <a href="/#/training/vorlagen">
                                <button style="width: 100%">
                                  {{ $t("Workout-Vorlagen bearbeiten") }}
                                </button></a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-5" v-if="!warnPremiumExpiration"></div>
            </div>
          </vue-pull-refresh>
        </div>

        <div class="row top-spacer-15">
          <div class="col-lg-6"></div>
        </div>
      </div>
    </div>
    <div class="row top-spacer-100"></div>
  </div>
</template>

<script>
import WorkoutEntry from "@/components/calendar/WorkoutEntryNoHighlight";
import NewWorkout from "@/components/workout/NewWorkout";
import VuePullRefresh from "vue-pull-refresh";
import axios from "axios";
import StressChartFitness from "@/components/chart/StressChartFitness.vue";

import WorkoutAnalysisLoadChart from "@/components/chart/WorkoutAnalysisLoadChart.vue";
import WorkoutAnalysisIntensityChart from "@/components/chart/WorkoutAnalysisIntensityChart.vue";
import WorkoutAnalysisDistanceChart from "@/components/chart/WorkoutAnalysisDistanceChart.vue";
import WorkoutAnalysisPssChart from "@/components/chart/WorkoutAnalysisPssChart.vue";
import StressChartFormRamp from "@/components/chart/StressChartFormRamp.vue";

import ThresholdRunChart from "@/components/chart/ThresholdRunChart.vue";
import ThresholdBikeChart from "@/components/chart/ThresholdBikeChart.vue";
import WeatherForecast from "@/components/WeatherForecast.vue";

// import Chart from "chart.js/auto";
import calendar_utils from "@/js/calendar_utils";
import WorkoutSvg from "@/components/WorkoutSvg.vue";
import IntensityDistributionView from "@/components/IntensityDistributionView.vue";
import AdjustedWorkoutPlan from "./AdjustedWorkoutPlan.vue";
import AICoachPreferences from "@/components/AICoachPreferences.vue";
// import RouteVisualization from "@/components/RouteVisualization.vue";

export default {
  metaInfo: {
    title: "Dashboard",
  },
  name: "Dashboard",
  components: {
    AICoachPreferences,
    VuePullRefresh,
    WorkoutAnalysisLoadChart,
    WorkoutAnalysisIntensityChart,
    WorkoutAnalysisDistanceChart,
    WorkoutAnalysisPssChart,
    NewWorkout,
    StressChartFormRamp,
    WorkoutEntry,
    StressChartFitness,
    ThresholdRunChart,
    ThresholdBikeChart,
    WeatherForecast,
    WorkoutSvg,
    AdjustedWorkoutPlan,
    IntensityDistributionView,
    // RouteVisualization,
  },
  data: function () {
    return {
      coachWorkoutResults: [],
      showToday: true,
      wettkampfWorkouts: [],
      weather_prediction: [],
      weather_prediction_all: [],

      showAICoachPreferences: false,

      pullDownConfig: {
        errorLabel: "Es ist ein Fehler aufgetreten",
        startLabel: "Lade...",
        readyLabel: "Neu laden...",
        loadingLabel: "Lade...",
      },

      expandedDescriptions: {},
      warnPremiumExpiration: false,
      information: "", // Nachricht vom Server
      workouts: [],
      workouts42d: [],
      key: 0,
      thresholdsChanged: false,
      nextWorkouts: [],
      previousWorkouts: [],
      runThreshold: 0,
      swimThreshold: 0,
      ftp: 0,
      runThresholdStart: 0,
      swimThresholdStart: 0,
      ftpStart: 0,
      thresholds: undefined,
      editThresholdId: "",
      showThresholdDetails: false,
      edit: {},
      trainingplans: [],
      showTrainingplans: false,
      switchPlanTo: {},

      foodlogLength: 0,

      workout_run_count: 0,
      workout_bike_count: 0,
      workout_strength_count: 0,
      workout_activity_count: 0,
      workout_swim_count: 0,
      workout_run_duration: 0,
      workout_bike_duration: 0,
      workout_strength_duration: 0,
      workout_activity_duration: 0,
      workout_swim_duration: 0,
      workout_run_distance: 0,
      workout_bike_distance: 0,
      workout_strength_distance: 0,
      workout_activity_distance: 0,
      workout_swim_distance: 0,

      selectedCalendarWorkout: undefined,
      isMenuOpen: false,
      recipes: [],
      loaded: false,

      ftpUpdated: false,
      workoutAnalysisBest: [],
      user: {},
      maxPower: 120, // New data property for maximum power

      feelings: ["Erschöpft", "Müde", "Normal", "Gut", "Exzellent"],
      wordToVideoUrl: [],
      coachResults: null,
      coachLoading: false,
      isMobile: false,
      scrollPosition: 0,
      showCoachInfo: false,
    };
  },

  watch: {
    $route(newVal) {
      this.isMenuOpen = newVal.query?.isMenuOpen || false;

      if (!this.isMenuOpen) {
        this.setScrollLock(false);
      } else {
        this.setScrollLock(true);
      }
    },
  },

  mounted: async function () {
    const _self = this;

    await this.loadWordToVideoMapping();
    this.updateData();
    this.getRandomRecipes();

    this.updateWeather();

    axios.get(this.$host + "/user/information").then(function (res) {
      _self.information = res.data.information;
    });

    let user = (await axios.get(this.$host + "/user/me")).data;

    try {
      _self.$moment.tz.setDefault(user.timezone || "Europe/Berlin");
    } catch (err) {
      console.log("Fehler beim setzen der TZ", user.timezone);
    }

    this.user = user;

    if (
      !this.$moment(user.premiumAccountUntil).isAfter(this.$moment()) &&
      !this.$moment(user.freeAccountUntil).isAfter(this.$moment())
    ) {
      this.warnPremiumExpiration = true;
    }

    if (!user.introduction_done) {
      this.$router.push({ name: "Introduction" });
    }
    /*
    axios
      .get(this.$host + "/workout/best")
      .then(function (res) {
        _self.workoutAnalysisBest = res.data;
      })
      .catch(function (err) {
        console.log("ERR", err);
      });

    const response7d = await axios.get(this.$host + "/food/7d");
    const entries7d = response7d.data;

    const response30d = await axios.get(this.$host + "/food/30d");
    const entries30d = response30d.data;

    const response365d = await axios.get(this.$host + "/food/365d");
    const entries365d = response365d.data;

    this.foodlogLength = entries365d.length;

    // chartjs pie chart for ratings
    let ctx = this.$refs.foodChart7d.getContext("2d");

    const good7d = entries7d.filter((entry) => entry.rating === "Good").length;
    const okay7d = entries7d.filter((entry) => entry.rating === "Okay").length;
    const bad7d = entries7d.filter((entry) => entry.rating === "Bad").length;

    new Chart(ctx, {
      type: "pie",
      data: {
        labels: ["Gut", "Okay", "Schlecht"],
        datasets: [
          {
            label: "# of Votes",
            data: [good7d, okay7d, bad7d],
            backgroundColor: ["#4CAF50", "#FFC107", "#F44336"],
            borderColor: ["#4CAF50", "#FFC107", "#F44336"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    // 30 d

    // chartjs pie chart for ratings
    let ctx2 = this.$refs.foodChart30d.getContext("2d");

    const good2 = entries30d.filter((entry) => entry.rating === "Good").length;
    const okay2 = entries30d.filter((entry) => entry.rating === "Okay").length;
    const bad2 = entries30d.filter((entry) => entry.rating === "Bad").length;

    new Chart(ctx2, {
      type: "pie",
      data: {
        labels: ["Gut", "Okay", "Schlecht"],
        datasets: [
          {
            label: "# of Votes",
            data: [good2, okay2, bad2],
            backgroundColor: ["#4CAF50", "#FFC107", "#F44336"],
            borderColor: ["#4CAF50", "#FFC107", "#F44336"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    // 1yr
    // chartjs pie chart for ratings
    let ctx3 = this.$refs.foodChart365d.getContext("2d");

    const good3 = entries365d.filter((entry) => entry.rating === "Good").length;
    const okay3 = entries365d.filter((entry) => entry.rating === "Okay").length;
    const bad3 = entries365d.filter((entry) => entry.rating === "Bad").length;

    new Chart(ctx3, {
      type: "pie",
      data: {
        labels: ["Gut", "Okay", "Schlecht"],
        datasets: [
          {
            label: "# of Votes",
            data: [good3, okay3, bad3],
            backgroundColor: ["#4CAF50", "#FFC107", "#F44336"],
            borderColor: ["#4CAF50", "#FFC107", "#F44336"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
*/
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.checkMobile);
  },

  methods: {
    computeDistanceShould: function (workout) {
      if (workout.sport == "Laufen") {
        workout.paceShould =
          calendar_utils.computeSpeedFromWorkoutFile(workout.workoutFile, this.thresholds.threshold_run) || 0;
      }

      if (workout.sport == "Laufen" && workout.durationShould > 0 && workout.paceShould > 0) {
        workout.distanceShould = Math.round((workout.durationShould / workout.paceShould) * 10) / 10;
      }
      return workout.distanceShould;
    },
    toggleDescription: function (workoutId) {
      this.$set(this.expandedDescriptions, workoutId, !this.expandedDescriptions[workoutId]);
    },
    sendFeeling(level) {
      const _self = this;

      _self.coachLoading = true;

      axios
        .post(_self.$host + "/autocoach/rate_feeling", { feeling: level })
        .then(function (res) {
          _self.coachResults = res.data;
          _self.coachLoading = false;
        })
        .catch(function (error) {
          console.log(error);
          _self.coachLoading = false;
        });
    },

    onRefresh: function () {
      const _self = this;

      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          _self.updateData();
          _self.getRandomRecipes();

          _self.updateWeather();
          axios
            .get(_self.$host + "/workout/best")
            .then(function (res) {
              _self.workoutAnalysisBest = res.data;
            })
            .catch(function (err) {
              console.log("ERR", err);
              reject();
            });
          resolve();
        }, 1000);
      });
    },

    getRandomYoutubeVideo() {
      const videos = [
        "https://www.youtube-nocookie.com/embed/rrwd8Nl3oiY",
        "https://www.youtube-nocookie.com/embed/D1X3aGPtT0w",
        "https://www.youtube-nocookie.com/embed/Ahc7bRoWhTk",
        "https://www.youtube-nocookie.com/embed/yoVHzBYzwHU",
      ];
      const randomIndex = Math.floor(Math.random() * videos.length);
      return videos[randomIndex];
    },

    updateWeather: async function () {
      const _self = this;
      axios.get(_self.$host + "/weather/user_location").then(function (res) {
        _self.weather_prediction = res.data.weather_days;
      });
      axios.get(_self.$host + "/weather/user_location_all").then(function (res) {
        _self.weather_prediction_all = res.data.weather_days;
      });
    },

    youtubeToNoCookie(link) {
      if (link && link.indexOf("youtube.com") !== -1) {
        const search = new URL(link).searchParams;
        const videoId = search.get("v");
        if (videoId) {
          link = "https://www.youtube-nocookie.com/embed/" + videoId;
        }
      } else if (link && link.indexOf("youtu.be") !== -1) {
        link = link.replace("//youtu.be/", "//www.youtube-nocookie.com/embed/");
      }
      return link;
    },

    video_introduction_done() {
      const _self = this;
      axios
        .post(this.$host + "/user/video_introduction_done")
        .then(function (res) {
          console.log("VIDEO INTRO DONE RES", res);
          _self.user = res.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setScrollLock(lockIt) {
      const el = window.document.body;

      if (lockIt) {
        el.classList.add("locked");
      } else {
        el.classList.remove("locked");
      }
    },
    findNewFtp() {
      const _self = this;
      return Math.max.apply(
        Math,
        this.previousWorkouts.map(function (o) {
          // todo datumseinschränkung testen
          if (_self.$moment(o.date).isAfter(_self.$moment().add(-14, "days"))) {
            return o.newFtp;
          } else {
            return 0;
          }
        })
      );
    },

    findNewFtpWorkout() {
      const _self = this;
      const ftp = Math.max.apply(
        Math,
        this.previousWorkouts.map(function (o) {
          // todo datumseinschränkung testen
          if (_self.$moment(o.date).isAfter(_self.$moment().add(-14, "days"))) {
            return o.newFtp;
          } else {
            return 0;
          }
        })
      );

      return this.previousWorkouts.find(function (o) {
        return o.newFtp == ftp;
      });
    },

    setNewFtp: function (ftp) {
      let req = {
        ftp: ftp,
        threshold_swim: this.thresholds.threshold_swim,
        threshold_run: this.thresholds.threshold_run,

        maxHr: this.thresholds.maxHr,
        restingHr: this.thresholds.restingHr,
        weight: this.thresholds.weight,
      };

      const _self = this;

      axios
        .post(this.$host + "/user/thresholds", req)
        .then(function () {
          _self.updateData();
          _self.ftpUpdated = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    unsetNewFtp: function (workout) {
      let req = {
        id: workout._id,
      };

      const _self = this;

      axios
        .post(this.$host + "/workout/thresholds/unsetftp", req)
        .then(function () {
          _self.updateData();
          _self.ftpUpdated = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    updateData: async function () {
      const _self = this;
      await this.loadWordToVideoMapping();
      // set limit to 9
      const res = await axios.get(
        this.$host + "/workout/nextworkouts/" + this.$moment().startOf("day").utc() + "/limit/9"
      );
      _self.nextWorkouts = res.data.sort((a, b) => {
        return (
          new Date(_self.$moment(a.date).startOf("d")) - new Date(_self.$moment(b.date).startOf("d")) ||
          a.orderOfDay - b.orderOfDay
        );
      });
      // Add video URLs to each workout
      _self.nextWorkouts.forEach((workout) => {
        workout.videoUrls = _self.findVideoUrls(workout);
      });

      axios
        .get(this.$host + "/workout/competition_workouts/" + this.$moment().startOf("day").utc())
        .then(function (res) {
          _self.wettkampfWorkouts = res.data;
        });

      axios.get(this.$host + "/workout/previous").then(function (res) {
        _self.previousWorkouts = res.data;
      });

      this.loadWorkouts();

      this.thresholds = await (await axios.get(this.$host + "/user/threshold")).data;
      this.runThreshold = this.thresholds.threshold_run;

      this.loaded = true;
    },

    getRandomRecipes() {
      const _self = this;
      axios
        .get(this.$host + "/rezept/random")
        .then(function (response) {
          _self.recipes = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    showOverlay(id) {
      // Prevent default behavior
      event.preventDefault();

      // Store current scroll position
      this.scrollPosition = window.pageYOffset;

      this.setScrollLock(true);
      this.menuRoute();

      this.selectedCalendarWorkout = this.nextWorkouts
        .concat(this.previousWorkouts)
        .concat(this.wettkampfWorkouts)
        .find((element) => element._id == id);

      this.isMenuOpen = true;

      if (this.isMobile) {
        // Adjust overlay for mobile
        this.isMenuOpen = true;
        document.body.style.overflow = "hidden";
      }
    },
    hideOverlay() {
      this.updateData();
      this.selectedCalendarWorkout = undefined;
      this.menuRouteClose();

      if (this.isMobile) {
        document.body.style.overflow = "";
      }

      // Restore scroll position after a short delay
      setTimeout(() => {
        window.scrollTo(0, this.scrollPosition);
      }, 0);
    },

    workoutDeleted(workoutId) {
      this.nextWorkouts = this.nextWorkouts.filter((workout) => workout._id !== workoutId);
      this.previousWorkouts = this.previousWorkouts.filter((workout) => workout._id !== workoutId);
    },

    hideOverlayNoUpdate() {
      this.selectedCalendarWorkout = undefined;
      this.menuRouteClose();
      // {{ edit_1 }}: Fügt das Zurücksetzen der Overflow-Eigenschaft für mobile Geräte hinzu
      if (this.isMobile) {
        document.body.style.overflow = "";
      }
    },
    menuRoute() {
      this.setScrollLock(true);

      this.isMenuOpen = true;
      this.$router.push({
        name: "Dashboard",
        query: {
          isMenuOpen: true,
        },
      });
    },
    menuRouteClose() {
      this.isMenuOpen = false;

      this.$router.push({
        name: "Dashboard",
        query: {
          isMenuOpen: false,
        },
      });

      this.setScrollLock(false);
    },
    async loadWorkouts() {
      const _self = this;

      let from = this.$moment().add(-14, "d").startOf("day");
      let till = this.$moment().endOf("day");

      axios.get(this.$host + "/workout/" + "from/" + from + "/until/" + till + "/done").then(function (res) {
        _self.workouts = res.data;

        _self.workout_run_count = 0;
        _self.workout_bike_count = 0;
        _self.workout_strength_count = 0;
        _self.workout_swim_count = 0;

        _self.workout_run_duration = 0;
        _self.workout_bike_duration = 0;
        _self.workout_strength_duration = 0;
        _self.workout_swim_duration = 0;

        _self.workout_run_distance = 0;
        _self.workout_bike_distance = 0;
        _self.workout_strength_distance = 0;
        _self.workout_activity_distance = 0;
        _self.workout_swim_distance = 0;

        _self.workouts.forEach(function (workout) {
          if (workout.sport == "Laufen") {
            _self.workout_run_count++;
            _self.workout_run_duration += workout.durationIs;
            _self.workout_run_distance += workout.distance;
          } else if (workout.sport == "Radfahren") {
            _self.workout_bike_count++;
            _self.workout_bike_duration += workout.durationIs;
            _self.workout_bike_distance += workout.distance;
          } else if (workout.sport == "Kraft") {
            _self.workout_strength_count++;
            _self.workout_strength_duration += workout.durationIs;
          } else if (workout.sport == "Aktivität") {
            _self.workout_activity_count++;
            _self.workout_activity_duration += workout.durationIs;
            _self.workout_activity_distance += workout.distance;
          } else if (workout.sport == "Schwimmen") {
            _self.workout_swim_count++;
            _self.workout_swim_duration += workout.durationIs;
            _self.workout_swim_distance += workout.distance;
          }
        });
      });
      axios
        .get(this.$host + "/workout/" + "from/" + _self.$moment(from).add(-42, "d") + "/until/" + till + "/done")
        .then(function (res) {
          _self.workouts42d = res.data;
        });
    },

    getTotalDuration(workoutFile) {
      return workoutFile.reduce((total, segment) => {
        if (segment.type === "IntervalsT") {
          return total + (segment.OnDuration + segment.OffDuration) * segment.Repeat;
        }
        return total + (segment.Duration || segment.Distance / 15);
      }, 0);
    },

    getSegmentX(workoutFile, index) {
      let x = 0;
      for (let i = 0; i < index; i++) {
        const segment = workoutFile[i];
        if (segment.type === "IntervalsT") {
          x += (segment.OnDuration + segment.OffDuration) * segment.Repeat;
        } else {
          x += segment.Duration || segment.Distance / 15;
        }
      }
      return x;
    },

    colorForPower(power) {
      if (power > 105) return "darkred";
      if (power > 100) return "brown";
      if (power > 95) return "firebrick";
      if (power > 88) return "darkgreen";
      if (power > 83) return "green";
      return "rgba(94, 110, 255, 0.97)";
    },

    getRampPoints(workoutFile, index, segment, height = 200) {
      const x = this.getSegmentX(workoutFile, index);
      const y1 = height - (segment.PowerLow * height) / this.maxPower;
      const y2 = height - (segment.PowerHigh * height) / this.maxPower;
      return `${x},${y1} ${x + segment.Duration},${y2} ${x + segment.Duration},300 ${x},300`;
    },

    addWorkoutToCalendar(workout) {
      let tempWorkoutDuration = workout.durationShould;

      // Distanz berechnen, falls duration und pace gegeben

      if (workout.sport == "Laufen") {
        workout.paceShould =
          calendar_utils.computeSpeedFromWorkoutFile(workout.workoutFile, this.thresholds.threshold_run) || 0;
      }

      if (workout.sport == "Laufen" && tempWorkoutDuration > 0 && workout.paceShould > 0) {
        workout.distanceShould = Math.round((tempWorkoutDuration / workout.paceShould) * 10) / 10;
      }

      let req = {
        name: workout.name,
        sport: workout.sport,
        description: workout.description,
        durationShould: workout.durationShould,
        paceShould: workout.paceShould || 0,
        distanceShould: workout.distanceShould || 0,
        distance: workout.distance || 0,
        date: this.$moment(),
        workoutFile: workout.workoutFile,
        radraumWorkoutId: workout.radraumWorkoutId,
        durationIs: 0,
        paceIs: 0,
        youtubeEmbedLink: workout.youtubeEmbedLink,
        workout_blueprint: workout._id,
        isSuggestion: true,
      };

      const _self = this;

      axios
        .post(this.$host + "/workout", req)
        .then(async function () {
          _self.showFeelingForm = false;
          _self.coachResults = undefined;
          _self.coachWorkoutResults = [];
          _self.updateData();
        })
        .catch(function (error) {
          console.log(error);
          _self.showFeelingForm = false;
          _self.coachResults = undefined;
          _self.coachWorkoutResults = [];
        });
    },

    formatDuration(seconds) {
      const duration = this.$moment.duration(seconds, "seconds");
      const hours = duration.hours();
      const minutes = duration.minutes();
      const formattedSeconds = duration.seconds().toString().padStart(2, "0");
      return hours > 0
        ? `${hours}:${minutes.toString().padStart(2, "0")}:${formattedSeconds}`
        : `${minutes}:${formattedSeconds}`;
    },

    formatPace(seconds) {
      return seconds ? this.$moment.utc(seconds * 1000).format("mm:ss") : "N/A";
    },

    formatSpeed(paceInSecondsPerKm) {
      if (!paceInSecondsPerKm) return "N/A";
      const speedInKmPerHour = 3600 / paceInSecondsPerKm;
      return speedInKmPerHour.toFixed(1);
    },

    intensityToHeartRate(intensity) {
      let low = 0;
      let high = 0;

      // Ramp needs hrTargeZoneUpdate
      if (intensity < 56) {
        low = 0;
        high = this.user.threshold_hr_zone_1 * this.thresholds.maxHr;
      } else if (intensity <= 82) {
        low = this.user.threshold_hr_zone_1 * this.thresholds.maxHr;
        high = this.user.threshold_hr_zone_2 * this.thresholds.maxHr;
      } else if (intensity < 96) {
        low = this.user.threshold_hr_zone_2 * this.thresholds.maxHr;
        high = this.user.threshold_hr_zone_3 * this.thresholds.maxHr;
      } else if (intensity < 111) {
        low = this.user.threshold_hr_zone_3 * this.thresholds.maxHr;
        high = this.user.threshold_hr_zone_4 * this.thresholds.maxHr;
      } else {
        low = this.user.threshold_hr_zone_4 * this.thresholds.maxHr;
        high = this.thresholds.maxHr * 100;
      }
      low = Math.round(low / 100);
      high = Math.round(high / 100);
      return { low, high };
    },

    selectFeeling(feeling) {
      this.$parent.selectedFeeling = feeling;
      const feelingNumber =
        feeling === "Erschöpft" ? 1 : feeling === "Müde" ? 2 : feeling === "Normal" ? 3 : feeling === "Gut" ? 4 : 5;
      this.sendFeeling(feelingNumber);
    },

    async loadWordToVideoMapping() {
      try {
        const response = await axios.get(this.$host + "/media/get_videos_wordlist");
        this.wordToVideoUrl = response.data;
      } catch (error) {
        console.error("Error loading video mapping:", error);
      }
    },

    findVideoUrls(workout) {
      const text = workout.description;
      const objects = workout.workoutFile || [];

      const uniqueVideoUrls = new Set();
      let combinedText = text + " " + objects.map((obj) => obj.Description).join(" ");

      const areAllWordsPresent = (key, text) => {
        return key.split(" ").every((word) => text.includes(word));
      };

      for (let key in this.wordToVideoUrl) {
        if (areAllWordsPresent(key, combinedText)) {
          uniqueVideoUrls.add(this.wordToVideoUrl[key]);
        }
      }

      return Array.from(uniqueVideoUrls);
    },

    collapseTodaysWorkouts() {
      this.showToday = !this.showToday;
    },

    adjustWorkout(workout) {
      // Implement the logic to adjust the specific workout
      console.log("Adjusting workout:", workout);
      // You might want to open a modal or navigate to a workout edit page
      // Pass the specific workout to be adjusted
    },

    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },

    showDetails(workoutId) {
      // Navigate to the workout details page
      this.$router.push({ name: "WorkoutDetails", params: { id: workoutId } });
    },

    toggleCoachInfo() {
      this.showCoachInfo = !this.showCoachInfo;
    },
  },

  computed: {
    nextWorkoutDate() {
      if (this.nextWorkouts.length > 0) {
        return this.$moment(this.nextWorkouts[0].date).format("YYYY-MM-DD");
      }
      return null;
    },

    todaysWorkouts() {
      const today = this.$moment().startOf("day");
      return this.nextWorkouts.filter((workout) => this.$moment(workout.date).isSame(today, "day"));
    },

    todaysWorkoutsAlternative() {
      let workouts = this.todaysWorkouts;
      const alternativeWorkouts = workouts.filter((workout) => workout.is_alternative == false);
      // kann genutzt werden, damit nach auswahl eines alternativen workouts, dieser nicht mehr angezeigt wird
      // wird aber erstmal nicht genutzt
      console.log("alternativeWorkouts", alternativeWorkouts);
      return workouts;
    },

    todayDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
  },
};
</script>

<style scoped>
.dashboard-top {
  margin-top: 25px;
}

@media (max-width: 768px) {
  .dashboard-top {
    margin-top: 15px;
  }

  .container,
  .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }

  .row {
    margin-left: -5px;
    margin-right: -5px;
  }

  .col,
  [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .white_wrapper_tighter {
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding: 15px;
  }

  .overlay-box {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;
    max-width: 100%;
    margin-left: 0;
  }

  .feeling-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    gap: 10px;
  }

  .feeling-button {
    margin: 0;
    width: 100%;
  }

  .feeling-button:nth-child(3) {
    grid-column: 1 / -1;
  }

  .workout-svg-container {
    max-width: 100%;
    overflow-x: auto;
  }

  .custom-accordion .accordion-button {
    padding: 0.5rem 1rem;
  }

  .embed-responsive {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
  }

  .embed-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.threshold {
  padding: 10px;
  border-bottom: 2px solid #fff;
}

.close-overlay i {
  cursor: pointer;
  font-size: 45px;
  float: right;
  color: #000;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 66666;
  overflow: hidden;
}

.close-overlay i {
  cursor: pointer;
  font-size: 35px;
  float: right;
}

.overlay-box {
  -webkit-overflow-scrolling: touch;
  position: fixed;
  background: #fff;
  padding: 0;
  overflow-y: auto !important;
  margin: auto;
  max-width: 750px;
  left: 50%;
  margin-left: -375px;
  overflow-x: hidden;
  top: calc(env(safe-area-inset-top) + 3%);

  bottom: 3%;

  z-index: 99999999;
  border-radius: 10px;
}

@media (max-width: 800px) {
  .overlay-box {
    position: fixed;

    left: 20px;
    right: 20px;
    top: calc(env(safe-area-inset-top) + 5px);
    bottom: 20px;
    margin-left: 0;
  }
}

.library {
  background: #efefef;
  padding: 20px;
  border-radius: 5px;
}

.table-sm {
  font-size: 0.9em;
}
.table-sm th {
  width: 40%;
}

.workout-preview {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .table-sm {
    font-size: 0.8em; /* Reduce font size on mobile */
  }

  .table-md {
    font-size: 0.75em; /* Reduce font size for the workout details table on mobile */
  }
}

.feeling-section {
  margin-bottom: 30px;
  margin-top: 20px;
}

.feeling-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.feeling-button {
  flex: 1;
  min-width: 80px;
  margin: 5px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: #f0f0f0;
  color: #333;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.feeling-button:hover {
  background-color: #e0e0e0;
}

.feeling-button.active {
  background-color: #4caf50;
  color: white;
}

@media (max-width: 768px) {
  .feeling-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    gap: 10px;
  }

  .feeling-button {
    margin: 0;
    width: 100%;
  }

  .feeling-button:nth-child(3) {
    grid-column: 1 / -1;
  }
}

.custom-accordion .card-header {
  padding: 0;
  background-color: transparent;
  border: none;
}

.custom-accordion .accordion-button {
  background-color: #f8f9fa;
  color: #333;
  font-weight: bold;
  text-align: left;
  width: 100%;
  padding: 0.75rem 1.25rem;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
}

.custom-accordion .accordion-button:hover,
.custom-accordion .accordion-button:focus {
  background-color: #e9ecef;
  text-decoration: none;
}

.custom-accordion .collapsed .accordion-button {
  background-color: #f8f9fa;
  color: #333;
}

.custom-accordion .accordion-button:not(.collapsed) {
  background-color: #6c757d;
  color: white;
}

.custom-accordion .accordion-button:not(.collapsed) i {
  transform: rotate(180deg);
}

.custom-accordion .fa-chevron-down {
  transition: transform 0.3s ease;
}

.custom-accordion .card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.custom-accordion .card:not(:last-child) {
  margin-bottom: 0.5rem;
}

.embed-responsive {
  margin-top: 1rem;
}

.trash-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.trash-container i {
  font-size: 5em;
  color: #6c757d;
}

.trash-container p {
  margin-top: 1rem;
  font-weight: bold;
}

.adjust-workout-btn {
  padding: 8px 16px; /* Reduced padding */
  font-size: 0.9em; /* Smaller font size */
  border-radius: 20px; /* Rounded corners */
  transition: all 0.3s ease;
  background-color: #007bff; /* Bootstrap primary color */
  border: none;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.adjust-workout-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #0056b3; /* Darker shade on hover */
}

@media (max-width: 768px) {
  .col-md-5,
  .col-md-2 {
    width: 100%;
    margin-bottom: 1rem;
  }

  .fa-arrow-down {
    margin: 1rem 0;
  }
}

.workout-row {
  min-height: 200px;
}

.workout-separator {
  height: 1px;
  background-color: #dee2e6;
  margin: 20px 0;
}

.workout-item {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.trash-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.trash-container i {
  font-size: 3em;
  color: #6c757d;
}

.trash-container p {
  margin-top: 1rem;
  font-weight: bold;
}

.workout-suggestions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.workout-suggestion {
  background-color: #f8f9fa;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.workout-suggestion:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.workout-suggestion-header {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
}

.workout-suggestion-header h6 {
  margin: 0;
  font-weight: bold;
}

.workout-suggestion-body {
  padding: 15px;
}

.workout-details {
  margin-top: 15px;
}

.workout-suggestion-footer {
  padding: 15px;
  text-align: center;
}

.workout-suggestion-footer button {
  width: 100%;
}

@media (max-width: 768px) {
  .workout-suggestions {
    grid-template-columns: 1fr;
  }
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.white_wrapper_tighter {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.mt-3 {
  display: flex;
  gap: 8px;
}

.mt-3 .btn {
  flex: 1;
  white-space: nowrap;
}

.small-btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.weather-forecast-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .weather-forecast-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.route-visualization {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 10px;
  background-color: #f8f9fa;
}

.fa-info-circle {
  margin-left: 5px;
  color: #007bff;
}

.workout-svg-container-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.workout-svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
