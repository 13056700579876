<template>
  <div v-if="workout.sport != 'Aktivität'">
    <p><strong>Intensität:</strong></p>
    <div class="intensity-bars">
      <div class="intensity-bar" v-for="(intensity, index) in intensities" :key="index">
        <div class="bar-label">{{ intensity.label }}</div>
        <div class="bar-container">
          <div class="bar" :style="{ width: intensity.value + '%' }"></div>
        </div>
        <div class="bar-value">{{ intensity.value }}%</div>
      </div>
    </div>
    <p class="top-spacer-10" v-if="workout.pss > 0"><strong>Belastung:</strong> {{ Math.round(workout.pss) }}</p>
  </div>
  <div v-else>
    <p>Aktivität</p>
    Keine Belastungspunkte
  </div>
</template>

<script>
export default {
  name: "IntensityDistributionView",
  props: {
    workout: {
      type: Object,
      required: true,
    },
    thresholds: {
      type: Object,
      required: true,
    },
  },
  computed: {
    intensities() {
      const getIntensityValue = (actual, estimate) => {
        if (actual > 0) return actual;
        if (estimate > 0) return estimate;
        return 0;
      };

      const lit = getIntensityValue(this.workout.time_lit, this.workout.estimate_time_lit);
      const mit = getIntensityValue(this.workout.time_mit, this.workout.estimate_time_mit);
      const hit = getIntensityValue(this.workout.time_hit, this.workout.estimate_time_hit);

      const total = lit + mit + hit;

      if (total === 0) {
        return [
          { label: "LIT", value: 93 },
          { label: "MIT", value: 5 },
          { label: "HIT", value: 2 },
        ];
      }

      return [
        { label: "LIT", value: Math.round((lit / total) * 100) },
        { label: "MIT", value: Math.round((mit / total) * 100) },
        { label: "HIT", value: Math.round((hit / total) * 100) },
      ];
    },
    estimatedLoad() {
      return Math.round(this.workout.loadEstimate) || 54;
    },
  },
  methods: {
    formatDuration(seconds) {
      return this.$moment.utc(seconds * 1000).format("H:mm") + "h";
    },
  },
};
</script>

<style scoped>
.intensity-distribution-view {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.intensity-bars {
  margin-top: 10px;
}

.intensity-bar {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.bar-label {
  width: 40px;
  font-weight: bold;
}

.bar-container {
  flex-grow: 1;
  background-color: #e9ecef;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 10px;
}

.bar {
  height: 100%;
  background-color: #007bff;
}

.bar-value {
  width: 40px;
  text-align: right;
}
</style>
