<template>
  <div>
    <div class="white_wrapper_tighter">
      <div class="row">
        <div class="col-3">
          <h4 style="font-size: 15px">{{ $t("Form") }}</h4>
          <h3 class="bigNumberCircle">{{ form }}</h3>
        </div>
        <div class="col-3">
          <h4 style="font-size: 15px">{{ $t("Ermüdung") }}</h4>
          <h3 class="bigNumberCircle">{{ ermuedung }}</h3>
        </div>
        <div class="col-3">
          <h4 style="font-size: 15px">{{ $t("Fitness") }}</h4>
          <h3 class="bigNumberCircle">{{ fitness }}</h3>
        </div>
        <div class="col-3">
          <h4 style="font-size: 15px">{{ todayText }}</h4>
          <h3 class="bigNumberCircle">{{ today }}</h3>
        </div>
      </div>
      <!--
      <div class="top-spacer-25" style="font-weight: bold">
        <div v-if="ermuedung > fitness + 5">
          Du baust Leistungsfähigkeit auf
        </div>
        <div v-if="ermuedung <= fitness + 5 && ermuedung > fitness - 5">
          Du hälst deine Leistungsfähigkeit relativ stabil
        </div>
        <div v-if="ermuedung <= fitness - 5 && ermuedung > fitness - 15">
          Du baust eher Leistungsfähigkeit ab
        </div>
        <div v-if="ermuedung <= fitness - 15">
          Du baust Leistungsfähigkeit ab
        </div>

        <div v-if="form >= 15">Du bist in guter Form und Wettkampfbereit</div>
        <div v-if="form < 15 && form >= 0">
          Du befindest dich in einer Erholungsphase
        </div>
        <div v-if="form < 0 && form >= -10">Du bist normal belastet</div>
        <div v-if="form < -10 && form >= -20">
          Du bist in einer Belastungsphase und eher nicht wettkampfbereit
        </div>
        <div v-if="form < -20">
          Du bist in einer größeren Belastung und vermutlich nicht
          wettkampfbereit.
        </div>
      </div>-->
    </div>
    <div style="height: 300px" class="top-spacer-25 white_wrapper_tighter">
      <canvas ref="stressGraph" id="stressGraph" width="100%"></canvas>
    </div>
  </div>
</template>

<script>
import { Chart } from "chart.js";
import moment from "moment-timezone";

export default {
  props: ["fromDay", "until", "done", "todayText"],

  data: function () {
    return {
      // Gehe 42 Tage zurück, für 42 / 10 Tage Load
      from: moment(this.fromDay).add(-42, "d"),
      workouts: [],
      loadedWeeks: this.weeks,
      loadOnlydone: this.done,
      fitness: 0,
      today: 0,
      ermuedung: 0,
      form: 0,
      days: [1, 2, 3, 4, 5, 6, 7],

      chartdata: {
        labels: [],
        datasets: [
          {
            label: "Fitness",
            borderColor: "#4CAF50", // Green
            backgroundColor: "#4CAF50",
            data: [],
            yAxisID: "y",
            borderWidth: 3,
            order: 10,
            tension: 0.4,
          },
          {
            label: "Ermüdung",
            borderColor: "#FF5722", // Deep Orange
            backgroundColor: "#FF5722",
            data: [],
            yAxisID: "y",
            borderWidth: 3,
            fill: false,
            order: 5,
            tension: 0.4,
          },
          {
            label: "Form",
            backgroundColor: "#2196F3", // Blue
            borderColor: "#2196F3",
            data: [],
            yAxisID: "y",
            borderWidth: 3,
            fill: false,
            order: 0,
            tension: 0.4,
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        plugins: {
          legend: {
            display: true,
          },
        },

        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },

        scales: {
          y: {
            grid: {
              display: true,
            },
            stacked: false,
            id: "stack",
            position: "right",
            display: true,

            ticks: {
              beginAtZero: true,
              maxTicksLimit: 15,
            },
          },
          xAxes: {
            grid: {
              display: true,
            },
            id: "x-axis-1",
            gridLines: {
              display: false,
            },
            stacked: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              beginAtZero: true,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  async mounted() {
    let ctx = this.$refs.stressGraph.getContext("2d");

    this.workouts = this.done;
    this.workouts42d = this.done42d;

    this.computeChartData();

    const config = {
      type: "line",
      data: this.chartdata,
      options: this.options,
      plugins: [
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.y;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }
            }
          },
        },
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.yAxes;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }
            }
          },
        },
      ],
    };

    new Chart(ctx, config);
    this.$emit("chartRendered");
  },

  methods: {
    formatDate: function (value) {
      return value.format("DD.MM");
    },
    getDayOfWeek: function (week, day) {
      return this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(day - 1, "d");
    },

    totalWorkoutLength(workoutFile) {
      let length = workoutFile.reduce(function (prev, cur) {
        return (
          prev +
          (cur.Duration > 0 ? cur.Duration : 0) +
          (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0)
        );
      }, 0);
      return length;
    },

    computeAvg(arr) {
      const sum = arr.reduce((a, b) => a + b, 0);
      return sum / arr.length || 0;
    },

    computeWeightedAvg(arr) {
      const sum = arr.reduce((a, b, index) => a + b * (1 + index / arr.length), 0);
      return sum / arr.length || 0;
    },

    computeChartData: function () {
      // `this` points to the vm instance
      let smoothed = [];
      let smoothed42 = [];

      while (this.from.isSameOrBefore(this.until, "day")) {
        let tomorrow = this.$moment(this.from);
        tomorrow.add(1, "d");

        let workoutsOfDate = this.workouts.filter((workout) => {
          return (
            this.$moment(workout.date).isSameOrAfter(this.from) &&
            this.$moment(workout.date).isBefore(this.$moment(tomorrow))
          );
        });

        let durationSwim = 0;
        let durationBike = 0;
        let durationRun = 0;
        let durationKraft = 0;

        workoutsOfDate.forEach((workout) => {
          if (workout.sport == "Laufen") {
            durationRun += workout.pss;
          }
          if (workout.sport == "Radfahren") {
            durationBike += workout.pss;
          }
          if (workout.sport == "Schwimmen") {
            durationSwim += workout.pss;
          }
          if (workout.sport == "Kraft") {
            durationKraft += workout.pss;
          }
        });

        smoothed.push(durationRun + durationBike + durationSwim + durationKraft);
        smoothed42.push(durationRun + durationBike + durationSwim + durationKraft);

        if (smoothed.length > 10) {
          smoothed.shift();
        }
        if (smoothed42.length > 42) {
          smoothed42.shift();
        }

        if (this.from.isSameOrAfter(this.fromDay)) {
          this.chartdata.labels.push(this.formatDate(this.from));

          if (this.$moment(this.from).isSameOrBefore(this.$moment())) {
            this.chartdata.datasets[0].data.push(Math.round(this.computeWeightedAvg(smoothed42)));
            this.chartdata.datasets[1].data.push(Math.round(this.computeWeightedAvg(smoothed)));
            this.chartdata.datasets[2].data.push(
              Math.round(this.computeWeightedAvg(smoothed42) - this.computeWeightedAvg(smoothed))
            );
          }
        }

        this.today = smoothed[smoothed.length - 1];

        this.from = this.from.add(1, "d");
      }

      const fit = this.computeWeightedAvg(smoothed42);
      const fatigue = this.computeWeightedAvg(smoothed);
      this.form = Math.round(fit - fatigue);
      this.fitness = Math.round(fit);
      this.ermuedung = Math.round(fatigue);

      return this.chartdata;
    },
  },
};
</script>
