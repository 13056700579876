<template>
  <div class="row">
    <div class="submenu col-xl-2">
      <div class="width-wrap top-spacer-25 sticky-top">
        <div class="row">
          <div class="col-lg-12">
            <a :href="'/#/auswertung/workouts?from=' + customFromMoment / 1000 + '&until=' + customTillMoment / 1000">
              <div class="menu-entry" :class="active_menu == 'workouts' ? 'active_submenu' : ''">
                {{ $t("Einzelne Einheiten") }}
              </div></a
            >
          </div>
          <div class="col-lg-12">
            <a :href="'/#/auswertung/uebersicht?from=' + customFromMoment / 1000 + '&until=' + customTillMoment / 1000">
              <div class="menu-entry" :class="active_menu == 'uebersicht' ? 'active_submenu' : ''">
                {{ $t("Übersicht") }}
              </div>
            </a>
          </div>

          <div class="col-lg-12">
            <a :href="'/#/auswertung/belastung?from=' + customFromMoment / 1000 + '&until=' + customTillMoment / 1000">
              <div class="menu-entry" :class="active_menu == 'belastung' ? 'active_submenu' : ''">
                {{ $t("Belastung & Fitness") }}
              </div></a
            >
          </div>
          <div class="col-lg-12">
            <a :href="'/#/auswertung/zonen?from=' + customFromMoment / 1000 + '&until=' + customTillMoment / 1000">
              <div class="menu-entry" :class="active_menu == 'zonen' ? 'active_submenu' : ''">
                {{ $t("Zeit in Zonen") }}
              </div></a
            >
          </div>

          <div class="col-lg-12">
            <a :href="'/#/auswertung/vergleiche?from=' + customFromMoment / 1000 + '&until=' + customTillMoment / 1000">
              <div class="menu-entry" :class="active_menu == 'vergleiche' ? 'active_submenu' : ''">
                {{ $t("Leistungen/HR") }}
                <!-- TODO: Scatter Chart mit  (leistung oder speed) / bpm  auf Y
               und Zeit auf X Achse und Trendlinie zur Leistungsentwicklung! -->
              </div></a
            >
          </div>

          <div class="col-lg-12">
            <a
              :href="
                '/#/auswertung/substratverbrauch?from=' + customFromMoment / 1000 + '&until=' + customTillMoment / 1000
              "
            >
              <div class="menu-entry" :class="active_menu == 'substratverbrauch' ? 'active_submenu' : ''">
                {{ $t("Substratverbrauch") }}
                <!-- TODO: Scatter Chart mit  (leistung oder speed) / bpm  auf Y
               und Zeit auf X Achse und Trendlinie zur Leistungsentwicklung! -->
              </div></a
            >
          </div>
          <!--
        <div class="col-lg-12">
          <a href="/#/auswertung/form">
            <div
              class="menu-entry"
              :style="
                active_menu == 'form'
                  ? 'background:rgb(100, 149, 237) none repeat scroll 0% 0%;color:#000;border-radius:5px'
                  : ''
              "
            >
              {{$t("Fitness und Form")}}
            </div></a
          >
        </div>-->

          <div class="col-lg-12">
            <a
              :href="
                '/#/auswertung/leistungskurven?from=' + customFromMoment / 1000 + '&until=' + customTillMoment / 1000
              "
            >
              <div class="menu-entry" :class="active_menu == 'leistungskurven' ? 'active_submenu' : ''">
                {{ $t("Leistungskurven") }}
              </div></a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-10" id="auswertung">
      <div class="col-12 top-spacer-10" v-if="warnPremiumExpiration" style="text-align: center; color: #fff">
        <div
          class="col-12"
          style="
            background-size: 200% auto;
            padding: 30px;
            background: #000;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
          "
        >
          <br />

          {{
            $t(
              "Hinweis: Dein Zugang ist abgelaufen. Um dauerhaft alle Funktionen nutzen zu können, kannst du über die App im Profil ein Abo abschließen."
            )
          }}

          <h2>
            {{ $t(`Die Auswertungen stehen nur unseren zahlenden Club Mitgliedern zur Verfügung.`) }}
          </h2>
        </div>
      </div>

      <div class="col-lg-12 white_wrapper_tighter date-selection-wrapper" v-if="!warnPremiumExpiration">
        <div class="date-selection-container">
          <div class="row">
            <div class="col-md-8 mb-3 mb-md-0">
              <div
                class="date-range-buttons d-flex flex-wrap justify-content-center justify-content-md-start"
                style="margin-top: 2px"
              >
                <button @click="setDateRange('week')" class="btn btn-sm btn-outline-secondary me-2 mb-2">Woche</button>
                <button @click="setDateRange('month')" class="btn btn-sm btn-outline-secondary me-2 mb-2">Monat</button>
                <button @click="setDateRange('quarter')" class="btn btn-sm btn-outline-secondary me-2 mb-2">
                  Quartal
                </button>
                <button @click="setDateRange('year')" class="btn btn-sm btn-outline-secondary mb-2">Jahr</button>
              </div>
            </div>
            <div class="col-md-4">
              <div class="date-navigation d-flex align-items-center justify-content-center justify-content-md-end">
                <div class="pointer me-3" @click="previousPeriod()">
                  <i class="fa fa-chevron-left"></i>
                </div>
                <div class="date-inputs d-flex flex-column flex-sm-row align-items-center">
                  <input type="date" v-model="customFrom" @change="refresh()" />
                  <input type="date" v-model="customTill" @change="refresh()" />
                </div>
                <div class="pointer ms-3" @click="nextPeriod()">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="spinner-border text-primary top-spacer-25" role="status" v-if="!loaded"></div>
      <div v-if="!warnPremiumExpiration && loaded" class="top-spacer-15" style="min-height: 800px; padding-top: 0px">
        <div v-if="workouts.length == 0">
          <div style="padding-top: 50px">
            <h4>Es liegen keine Daten vor</h4>
            <h4>
              <small>{{ $t("Wähle einen anderen Zeitraum oder lade mehr Daten hoch") }}</small>
            </h4>
          </div>
        </div>

        <div class="row" v-if="active_menu == 'vergleiche' && workouts.length > 0 && loaded">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-6">
                <div class="white_wrapper_tighter top-spacer-10">
                  <h4>Entwicklung: Rad-Leistung / Herzfrequenz</h4>
                  <div
                    class="spinner-border text-primary top-spacer-25"
                    role="status"
                    v-if="scatterSpeedChartLoading"
                  ></div>
                  <time-power-hr-scatter-chart
                    @chartRendered="scatterSpeedChartRendered()"
                    style="height: 275px"
                    :key="'x' + customFromMoment + customTillMoment + '5scatter'"
                    v-if="smallSummaries.length > 0"
                    v-bind:done="smallSummaries"
                    v-bind:reducex="false"
                    v-bind:fromDay="$moment(customFromMoment)"
                    v-bind:until="$moment(customTillMoment)"
                  />
                  <time-bar
                    :from="$moment(customFromMoment).format('DD.MM.YYYY')"
                    :until="$moment(customTillMoment).format('DD.MM.YYYY')"
                  />
                </div>
              </div>
              <!--
              <div class="col-lg-6">
                <div class="white_wrapper_tighter top-spacer-10">
                  <h4>Entwicklung: Rad-Leistung / Herzfrequenz</h4>
                  <div
                    class="spinner-border text-primary top-spacer-25"
                    role="status"
                    v-if="scatterSpeedChartLoading"
                  ></div>
                  <time-power-hr-scatter-chart
                    @chartRendered="scatterSpeedChartRendered()"
                    style="height: 275px"
                    :key="
                      'x' + customFromMoment + customTillMoment + '5scatter'
                    "
                    v-if="smallSummariesLIT.length > 0"
                    v-bind:done="smallSummariesLIT"
                    v-bind:reducex="false"
                    v-bind:fromDay="$moment(customFromMoment)"
                    v-bind:until="$moment(customTillMoment)"
                  />
                  <time-bar
                    :from="$moment(customFromMoment).format('DD.MM.YYYY')"
                    :until="$moment(customTillMoment).format('DD.MM.YYYY')"
                  />
                </div>


                
              </div>-->
              <div class="col-lg-6">
                <div class="white_wrapper_tighter top-spacer-10">
                  <h4>Vergleich: Rad-Leistung zu Herzfrequenz</h4>
                  <div
                    class="spinner-border text-primary top-spacer-25"
                    role="status"
                    v-if="scatterSpeedChartLoading"
                  ></div>
                  <power-hr-scatter-chart
                    @chartRendered="scatterPowerChartRendered()"
                    v-if="smallSummaries.length > 0"
                    v-bind:done="smallSummaries"
                    style="height: 275px"
                    v-bind:reducex="false"
                    :key="'x' + customFromMoment + customTillMoment + '4scatter'"
                    v-bind:fromDay="$moment(customFromMoment)"
                    v-bind:until="$moment(customTillMoment)"
                  />
                  <time-bar
                    :from="$moment(customFromMoment).format('DD.MM.YYYY')"
                    :until="$moment(customTillMoment).format('DD.MM.YYYY')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-6">
                <div class="white_wrapper_tighter top-spacer-25">
                  <h4>Entwicklung: Lauf-Geschwindigkeit / Herzfrequenz</h4>
                  <div
                    class="spinner-border text-primary top-spacer-25"
                    role="status"
                    v-if="scatterSpeedChartLoading"
                  ></div>
                  <time-speed-hr-scatter-chart
                    v-if="smallSummaries.length > 0"
                    v-bind:done="smallSummaries"
                    style="height: 275px"
                    :key="'x' + customFromMoment + customTillMoment + '5scatter' + smallSummaries.length"
                    v-bind:reducex="false"
                    v-bind:fromDay="$moment(customFromMoment)"
                    v-bind:until="$moment(customTillMoment)"
                  />
                  <time-bar
                    :from="$moment(customFromMoment).format('DD.MM.YYYY')"
                    :until="$moment(customTillMoment).format('DD.MM.YYYY')"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="top-spacer-15 d-md-none"></div>

                <div class="white_wrapper_tighter top-spacer-25">
                  <h4>Vergleich: Lauf-Geschwindigkeit zu Herzfrequenz</h4>
                  <div
                    class="spinner-border text-primary top-spacer-25"
                    role="status"
                    v-if="scatterSpeedChartLoading"
                  ></div>
                  <speed-hr-scatter-chart
                    @chartRendered="scatterSpeedChartRendered()"
                    v-if="smallSummaries.length > 0"
                    v-bind:done="smallSummaries"
                    style="height: 275px"
                    :key="'x' + customFromMoment + customTillMoment + '5scatter'"
                    v-bind:reducex="false"
                    v-bind:fromDay="$moment(customFromMoment)"
                    v-bind:until="$moment(customTillMoment)"
                  />
                  <time-bar
                    :from="$moment(customFromMoment).format('DD.MM.YYYY')"
                    :until="$moment(customTillMoment).format('DD.MM.YYYY')"
                  />
                </div>
              </div>
            </div>
          </div>
          <!--deaktiviert-->
          <div class="col-lg-6 top-spacer-25" style="padding-top: 0px">
            <div class="white_wrapper">
              <h4>Rad: Leistungstendenz im Pulsbereich</h4>
              Achtung: Benötigt möglichst viele Einheiten, funktioniert also am Besten über lange Zeiträume. Passe ggf.
              oben den Zeitraum an.
              <bike-power-at-bpm-scatter-chart
                class="top-spacer-25"
                v-if="smallSummaries.length > 0"
                v-bind:allSummaries="smallSummaries"
                :key="'x' + customFromMoment + customTillMoment + '98-scatter' + smallSummaries.length"
              />
            </div>
          </div>
          <div class="col-lg-6 top-spacer-25" style="padding-top: 0px">
            <div class="white_wrapper">
              <h4>Lauf: Leistungstendenz im Pulsbereich</h4>
              Achtung: Benötigt möglichst viele Einheiten, funktioniert also am Besten über lange Zeiträume. Passe ggf.
              oben den Zeitraum an.
              <run-speed-at-bpm-scatter-chart
                class="top-spacer-25"
                v-if="smallSummaries.length > 0"
                v-bind:allSummaries="smallSummaries"
                :key="'x' + customFromMoment + customTillMoment + '99-scatter' + smallSummaries.length"
              />
            </div>
          </div>
          <div class="col-lg-12">
            <div class="white_wrapper top-spacer-25" style="text-align: left; font-size: 15px">
              <h4>Erklärung</h4>
              Je größer ein Punkt, desto länger die Einheit. Mit einem Klick auf den Punkt kannst du die Einheit
              öffnen.<br /><br />

              Die Trendlinien sind nur über längere Zeiträume aussagekräftig, bei kürzeren können Ausreißer die Linien
              stark beeinflussen.<br /><br />

              Bei den <strong>Vergleichen</strong> gilt: je höher ein Punkt, desto höher die Leistung zu einer
              Herzfrequenz. <br />Liegen z.B. die roten Punkte in einer Spalte über den blauen Punkten, so hat sich die
              Leistung zu der jeweiligen Herzfrequenz über die Zeit verbessert.<br />
              Ein Punkt, der weiter links liegt, wurde bei einer niedrigeren Herzfrequenz erreicht.<br /><br />
              <strong>Insgesamt gilt: je höher, desto besser war die Leistung.</strong><br /><br />
              Ausreißer können z.B. Einheiten mit vielen Höhenmetern (beim Laufen), sehr lange Einheiten oder
              Intervalleinheiten sein.<br />Je konstanter die Leistung einer Einheit, desto besser lässt sich hier die
              Leistungsfähigkeit ablesen.
            </div>
          </div>
        </div>

        <div
          class="row top-spacer-25"
          v-if="
            loaded &&
            active_menu == 'substratverbrauch' &&
            workouts.length > 0 &&
            this.beginThresholds &&
            this.endThresholds
          "
        >
          <div class="col-lg-12">
            <div class="white_wrapper_tighter">
              <div class="col-lg-12 top-spacer-15">
                <h4>Energieverbrauch der Trainingseinheiten</h4>
              </div>
              <div class="styled-table">
                <div class="chart-wrapper-tighter centered">
                  <energy-consumption
                    :key="this.loadStart + this.dataInterval + 'aax' + smallSummaries.length"
                    v-bind:workouts="smallSummaries"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="white_wrapper_tighter top-spacer-25">
              <div class="col-lg-12">
                <h4>Substratverbrauch zu Leistung - Radfahren</h4>
                <small
                  >Hinweis: Die Leistungen werden aus den Schwellenwerten, die du im Profil einträgst, errechnet. Wenn
                  im ausgewählten Zeitraum verschiedene Schwellenwerte vorlagen, werden hier die absolut beste und
                  schlechteste Leistung angezeigt.<br />
                  Die Werte sind Schätzungen. Es gilt Grün = LIT, Gelb = MIT, Rot = HIT</small
                >
              </div>
              <div class="row">
                <div class="col-lg-6 top-spacer-25">
                  <h4>Schlechteste Form im Zeitraum</h4>
                  <div class="styled-table">
                    <div class="chart-wrapper-tighter centered">
                      <fat-cho-utilization-mix-bike
                        :key="'x' + key"
                        v-bind:ftp="this.beginThresholds.ftp"
                        v-bind:weight="this.beginThresholds.weight"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 top-spacer-25">
                  <div class="d-block d-md-none top-spacer-25"></div>
                  <h4>Bestform im Zeitraum</h4>

                  <div class="styled-table">
                    <div class="chart-wrapper-tighter centered">
                      <fat-cho-utilization-mix-bike
                        :key="'x2' + key"
                        v-bind:ftp="this.endThresholds.ftp"
                        v-bind:weight="this.endThresholds.weight"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="white_wrapper_tighter top-spacer-25">
              <div class="col-lg-12 top-spacer-50">
                <h4>Substratverbrauch zu Leistung - Laufen</h4>
              </div>
              <div class="row">
                <div class="col-lg-6 top-spacer-25">
                  <h4>Schlechteste Form im Zeitraum</h4>

                  <div class="styled-table">
                    <div class="chart-wrapper-tighter centered">
                      <fat-cho-utilization-mix-run
                        :key="'x3' + key"
                        v-bind:threshold_run="this.endThresholds.threshold_run"
                        v-bind:weight="this.endThresholds.weight"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 top-spacer-25">
                  <div class="d-block d-md-none top-spacer-25"></div>

                  <h4>Bestform im Zeitraum</h4>

                  <div class="styled-table">
                    <div class="chart-wrapper-tighter centered">
                      <fat-cho-utilization-mix-run
                        :key="'x4' + key"
                        v-bind:threshold_run="this.beginThresholds.threshold_run"
                        v-bind:weight="this.beginThresholds.weight"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="top-spacer-50" style="text-align: center; width: 100%">
            <small style="color: #888"
              >VO2max Berechnungen basierend auf Paper "Validation of a New Method for Estimating VO2max Based on VO2
              Reserve", DOI: 10.1249/01.mss.0000135774.28494.19</small
            >
          </div>
        </div>

        <div class="row" v-if="active_menu == 'form' && workouts.length > 0 && loaded">
          <div class="col-lg-12">
            <h4 class="top-spacer-25">Fitness und Form</h4>
            <div class="spinner-border text-primary top-spacer-25" role="status" v-if="rampChartLoading"></div>

            <stress-chart-fitness
              @chartRendered="rampChartRendered()"
              style="height: 225px"
              v-bind:todayText="'Tag'"
              v-bind:done="true"
              :key="this.loadStart + this.dataInterval + '4'"
              v-bind:fromDay="$moment(customFromMoment)"
              v-bind:until="$moment(customTillMoment)"
            />

            <p class="top-spacer-50">
              Der graue Bereich zeigt deine Fitness. Er beschreibt deine Trainingsbelastung der letzten Wochen.
              <br />Die blaue Linie zeigt deine Belastung, dabei handelt es sich um die gemittelte Trainingsbelastung
              der letzten 10 Tage. <br />Damit deine Fitness steigt, muss die Belastung über deiner Fitness liegen.
              <br />Die schwarze Linie ist deine Form. Der Wert errechnet sich aus Fitness minus Belastung. Ein
              negativer Wert zeigt steigende Ermüdung und ist notwendig, um fitter zu werden.<br />Die Ermüdung sollte
              aber nicht zu groß und insbesondere vor Wettkämpfen reduziert werden. <br />Versuche, in Belastungsphasen
              die schwarze Linie im grünen Bereich zu halten.
            </p>
          </div>
        </div>

        <div
          class="row top-spacer-25"
          v-if="active_menu == 'belastung' && workouts.length > 0 && workouts42d.length > 0 && loaded"
        >
          <div class="col-lg-6">
            <div class="white_wrapper_tighter">
              <h5>Bleib im grünen Bereich!</h5>
              <div class="spinner-border text-primary top-spacer-50" role="status" v-if="rampChartLoading"></div>
              <stress-chart-form-ramp
                @chartRendered="rampChartRendered()"
                v-bind:done="workouts"
                :key="this.loadStart + this.dataInterval + '3'"
                v-bind:fromDay="customFromMoment"
                v-bind:until="customTillMoment"
              />
            </div>
            <div class="top-spacer-25"></div>
            <div style="padding-bottom: 120px">
              <div>
                <stress-chart-fitness
                  v-bind:todayText="'Tag'"
                  style="height: 340px"
                  v-bind:done="workouts42d"
                  :key="this.loadStart + this.dataInterval + '4'"
                  v-bind:fromDay="customFromMoment"
                  v-bind:until="customTillMoment"
                />
              </div>
            </div>
          </div>

          <div class="col-lg-6" style="text-align: left; font-size: 15px">
            <div class="row">
              <div class="col-lg-12">
                <div class="white_wrapper">
                  <h4>Bedeutung der Belastungsbereiche</h4>

                  <p>
                    <br />Hinweis: Es müssen einige Wochen Trainingsdaten vorliegen, damit die Belastungsbereiche
                    sinnvoll sind. <br /><br />Roter Hintergrund: starke Belastungssteigerung - mögliche Überlastung
                    oder intensiver Block (z.B. Trainingslager). Bleibe nicht zu lange in diesem Bereich! <br /><br />

                    Grüner Hintergrund: Formaufbau <br /><br />
                    Grauer Hintergrund: Erholung oder Formabbau<br /><br />
                    Optimalerweise verbringst du einen großen Teil der Zeit im unteren grünen Bereich.
                  </p>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="top-spacer-25 d-md-none"></div>

                <div class="white_wrapper top-spacer-25">
                  <h4>Bedeutung der Fitness</h4>
                  <p>
                    <br />
                    Die Fitness ist eine Annäherung an deine aktuelle Leistungsfähigkeit. Je höher der Wert, desto mehr
                    hast du trainiert.<br /><br />
                    Damit deine Fitness steigt, muss deine Ermüdung (= kurzfristige Belastung) über deiner aktuellen
                    Fitness (=langfristige Belastung liegen). <br /><br />
                    Deine Form ist deine Fitness minus Ermüdung. Je höher deine Form, desto besser bist du erholt.<br /><br />
                    Hinweis: Deine Ermüdung kann an einem Tag auch sinken, obwohl du viel trainiert hast. Sie berechnet
                    sich aus den letzten 10 Tagen - fällt also eine Einheit mit einer Belastung von 100, die du vor 10
                    Tagen trainiert hast, heraus und du trainierst mit einer Belastung von 80, so sinkt deine Ermüdung
                    trotz Trainings. Entsprechend ist die Ermüdung ein Wert, der auf 10 Tage und nicht auf einen
                    einzelnen Tag genau betrachtet werden sollte.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-1"></div>
        </div>

        <div v-if="active_menu == 'leistungskurven' && loaded">
          <div v-if="summaries">
            <div class="row">
              <div v-if="totals.bp_5" class="col-lg-12">
                <div class="white_wrapper">
                  <h4>Rad-Leistung</h4>
                  <div class="top-spacer-25">
                    <div class="row">
                      <div class="col-6 col-lg-2">
                        <div class="row">
                          <div class="col-6 col-lg-6">
                            <strong>Dauer</strong>
                          </div>
                          <div class="col-6 col-lg-6">
                            <strong>Leistung</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-lg-6">5s</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_5) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">10s</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_10) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">15s</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_15) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">30s</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_30) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">60s</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_60) }}w</div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-lg-6">90s</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_90) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">2m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_120) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">3m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_180) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">4m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_240) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">5m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_300) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">6m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_360) }}w</div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-lg-6">7m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_420) }}w</div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-lg-6">8m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_480) }}w</div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-lg-6">9m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_540) }}w</div>
                        </div>
                      </div>

                      <div class="col-6 col-lg-2">
                        <div class="row">
                          <div class="col-6 col-lg-6">
                            <strong>Dauer</strong>
                          </div>
                          <div class="col-6 col-lg-6">
                            <strong>Leistung</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-lg-6">10m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_600) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">12m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_720) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">14m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_840) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">16m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_960) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">18m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_1080) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">20m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_1200) }}w</div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-lg-6">25m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_1500) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">30m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_1800) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">40m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_2400) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">50m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_3000) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">60m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_3600) }}w</div>
                        </div>

                        <div class="row">
                          <div class="col-6 col-lg-6">90m</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_5400) }}w</div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-lg-6">2h</div>
                          <div class="col-6 col-lg-6">{{ Math.round(totals.bp_7200) }}w</div>
                        </div>
                        <br /><br />
                      </div>

                      <div class="col-lg-8 col-12">
                        <workout-power-analysis-chart
                          style="height: 330px"
                          color="black"
                          text="Zeitraum"
                          alltime_color="orange"
                          fourtyTwoDays_color="rgb(100,150,100)"
                          v-bind:alltime="alltimetotals.bp"
                          label="w"
                          v-bind:fourtyTwoDays="fourtyTwoDaysPower.bp"
                          v-bind:bp="[
                            totals.bp_5,
                            totals.bp_10,
                            totals.bp_15,
                            totals.bp_30,
                            totals.bp_60,
                            totals.bp_90,
                            totals.bp_120,
                            totals.bp_180,
                            totals.bp_240,
                            totals.bp_300,
                            totals.bp_360,
                            totals.bp_420,
                            totals.bp_480,
                            totals.bp_540,
                            totals.bp_600,
                            totals.bp_720,
                            totals.bp_840,
                            totals.bp_960,
                            totals.bp_1080,
                            totals.bp_1200,
                            totals.bp_1500,
                            totals.bp_1800,
                            totals.bp_2400,
                            totals.bp_3000,
                            totals.bp_3600,
                            totals.bp_5400,
                            totals.bp_7200,
                            totals.bp_10800,
                            totals.bp_14400,
                          ]"
                          :key="
                            'bp' +
                            key +
                            getFormattedWeekFrom() +
                            getFormattedWeekTill() +
                            totals.bp_5 +
                            totals.bp_10 +
                            totals.bp_15 +
                            +totals.bp_30 +
                            totals.bp_60
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="totals.speed_run_5" class="col-lg-12">
                <div class="white_wrapper top-spacer-25">
                  <h4>Lauf-Geschwindigkeit</h4>

                  <div class="row top-spacer-25">
                    <div class="col-6 col-lg-2">
                      <div class="row">
                        <div class="col-6 col-lg-6">
                          <strong>Dauer</strong>
                        </div>
                        <div class="col-6 col-lg-6">
                          <strong>Leistung</strong>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 col-lg-6">5s</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_5) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">10s</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_10) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">15s</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_15) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">30s</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_30) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">60s</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_60) }}m/km</div>
                      </div>
                      <div class="row">
                        <div class="col-6 col-lg-6">90s</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_90) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">2m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_120) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">3m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_180) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">4m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_240) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">5m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_300) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">6m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_360) }}m/km</div>
                      </div>
                      <div class="row">
                        <div class="col-6 col-lg-6">7m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_420) }}m/km</div>
                      </div>
                      <div class="row">
                        <div class="col-6 col-lg-6">8m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_480) }}m/km</div>
                      </div>
                      <div class="row">
                        <div class="col-6 col-lg-6">9m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_540) }}m/km</div>
                      </div>
                    </div>

                    <div class="col-6 col-lg-2">
                      <div class="row">
                        <div class="col-6 col-lg-6">
                          <strong>Dauer</strong>
                        </div>
                        <div class="col-6 col-lg-6">
                          <strong>Leistung</strong>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 col-lg-6">10m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_600) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">12m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_720) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">14m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_840) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">16m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_960) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">18m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_1080) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">20m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_1200) }}m/km</div>
                      </div>
                      <div class="row">
                        <div class="col-6 col-lg-6">25m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_1500) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">30m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_1800) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">40m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_2400) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">50m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_3000) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">60m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_3600) }}m/km</div>
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6">90m</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_5400) }}m/km</div>
                      </div>
                      <div class="row">
                        <div class="col-6 col-lg-6">2h</div>
                        <div class="col-6 col-lg-6">{{ $parent.kmh2mkm(totals.speed_run_7200) }}m/km</div>
                      </div>
                      <br /><br />
                    </div>

                    <div class="col-lg-8">
                      <div>
                        <workout-power-analysis-chart
                          style="height: 330px"
                          color="black"
                          text="Zeitraum"
                          alltime_color="orange"
                          label="km/h"
                          fourtyTwoDays_color="rgb(100,150,100)"
                          v-bind:alltime="alltimetotals.runspeed"
                          v-bind:fourtyTwoDays="fourtyTwoDaysPower.runspeed"
                          v-bind:bp="[
                            totals.speed_run_5,
                            totals.speed_run_10,
                            totals.speed_run_15,
                            totals.speed_run_30,
                            totals.speed_run_60,
                            totals.speed_run_90,
                            totals.speed_run_120,
                            totals.speed_run_180,
                            totals.speed_run_240,
                            totals.speed_run_300,
                            totals.speed_run_360,
                            totals.speed_run_420,
                            totals.speed_run_480,
                            totals.speed_run_540,
                            totals.speed_run_600,
                            totals.speed_run_720,
                            totals.speed_run_840,
                            totals.speed_run_960,
                            totals.speed_run_1080,
                            totals.speed_run_1200,
                            totals.speed_run_1500,
                            totals.speed_run_1800,
                            totals.speed_run_2400,
                            totals.speed_run_3000,
                            totals.speed_run_3600,
                            totals.speed_run_5400,
                            totals.speed_run_7200,
                            totals.speed_run_10800,
                            totals.speed_run_14400,
                          ]"
                          :key="
                            'speed_run' +
                            key +
                            getFormattedWeekFrom() +
                            getFormattedWeekTill() +
                            totals.speed_run_5 +
                            totals.speed_run_10 +
                            totals.speed_run_15 +
                            totals.speed_run_30 +
                            totals.speed_run_60
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="row">
                  <div v-if="totals.hr_5" class="col-lg-6">
                    <div class="white_wrapper top-spacer-25">
                      <h4>Herzfrequenz</h4>
                      <div class="top-spacer-25">
                        <workout-power-analysis-chart
                          style="height: 240px"
                          color="black"
                          v-bind:alltime="alltimetotals.hr"
                          v-bind:fourtyTwoDays="fourtyTwoDaysPower.hr"
                          alltime_color="orange"
                          label="bpm"
                          fourtyTwoDays_color="rgb(100,150,100)"
                          text="Zeitraum"
                          v-bind:bp="[
                            totals.hr_5,
                            totals.hr_10,
                            totals.hr_15,
                            totals.hr_30,
                            totals.hr_60,
                            totals.hr_90,
                            totals.hr_120,
                            totals.hr_180,
                            totals.hr_240,
                            totals.hr_300,
                            totals.hr_360,
                            totals.hr_420,
                            totals.hr_480,
                            totals.hr_540,
                            totals.hr_600,
                            totals.hr_720,
                            totals.hr_840,
                            totals.hr_960,
                            totals.hr_1080,
                            totals.hr_1200,
                            totals.hr_1500,
                            totals.hr_1800,
                            totals.hr_2400,
                            totals.hr_3000,
                            totals.hr_3600,
                            totals.hr_5400,
                            totals.hr_7200,
                            totals.hr_10800,
                            totals.hr_14400,
                          ]"
                          :key="
                            'hr' +
                            key +
                            getFormattedWeekFrom() +
                            getFormattedWeekTill() +
                            totals.hr_5 +
                            totals.hr_10 +
                            totals.hr_15 +
                            totals.hr_30 +
                            totals.hr_60
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div v-if="totals.speed_bike_5" class="col-lg-6">
                    <div class="white_wrapper top-spacer-25">
                      <h4>Rad-Geschwindigkeit</h4>
                      <div class="top-spacer-25">
                        <workout-power-analysis-chart
                          style="height: 240px"
                          color="black"
                          alltime_color="orange"
                          label="km/h"
                          fourtyTwoDays_color="rgb(100,150,100)"
                          v-bind:alltime="alltimetotals.bikespeed"
                          v-bind:fourtyTwoDays="fourtyTwoDaysPower.bikespeed"
                          text="Zeitraum"
                          v-bind:bp="[
                            totals.speed_bike_5,
                            totals.speed_bike_10,
                            totals.speed_bike_15,
                            totals.speed_bike_30,
                            totals.speed_bike_60,
                            totals.speed_bike_90,
                            totals.speed_bike_120,
                            totals.speed_bike_180,
                            totals.speed_bike_240,
                            totals.speed_bike_300,
                            totals.speed_bike_360,
                            totals.speed_bike_420,
                            totals.speed_bike_480,
                            totals.speed_bike_540,
                            totals.speed_bike_600,
                            totals.speed_bike_720,
                            totals.speed_bike_840,
                            totals.speed_bike_960,
                            totals.speed_bike_1080,
                            totals.speed_bike_1200,
                            totals.speed_bike_1500,
                            totals.speed_bike_1800,
                            totals.speed_bike_2400,
                            totals.speed_bike_3000,
                            totals.speed_bike_3600,
                            totals.speed_bike_5400,
                            totals.speed_bike_7200,
                            totals.speed_bike_10800,
                            totals.speed_bike_14400,
                          ]"
                          :key="
                            'speed_bike' +
                            key +
                            getFormattedWeekFrom() +
                            getFormattedWeekTill() +
                            totals.speed_bike_5 +
                            totals.speed_bike_10 +
                            totals.speed_bike_15 +
                            totals.speed_bike_30 +
                            totals.speed_bike_60
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="active_menu == 'uebersicht' && loaded">
          <div v-if="workouts.length > 0" class="row top-spacer-25">
            <!--<h4>Zusammenfassung</h4>-->
            <div class="col-lg-3">
              <div class="white_wrapper_tighter">
                <div class="row">
                  <div class="col-lg-12 col-6 col-md-3">
                    <div class="d-block d-lg-none">&nbsp;</div>
                    <div class="d-none d-lg-block top-spacer-25"></div>

                    <h4>
                      <img src="@/assets/images/stopwatch.png" style="width: 35px" />
                    </h4>
                    <h4 style="width: 100%; text-align: center">
                      <strong>{{ workouts.length }} Einheiten</strong> <br />
                      {{ formattedTimeSlash(sum(workouts, "durationIs")) }}
                      <br />
                      {{
                        Math.round(((workout_swim_distance + workout_bike_distance + workout_run_distance) * 10) / 10)
                      }}km
                    </h4>
                  </div>
                  <hr class="d-none d-lg-block top-spacer-25" />

                  <div class="col-lg-12 col-md-3 col-6">
                    <div class="top-spacer-25"></div>

                    <h4>
                      <img src="@/assets/images/bike.png" style="width: 35px" />
                    </h4>
                    <h4>
                      {{ workout_bike_count }}
                      <strong>
                        <span v-if="!(workout_bike_count == 1)"> Einheiten</span>
                        <span v-if="workout_bike_count == 1"> Einheit</span></strong
                      ><br />
                      {{ formattedTimeSlash(workout_bike_duration) }}
                      <br />{{ Math.round(workout_bike_distance * 10) / 10 }}km
                    </h4>
                  </div>
                  <hr class="d-none d-lg-block top-spacer-25" />

                  <div class="col-lg-12 col-md-3 col-6">
                    <div class="top-spacer-25"></div>

                    <h4>
                      <img src="@/assets/images/run.png" style="width: 35px" />
                    </h4>
                    <h4>
                      {{ workout_run_count }}
                      <strong
                        ><span v-if="!(workout_run_count == 1)"> Einheiten</span>
                        <span v-if="workout_run_count == 1"> Einheit</span></strong
                      ><br />
                      {{ formattedTimeSlash(workout_run_duration) }}
                      <br />{{ Math.round(workout_run_distance * 10) / 10 }}km
                    </h4>
                  </div>
                  <hr class="d-none d-lg-block top-spacer-25" />

                  <div class="col-lg-12 col-md-3 col-6">
                    <div class="top-spacer-25"></div>
                    <h4>
                      <img src="@/assets/images/swim.png" style="width: 35px" />
                    </h4>
                    <h4>
                      {{ workout_swim_count }}
                      <strong
                        ><span v-if="!(workout_swim_count == 1)"> Einheiten</span>
                        <span v-if="workout_swim_count == 1"> Einheit</span></strong
                      >

                      <br />{{ formattedTimeSlash(workout_swim_duration) }} <br />{{
                        Math.round((workout_swim_distance * 10) / 10)
                      }}km
                    </h4>
                  </div>
                  <hr class="d-none d-lg-block top-spacer-25" />

                  <div class="col-lg-12 col-md-3 col-6">
                    <div class="top-spacer-25"></div>

                    <h4>
                      <img src="@/assets/images/strength.png" style="width: 35px" />
                    </h4>
                    <h4>
                      {{ workout_strength_count }}
                      <strong
                        ><span v-if="!(workout_strength_count == 1)"> Einheiten</span>
                        <span v-if="workout_strength_count == 1"> Einheit</span></strong
                      >
                      <br />{{ formattedTimeSlash(workout_strength_duration) }}
                    </h4>
                  </div>
                  <hr class="d-none d-lg-block top-spacer-25" />

                  <div class="col-lg-12 col-md-3 col-6">
                    <div class="top-spacer-25"></div>

                    <h4>
                      <img src="@/assets/images/activity.png" style="width: 35px" />
                    </h4>
                    <h4>
                      {{ workout_activity_count }}
                      <strong
                        ><span v-if="!(workout_activity_count == 1)"> Einheiten</span>
                        <span v-if="workout_activity_count == 1"> Einheit</span></strong
                      >
                      <br />{{ formattedTimeSlash(workout_activity_duration) }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-9">
              <div class="col-lg-12 white_wrapper_tighter">
                <div class="spinner-border text-primary top-spacer-25" role="status" v-if="dChartLoading"></div>
                <workout-analysis-pss-chart
                  class="top-spacer-25"
                  style="height: 180px"
                  v-bind:done="workouts"
                  :key="loadStart + dataInterval + '15'"
                  @chartRendered="pssChartRendered()"
                  v-bind:from="$moment(customFromMoment)"
                  v-bind:until="$moment(customTillMoment)"
                />
              </div>

              <div class="col-lg-12 top-spacer-25 white_wrapper_tighter">
                <div class="spinner-border text-primary top-spacer-25" role="status" v-if="tdiChartLoading"></div>

                <workout-analysis-intensity-chart
                  @chartRendered="tdiChartRendered()"
                  class="top-spacer-25"
                  style="height: 180px"
                  v-bind:done="workouts"
                  :key="loadStart + dataInterval + '18'"
                  v-bind:from="$moment(customFromMoment)"
                  v-bind:until="$moment(customTillMoment)"
                />
              </div>
              <div class="col-lg-12 top-spacer-25 white_wrapper_tighter">
                <div class="spinner-border text-primary top-spacer-25" role="status" v-if="tdChartLoading"></div>

                <workout-analysis-load-chart
                  @chartRendered="tdChartRendered()"
                  class="top-spacer-25"
                  style="height: 180px"
                  v-bind:done="workouts"
                  :key="loadStart + dataInterval + '14'"
                  v-bind:from="$moment(customFromMoment)"
                  v-bind:until="$moment(customTillMoment)"
                />
              </div>
              <div class="col-lg-12 top-spacer-25 white_wrapper_tighter">
                <div class="spinner-border text-primary top-spacer-25" role="status" v-if="dChartLoading"></div>
                <workout-analysis-distance-chart
                  class="top-spacer-25"
                  style="height: 180px"
                  v-bind:done="workouts"
                  :key="loadStart + dataInterval + '13'"
                  @chartRendered="dChartRendered()"
                  v-bind:from="$moment(customFromMoment)"
                  v-bind:until="$moment(customTillMoment)"
                />
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <threshold-run-chart
                    :done="summaries"
                    :key="'thresh-run' + summaries.length + customFromMoment + customTillMoment"
                  />
                </div>
                <div class="col-lg-12">
                  <div>
                    <threshold-bike-chart
                      :done="summaries"
                      :key="'thresh-bike' + summaries.length + customFromMoment + customTillMoment"
                    />
                  </div>
                </div>
              </div>
              <div
                class="row"
                v-show="Object.values(health).some((value) => Object.keys(value).length > 0) && !healthChartLoading"
              >
                <div class="col-lg-12">
                  <health-chart-heart-rate
                    v-if="health && hasRestingHeartRateData()"
                    :health="health"
                    @chartRendered="healthChartRendered()"
                    :key="'health-chart' + customFromMoment + customTillMoment + '_' + healthChartLoading"
                  />
                </div>
                <div class="col-lg-12">
                  <health-chart-sleep
                    v-if="health && hasSleepData()"
                    :health="health"
                    @chartRendered="healthChartRendered()"
                    :key="'health-chart' + customFromMoment + customTillMoment + '_' + healthChartLoading"
                  />
                </div>
                <div class="col-lg-12">
                  <health-chart-heart-rate-variability
                    v-if="health && hasHRVData()"
                    :health="health"
                    @chartRendered="healthChartRendered()"
                    :key="'health-chart' + customFromMoment + customTillMoment + '_' + healthChartLoading"
                  />
                </div>

                <div class="top-spacer-25"></div>
              </div>
              <div class="row">
                <div class="col-lg-12 top-spacer-25">
                  <div class="white_wrapper_tighter">
                    <div style="text-align: left">
                      <h4>Hinweis zu Schwellenschätzungen</h4>
                      Wenn du Einheiten mit Herzfrequenzmesser und Geschwindigkeitsmesser (Lauf) bzw Herzfrequenzmesser
                      und Wattmesser (Rad) absolvierst, berechnen wir automatisch Schwellenschätzungen. Es müssen
                      mindestens zwei Einheiten absolviert werden, damit hier die Graphiken dazu auftauchen.<br /><br />
                      <strong
                        >Tipp: Wähle einen langen Zeitraum zur Auswertung, indem du oben in den Datumsfeldern
                        entsprechende Tage wählst</strong
                      ><br /><br />

                      Die blaue Linie stellt jedes einzelne Workout dar. Die schwarze Linie ist eine Glättung und
                      insgesamt als genauer zu betrachten als die Einzelwerte. <br /><br />Die Schätzung der Schwellen
                      funktioniert am Besten, wenn deine maximale Herzfrequenz möglichst genau angegeben ist. Sie ist
                      als ungefähre Einschätzung anzusehen. Jedes Workout wird ausgewertet und dabei sind Schwankungen
                      (z.B. durch Tagesform, Vorbelastungen, usw.) normal. Die Grafiken können dabei helfen, deine
                      mittel- und langfristige Leistungsentwicklung einzuschätzen. Die errechneten Werte stimmen nicht
                      unbedingt mit deinen tatsächlichen Schwellen überein, können aber eine gute Annäherung bieten.<br /><br />Berücksichtigt
                      werden nur Workouts, die ab dem 06.11.2022 hochgeladen wurden.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="active_menu == 'zonen' && summaries.length > 0">
          <div class="row top-spacer-25">
            <div class="col-xl-6">
              <div class="row">
                <div class="col-xl-12 col-md-6">
                  <div class="white_wrapper_tighter">
                    <h4 class="top-spacer-25">Rad-Leistung</h4>

                    <table class="styled-table">
                      <thead>
                        <tr>
                          <th>Zone</th>
                          <th>Zeit</th>
                          <th style="text-align: left">Verteilung</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Z1: </strong>

                            <small>(bis {{ Math.round(this.endThresholds.ftp * threshold_bike_zone_1) }}w)</small>
                          </td>
                          <td>{{ formattedTimeSlash(totals.time_zone_1) }}</td>
                          <td>
                            <span
                              class="bar"
                              v-bind:style="'width:' + timePercentage(totals.time_zone_1, totals.time_total) + '%'"
                              >&nbsp;</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Z2: </strong>

                            <small
                              >({{
                                Math.min(
                                  Math.round(this.endThresholds.ftp * threshold_bike_zone_1),
                                  Math.round(this.beginThresholds.ftp * threshold_bike_zone_2)
                                )
                              }}
                              bis
                              {{ Math.round(this.endThresholds.ftp * threshold_bike_zone_2) }}w)</small
                            >
                          </td>
                          <td>{{ formattedTimeSlash(totals.time_zone_2) }}</td>
                          <td>
                            <span
                              class="bar"
                              v-bind:style="'width:' + timePercentage(totals.time_zone_2, totals.time_total) + '%'"
                              >&nbsp;</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Z3: </strong>

                            <small
                              >({{
                                Math.min(
                                  Math.round(this.endThresholds.ftp * threshold_bike_zone_2),
                                  Math.round(this.beginThresholds.ftp * threshold_bike_zone_3)
                                )
                              }}
                              bis
                              {{ Math.round(this.endThresholds.ftp * threshold_bike_zone_3) }}w)</small
                            >
                          </td>
                          <td>{{ formattedTimeSlash(totals.time_zone_3) }}</td>
                          <td>
                            <span
                              class="bar"
                              v-bind:style="'width:' + timePercentage(totals.time_zone_3, totals.time_total) + '%'"
                              >&nbsp;</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Z4: </strong>

                            <small
                              >({{
                                Math.min(
                                  Math.round(this.endThresholds.ftp * threshold_bike_zone_3),
                                  Math.round(this.beginThresholds.ftp * threshold_bike_zone_4)
                                )
                              }}
                              bis
                              {{ Math.round(this.endThresholds.ftp * threshold_bike_zone_4) }}w)</small
                            >
                          </td>
                          <td>{{ formattedTimeSlash(totals.time_zone_4) }}</td>
                          <td>
                            <span
                              class="bar"
                              v-bind:style="'width:' + timePercentage(totals.time_zone_4, totals.time_total) + '%'"
                              >&nbsp;</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Z5: </strong> <small>(mehr)</small></td>
                          <td>
                            {{ formattedTimeSlash(totals.time_zone_5) }}
                          </td>
                          <td>
                            <span
                              class="bar"
                              v-bind:style="'width:' + timePercentage(totals.time_zone_5, totals.time_total) + '%'"
                              >&nbsp;</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="col-lg-12 top-spacer-25">
                      <div class="row">
                        <div class="col-4">
                          <div style="padding: 15px">
                            <h4>LIT</h4>
                            <h2>
                              {{
                                Math.round(timePercentage(totals.time_zone_1 + totals.time_zone_2, totals.time_total))
                              }}%
                            </h2>
                          </div>
                        </div>
                        <div class="col-4">
                          <div style="padding: 15px">
                            <h4>MIT</h4>
                            <h2>{{ Math.round(timePercentage(totals.time_zone_3, totals.time_total)) }}%</h2>
                          </div>
                        </div>
                        <div class="col-4">
                          <div style="padding: 15px">
                            <h4>HIT</h4>
                            <h2>
                              {{
                                Math.round(timePercentage(totals.time_zone_4 + totals.time_zone_5, totals.time_total))
                              }}%
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-1"></div>
                      <div class="col-lg-10">
                        <div class="progress">
                          <div
                            class="progress-bar bg-success"
                            role="progressbar"
                            v-bind:style="
                              'width:' +
                              timePercentage(totals.time_zone_1 + totals.time_zone_2, totals.time_total) +
                              '%'
                            "
                            v-bind:aria-valuenow="
                              timePercentage(totals.time_zone_1 + totals.time_zone_2, totals.time_total)
                            "
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                          <div
                            class="progress-bar bg-info"
                            role="progressbar"
                            v-bind:style="
                              'background-color: rgb(232, 126, 4)!important;' +
                              'width:' +
                              timePercentage(totals.time_zone_3, totals.time_total) +
                              '%'
                            "
                            v-bind:aria-valuenow="timePercentage(totals.time_zone_3, totals.time_total)"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                          <div
                            class="progress-bar bg-danger"
                            role="progressbar"
                            v-bind:style="
                              'width:' +
                              timePercentage(totals.time_zone_4 + totals.time_zone_5, totals.time_total) +
                              '%'
                            "
                            v-bind:aria-valuenow="
                              timePercentage(totals.time_zone_4 + totals.time_zone_5, totals.time_total)
                            "
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-12 col-md-6">
                  <div class="d-xl-block d-md-none top-spacer-25"></div>

                  <div class="white_wrapper_tighter">
                    <h4 class="top-spacer-25">Lauf-Geschwindigkeit</h4>

                    <table class="styled-table">
                      <thead>
                        <tr>
                          <th>Zone</th>
                          <th>Zeit</th>
                          <th style="text-align: left">Verteilung</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Z1: </strong>
                            <small
                              v-if="
                                secondsToMinPerKm(this.beginThresholds.threshold_run * threshold_run_speed_zone_1) !=
                                secondsToMinPerKm(this.endThresholds.threshold_run * threshold_run_speed_zone_1)
                              "
                              >({{ secondsToMinPerKm(this.beginThresholds.threshold_run * threshold_run_speed_zone_1) }}
                              bis
                              {{
                                secondsToMinPerKm(this.endThresholds.threshold_run * threshold_run_speed_zone_1)
                              }})</small
                            >
                            <small
                              v-if="
                                secondsToMinPerKm(this.beginThresholds.threshold_run * threshold_run_speed_zone_1) ==
                                secondsToMinPerKm(this.endThresholds.threshold_run * threshold_run_speed_zone_1)
                              "
                            >
                              (bis
                              {{
                                secondsToMinPerKm(this.endThresholds.threshold_run * threshold_run_speed_zone_1)
                              }})</small
                            >
                          </td>
                          <td>
                            {{ formattedTimeSlash(totals.speed_run_zone_1_sum) }}
                          </td>
                          <td>
                            <span
                              class="bar"
                              v-bind:style="
                                'width:' + timePercentage(totals.speed_run_zone_1_sum, totals.run_total_time) + '%'
                              "
                              >&nbsp;</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Z2: </strong>

                            <small
                              >({{ secondsToMinPerKm(this.beginThresholds.threshold_run * threshold_run_speed_zone_2) }}
                              bis
                              {{
                                secondsToMinPerKm(
                                  Math.max(
                                    this.beginThresholds.threshold_run * threshold_run_speed_zone_1,
                                    this.endThresholds.threshold_run * threshold_run_speed_zone_2
                                  )
                                )
                              }})</small
                            >
                          </td>
                          <td>
                            {{ formattedTimeSlash(totals.speed_run_zone_2_sum) }}
                          </td>
                          <td>
                            <span
                              class="bar"
                              v-bind:style="
                                'width:' + timePercentage(totals.speed_run_zone_2_sum, totals.run_total_time) + '%'
                              "
                              >&nbsp;</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Z3: </strong>

                            <small
                              >({{ secondsToMinPerKm(this.endThresholds.threshold_run * threshold_run_speed_zone_3) }}
                              bis
                              {{
                                secondsToMinPerKm(
                                  Math.max(this.beginThresholds.threshold_run * threshold_run_speed_zone_2),
                                  this.beginThresholds.threshold_run * threshold_run_speed_zone_3
                                )
                              }})</small
                            >
                          </td>
                          <td>
                            {{ formattedTimeSlash(totals.speed_run_zone_3_sum) }}
                          </td>
                          <td>
                            <span
                              class="bar"
                              v-bind:style="
                                'width:' + timePercentage(totals.speed_run_zone_3_sum, totals.run_total_time) + '%'
                              "
                              >&nbsp;</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Z4: </strong>

                            <small
                              >({{ secondsToMinPerKm(this.endThresholds.threshold_run * threshold_run_speed_zone_4) }}
                              bis
                              {{
                                secondsToMinPerKm(
                                  Math.max(
                                    this.endThresholds.threshold_run * threshold_run_speed_zone_3,
                                    this.endThresholds.threshold_run * threshold_run_speed_zone_4
                                  )
                                )
                              }})</small
                            >
                          </td>
                          <td>
                            {{ formattedTimeSlash(totals.speed_run_zone_4_sum) }}
                          </td>
                          <td>
                            <span
                              class="bar"
                              v-bind:style="
                                'width:' + timePercentage(totals.speed_run_zone_4_sum, totals.run_total_time) + '%'
                              "
                              >&nbsp;</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Z5: </strong> <small>(schneller)</small></td>
                          <td>
                            {{ formattedTimeSlash(totals.speed_run_zone_5_sum) }}
                          </td>
                          <td>
                            <span
                              class="bar"
                              v-bind:style="
                                'width:' + timePercentage(totals.speed_run_zone_5_sum, totals.run_total_time) + '%'
                              "
                              >&nbsp;</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="col-lg-12 top-spacer-25">
                      <div class="row">
                        <div class="col-4">
                          <div style="padding: 15px">
                            <h4>LIT</h4>
                            <h2>
                              {{
                                Math.round(
                                  timePercentage(
                                    totals.speed_run_zone_1_sum + totals.speed_run_zone_2_sum,
                                    totals.run_total_time
                                  )
                                )
                              }}%
                            </h2>
                          </div>
                        </div>
                        <div class="col-4">
                          <div style="padding: 15px">
                            <h4>MIT</h4>
                            <h2>
                              {{ Math.round(timePercentage(totals.speed_run_zone_3_sum, totals.run_total_time)) }}%
                            </h2>
                          </div>
                        </div>
                        <div class="col-4">
                          <div style="padding: 15px">
                            <h4>HIT</h4>
                            <h2>
                              {{
                                Math.round(
                                  timePercentage(
                                    totals.speed_run_zone_4_sum + totals.speed_run_zone_5_sum,
                                    totals.run_total_time
                                  )
                                )
                              }}%
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-1"></div>
                      <div class="col-lg-10">
                        <div class="progress">
                          <div
                            class="progress-bar bg-success"
                            role="progressbar"
                            v-bind:style="
                              'width:' +
                              timePercentage(
                                totals.speed_run_zone_1_sum + totals.speed_run_zone_2_sum,
                                totals.run_total_time
                              ) +
                              '%'
                            "
                            v-bind:aria-valuenow="
                              timePercentage(
                                totals.speed_run_zone_1_sum + totals.speed_run_zone_2_sum,
                                totals.run_total_time
                              )
                            "
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                          <div
                            class="progress-bar bg-info"
                            role="progressbar"
                            v-bind:style="
                              'background-color: rgb(232, 126, 4)!important;' +
                              'width:' +
                              timePercentage(totals.speed_run_zone_3_sum, totals.run_total_time) +
                              '%'
                            "
                            v-bind:aria-valuenow="timePercentage(totals.speed_run_zone_3_sum, totals.run_total_time)"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                          <div
                            class="progress-bar bg-danger"
                            role="progressbar"
                            v-bind:style="
                              'width:' +
                              timePercentage(
                                totals.speed_run_zone_4_sum + totals.speed_run_zone_5_sum,
                                totals.run_total_time
                              ) +
                              '%'
                            "
                            v-bind:aria-valuenow="
                              timePercentage(
                                totals.speed_run_zone_4_sum + totals.speed_run_zone_5_sum,
                                totals.run_total_time
                              )
                            "
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="col-lg-1"></div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-12 col-md-6">
                  <div class="top-spacer-25"></div>

                  <div class="white_wrapper_tighter">
                    <h4 class="top-spacer-25">Herzfrequenz</h4>

                    <table class="styled-table">
                      <thead>
                        <tr>
                          <th>Zone (bpm)</th>
                          <th>Zeit</th>
                          <th style="text-align: left">Verteilung</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Z1: </strong>

                            <small>(bis {{ Math.round(this.endThresholds.maxHr * threshold_hr_zone_1) }})</small>
                          </td>
                          <td>{{ formattedTimeSlash(totals.hr_zone_1) }}</td>
                          <td>
                            <span
                              class="bar"
                              v-bind:style="'width:' + hrPercentage(totals.hr_zone_1, totals.hr_total_time) + '%'"
                              >&nbsp;</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Z2: </strong>

                            <small
                              >({{
                                Math.min(
                                  Math.round(this.endThresholds.maxHr * threshold_hr_zone_1),
                                  Math.round(this.beginThresholds.maxHr * threshold_hr_zone_2)
                                )
                              }}
                              bis
                              {{ Math.round(this.endThresholds.maxHr * threshold_hr_zone_2) }})</small
                            >
                          </td>
                          <td>{{ formattedTimeSlash(totals.hr_zone_2) }}</td>
                          <td>
                            <span
                              class="bar"
                              v-bind:style="'width:' + hrPercentage(totals.hr_zone_2, totals.hr_total_time) + '%'"
                              >&nbsp;</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Z3: </strong>

                            <small
                              >({{
                                Math.min(
                                  Math.round(this.beginThresholds.maxHr * threshold_hr_zone_2),
                                  Math.round(this.beginThresholds.maxHr * threshold_hr_zone_3)
                                )
                              }}
                              bis
                              {{ Math.round(this.endThresholds.maxHr * threshold_hr_zone_3) }})</small
                            >
                          </td>
                          <td>{{ formattedTimeSlash(totals.hr_zone_3) }}</td>
                          <td>
                            <span
                              class="bar"
                              v-bind:style="'width:' + hrPercentage(totals.hr_zone_3, totals.hr_total_time) + '%'"
                              >&nbsp;</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Z4: </strong>

                            <small
                              >({{
                                Math.min(
                                  Math.round(this.endThresholds.maxHr * threshold_hr_zone_3),
                                  Math.round(this.beginThresholds.maxHr * threshold_hr_zone_4)
                                )
                              }}
                              bis
                              {{ Math.round(this.endThresholds.maxHr * threshold_hr_zone_4) }})</small
                            >
                          </td>
                          <td>{{ formattedTimeSlash(totals.hr_zone_4) }}</td>
                          <td>
                            <span
                              class="bar"
                              v-bind:style="'width:' + hrPercentage(totals.hr_zone_4, totals.hr_total_time) + '%'"
                              >&nbsp;</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Z5: </strong> <small>(höher)</small></td>
                          <td>{{ formattedTimeSlash(totals.hr_zone_5) }}</td>
                          <td>
                            <span
                              class="bar"
                              v-bind:style="'width:' + hrPercentage(totals.hr_zone_5, totals.hr_total_time) + '%'"
                              >&nbsp;</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="col-lg-12 top-spacer-25">
                      <div class="row">
                        <div class="col-4">
                          <div style="padding: 15px">
                            <h4>LIT</h4>
                            <h2>
                              {{
                                Math.round(timePercentage(totals.hr_zone_1 + totals.hr_zone_2, totals.hr_total_time))
                              }}%
                            </h2>
                          </div>
                        </div>
                        <div class="col-4">
                          <div style="padding: 15px">
                            <h4>MIT</h4>
                            <h2>{{ Math.round(timePercentage(totals.hr_zone_3, totals.hr_total_time)) }}%</h2>
                          </div>
                        </div>
                        <div class="col-4">
                          <div style="padding: 15px">
                            <h4>HIT</h4>
                            <h2>
                              {{
                                Math.round(timePercentage(totals.hr_zone_4 + totals.hr_zone_5, totals.hr_total_time))
                              }}%
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-1"></div>
                      <div class="col-lg-10">
                        <div class="progress">
                          <div
                            class="progress-bar bg-success"
                            role="progressbar"
                            v-bind:style="
                              'width:' + timePercentage(totals.hr_zone_1 + totals.hr_zone_2, totals.hr_total_time) + '%'
                            "
                            v-bind:aria-valuenow="
                              timePercentage(totals.hr_zone_1 + totals.hr_zone_1, totals.hr_total_time)
                            "
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                          <div
                            class="progress-bar bg-info"
                            role="progressbar"
                            v-bind:style="
                              'background-color: rgb(232, 126, 4)!important;' +
                              'width:' +
                              timePercentage(totals.hr_zone_3, totals.hr_total_time) +
                              '%'
                            "
                            v-bind:aria-valuenow="timePercentage(totals.hr_zone_3, totals.hr_total_time)"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                          <div
                            class="progress-bar bg-danger"
                            role="progressbar"
                            v-bind:style="
                              'width:' + timePercentage(totals.hr_zone_4 + totals.hr_zone_5, totals.hr_total_time) + '%'
                            "
                            v-bind:aria-valuenow="
                              timePercentage(totals.hr_zone_4 + totals.hr_zone_5, totals.hr_total_time)
                            "
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="row">
                <div class="col-xl-12 col-md-6">
                  <div class="top-spacer-25 d-md-none d-md-block d-xl-none"></div>
                  <div class="white_wrapper_tighter">
                    <h4 class="top-spacer-25">Intensitätsverteilung Einheiten</h4>
                    <workout-analysis-intensity-chart
                      @chartRendered="tdiChartRendered()"
                      class="top-spacer-25"
                      style="height: 240px"
                      v-if="workouts"
                      v-bind:done="workouts"
                      :key="loadStart + dataInterval + '18'"
                      v-bind:from="$moment(customFromMoment)"
                      v-bind:until="$moment(customTillMoment)"
                    />
                  </div>
                </div>
                <div class="col-xl-12 col-md-6">
                  <div class="top-spacer-25"></div>
                  <div class="white_wrapper_tighter">
                    <h4 class="top-spacer-25">Leistungsverteilung Rad</h4>
                    <total-hr-analysis-chart
                      class="top-spacer-25"
                      style="height: 240px"
                      v-bind:chartLabel="'watt'"
                      v-bind:type="'power'"
                      v-bind:data="powerDataDist"
                      :key="Math.max(powerDataDist)"
                    />
                  </div>
                </div>
                <div class="col-xl-12 col-md-6">
                  <div class="top-spacer-25"></div>

                  <div class="white_wrapper_tighter">
                    <h4 class="top-spacer-25 d-block">Leistungsverteilung Laufen</h4>
                    <total-hr-analysis-chart
                      class="top-spacer-25"
                      style="height: 240px"
                      v-bind:type="'run'"
                      v-bind:chartLabel="'min/km'"
                      v-bind:data="runDataDist"
                      :key="Math.max(runDataDist)"
                    />
                  </div>
                </div>

                <div class="col-xl-12 col-md-6">
                  <div class="top-spacer-25"></div>

                  <div class="white_wrapper_tighter">
                    <h4 class="top-spacer-25">Herzfrequenzverteilung</h4>
                    <total-hr-analysis-chart
                      class="top-spacer-25"
                      style="height: 240px"
                      v-bind:chartLabel="'bpm'"
                      v-bind:data="hrDataDist"
                      v-bind:type="'hr'"
                      :key="Math.max(hrDataDist)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="active_menu == 'workouts'">
          <div v-if="summaries.length > 0" class="white_wrapper_tighter top-spacer-25">
            <!--
            <div class="row" style="padding: 0 20px; font-weight: bold">
              <div class="col-lg-8 top-spacer-10"></div>
              <div class="col-lg-2 top-spacer-10">
                <h4>
                  <span style="color: green">LIT</span> /
                  <span style="color: rgb(232, 126, 4) !important">MIT</span> /
                  <span style="color: red">HIT</span>
                </h4>
              </div>
            </div>-->
            <div
              class="colored-row"
              style="padding: 20px"
              v-for="session in summaries"
              :key="session._id + session.total_timer_time + '-summaries'"
            >
              <div class="d-none d-lg-block">
                <div class="row" v-if="session.workout">
                  <div class="col-lg-2 top-spacer-10">
                    <h4>
                      {{ $moment(session.workout.date).format("DD.MM.YYYY") }}&nbsp;&nbsp;&nbsp;
                      <span style="position: relative">
                        <span v-if="session.workout.sport == 'Laufen'"
                          ><img src="@/assets/images/run.png" style="width: 20px"
                        /></span>
                        <span v-if="session.workout.sport == 'Schwimmen'"
                          ><img src="@/assets/images/swim.png" style="width: 20px"
                        /></span>
                        <span v-if="session.workout.sport == 'Radfahren'"
                          ><img src="@/assets/images/bike.png" style="width: 20px"
                        /></span>
                        <span v-if="session.workout.sport == 'Kraft'"
                          ><img src="@/assets/images/strength.png" style="width: 20px"
                        /></span>
                        <span v-if="session.workout.sport == 'Aktivität'"
                          ><img src="@/assets/images/activity.png" style="width: 20px"
                        /></span>
                      </span>
                    </h4>
                  </div>

                  <div class="col-lg-2 top-spacer-10">
                    <a v-bind:href="'/#/auswertung/workout/details/' + session.workout._id">
                      <h4>{{ session.workout.name }}</h4>
                    </a>
                  </div>
                  <div class="col-lg-2 top-spacer-10">
                    <h5>{{ formattedTime(session.total_timer_time) }}</h5>
                  </div>
                  <div class="col-lg-1 top-spacer-10">
                    <h4>
                      <span v-if="session.enhanced_avg_speed"
                        >{{ Math.round(session.enhanced_avg_speed * 10) / 10 }}<small>km/h</small></span
                      >

                      <span v-if="session.avg_speed && (!session.avg_power || session.workout.sport == 'Laufen')">
                        <span v-if="session.workout.sport != 'Laufen'">
                          {{ Math.round(session.avg_speed * 10) / 10 }}<small>km/h</small></span
                        >
                        <span v-if="session.workout.sport == 'Laufen'">
                          {{ $parent.kmh2mkm(session.avg_speed) }}<small>m/km</small></span
                        ></span
                      >

                      <span v-if="session.avg_power && session.workout.sport != 'Laufen'"
                        >{{ Math.round(session.avg_power) }}<small>w</small></span
                      >
                    </h4>
                  </div>

                  <div class="col-lg-1 top-spacer-10">
                    <div class="row">
                      <div v-bind:class="'col-lg-12'">
                        <!--colPssTss(session.workout.perceivedStress, session.tss)-->
                        <h4 v-if="session.workout.sport != 'Aktivität'">
                          {{ session.workout.pss }} <small>Load</small>
                        </h4>
                      </div>
                      <!--
                    <div
                      v-bind:class="
                        colPssTss(session.workout.perceivedStress, session.tss)
                      "
                      v-if="session.tss"
                    >
                      <h4>{{ Math.round(session.tss) }} cTSS</h4>
                    </div>-->
                    </div>
                  </div>

                  <div class="col-lg-2 top-spacer-15">
                    <!-- todo run speed / power -->
                    <div class="progress">
                      <div
                        class="progress-bar bg-success"
                        role="progressbar"
                        v-bind:style="
                          'width:' +
                          timePercentage(
                            session.workout.time_lit,
                            session.workout.time_lit + session.workout.time_mit + session.workout.time_hit
                          ) +
                          '%'
                        "
                        v-bind:aria-valuenow="
                          timePercentage(
                            session.workout.time_lit,
                            session.workout.time_lit + session.workout.time_mit + session.workout.time_hit
                          )
                        "
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-info"
                        role="progressbar"
                        v-bind:style="
                          'background-color: rgb(232, 126, 4)!important;' +
                          'width:' +
                          timePercentage(
                            session.workout.time_mit,
                            session.workout.time_lit + session.workout.time_mit + session.workout.time_hit
                          ) +
                          '%'
                        "
                        v-bind:aria-valuenow="
                          timePercentage(
                            session.workout.time_mit,
                            session.workout.time_lit + session.workout.time_mit + session.workout.time_hit
                          )
                        "
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-danger"
                        role="progressbar"
                        v-bind:style="
                          'width:' +
                          timePercentage(
                            session.workout.time_hit,
                            session.workout.time_lit + session.workout.time_mit + session.workout.time_hit
                          ) +
                          '%'
                        "
                        v-bind:aria-valuenow="
                          timePercentage(
                            session.workout.time_hit,
                            session.workout.time_lit + session.workout.time_mit + session.workout.time_hit
                          )
                        "
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>

                  <div class="col-lg-2 top-spacer-5">
                    <div class="top-spacer-15 d-block d-md-none"></div>

                    <a style="text-align: center" v-bind:href="'/#/auswertung/workout/details/' + session.workout._id">
                      <button class="secondary" style="max-width: 200px">Details</button>
                    </a>
                  </div>
                </div>
              </div>

              <!--MOBILE-->

              <div class="d-lg-none">
                <div class="row" v-if="session.workout">
                  <div class="col-lg-12 top-spacer-10">
                    <span class="pull-left">
                      <span v-if="session.workout.sport == 'Laufen'"
                        ><img src="@/assets/images/run.png" style="width: 20px"
                      /></span>
                      <span v-if="session.workout.sport == 'Schwimmen'"
                        ><img src="@/assets/images/swim.png" style="width: 20px"
                      /></span>
                      <span v-if="session.workout.sport == 'Radfahren'"
                        ><img src="@/assets/images/bike.png" style="width: 20px"
                      /></span>
                      <span v-if="session.workout.sport == 'Kraft'"
                        ><img src="@/assets/images/strength.png" style="width: 20px"
                      /></span>
                      <span v-if="session.workout.sport == 'Aktivität'"
                        ><img src="@/assets/images/activity.png" style="width: 20px"
                      /></span>
                    </span>
                    <strong>
                      {{ $moment(session.workout.date).format("DD.MM.YYYY") }}&nbsp;&nbsp;&nbsp;

                      <a v-bind:href="'/#/auswertung/workout/details/' + session.workout._id">
                        {{ session.workout.name }}
                      </a>
                    </strong>
                  </div>

                  <div class="col-lg-12 top-spacer-10">
                    {{ formattedTime(session.total_timer_time) }}

                    <span v-if="session.enhanced_avg_speed">
                      - {{ Math.round(session.enhanced_avg_speed * 10) / 10 }}<small>km/h</small></span
                    >

                    <span v-if="session.avg_speed && (!session.avg_power || session.workout.sport == 'Laufen')">
                      -
                      <span v-if="session.workout.sport != 'Laufen'">
                        {{ Math.round(session.avg_speed * 10) / 10 }}<small>km/h</small></span
                      >
                      <span v-if="session.workout.sport == 'Laufen'">
                        {{ $parent.kmh2mkm(session.avg_speed) }}<small>m/km</small></span
                      >
                    </span>
                    <span v-if="session.avg_power && session.workout.sport != 'Laufen'">
                      - {{ Math.round(session.avg_power) }}<small>w</small></span
                    >

                    <!--colPssTss(session.workout.perceivedStress, session.tss)-->
                    - Load: {{ session.workout.pss }}
                    <!--
                    <div
                      v-bind:class="
                        colPssTss(session.workout.perceivedStress, session.tss)
                      "
                      v-if="session.tss"
                    >
                      <h4>{{ Math.round(session.tss) }} cTSS</h4>
                    </div>-->
                  </div>

                  <div class="col-12 top-spacer-15">
                    <!-- todo run speed / power -->
                    <div class="progress">
                      <div
                        class="progress-bar bg-success"
                        role="progressbar"
                        v-bind:style="
                          'width:' +
                          timePercentage(
                            session.workout.time_lit,
                            session.workout.time_lit + session.workout.time_mit + session.workout.time_hit
                          ) +
                          '%'
                        "
                        v-bind:aria-valuenow="
                          timePercentage(
                            session.workout.time_lit,
                            session.workout.time_lit + session.workout.time_mit + session.workout.time_hit
                          )
                        "
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-info"
                        role="progressbar"
                        v-bind:style="
                          'background-color: rgb(232, 126, 4)!important;' +
                          'width:' +
                          timePercentage(
                            session.workout.time_mit,
                            session.workout.time_lit + session.workout.time_mit + session.workout.time_hit
                          ) +
                          '%'
                        "
                        v-bind:aria-valuenow="
                          timePercentage(
                            session.workout.time_mit,
                            session.workout.time_lit + session.workout.time_mit + session.workout.time_hit
                          )
                        "
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-danger"
                        role="progressbar"
                        v-bind:style="
                          'width:' +
                          timePercentage(
                            session.workout.time_hit,
                            session.workout.time_lit + session.workout.time_mit + session.workout.time_hit
                          ) +
                          '%'
                        "
                        v-bind:aria-valuenow="
                          timePercentage(
                            session.workout.time_hit,
                            session.workout.time_lit + session.workout.time_mit + session.workout.time_hit
                          )
                        "
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>

                  <div class="col-lg-2 top-spacer-5">
                    <div class="top-spacer-15 d-block d-md-none"></div>

                    <a style="text-align: center" v-bind:href="'/#/auswertung/workout/details/' + session.workout._id">
                      <button class="secondary" style="max-width: 200px">Details</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import WorkoutPowerAnalysisChart from "@/components/chart/WorkoutPowerAnalysisChart.vue";
import WorkoutAnalysisLoadChart from "@/components/chart/WorkoutAnalysisLoadChart.vue";
import WorkoutAnalysisIntensityChart from "@/components/chart/WorkoutAnalysisIntensityChart.vue";

import PowerHrScatterChart from "@/components/chart/PowerHrScatterChart.vue";
import SpeedHrScatterChart from "@/components/chart/SpeedHrScatterChart.vue";
import TimeSpeedHrScatterChart from "@/components/chart/TimeSpeedHrScatterChart.vue";
import TimePowerHrScatterChart from "@/components/chart/TimePowerHrScatterChart.vue";
import BikePowerAtBpmScatterChart from "@/components/chart/BikePowerAtBpmScatterChart.vue";
import RunSpeedAtBpmScatterChart from "@/components/chart/RunSpeedAtBpmScatterChart.vue";

import ThresholdRunChart from "@/components/chart/ThresholdRunChart.vue";
import ThresholdBikeChart from "@/components/chart/ThresholdBikeChart.vue";

import WorkoutAnalysisDistanceChart from "@/components/chart/WorkoutAnalysisDistanceChart.vue";
import WorkoutAnalysisPssChart from "@/components/chart/WorkoutAnalysisPssChart.vue";

/*import StressChart from "@/components/chart/StressChart.vue";*/
import StressChartFormRamp from "@/components/chart/StressChartFormRamp.vue";
import StressChartFitness from "@/components/chart/StressChartFitness.vue";
import TimeBar from "@/components/auswertung/TimeBar.vue";
import FatChoUtilizationMixBike from "@/components/chart/FatChoUtilizationMixBike.vue";
import FatChoUtilizationMixRun from "@/components/chart/FatChoUtilizationMixRun.vue";
import EnergyConsumption from "@/components/chart/EnergyConsumption.vue";

import HealthChartHeartRate from "@/components/chart/HealthChartHeartRate.vue";
import HealthChartSleep from "@/components/chart/HealthChartSleep.vue";

import HealthChartHeartRateVariability from "@/components/chart/HealthChartHeartRateVariability.vue";

import TotalHrAnalysisChart from "@/components/chart/TotalHrAnalysisChart.vue";

//import CTStressChart from "@/components/chart/CTStressChart.vue";

export default {
  metaInfo: {
    title: "Auswertung",
  },
  name: "Auswertung",
  components: {
    WorkoutPowerAnalysisChart,
    WorkoutAnalysisLoadChart,
    WorkoutAnalysisIntensityChart,
    TimePowerHrScatterChart,
    TimeSpeedHrScatterChart,
    PowerHrScatterChart,
    SpeedHrScatterChart,
    //StressChart,
    FatChoUtilizationMixBike,
    FatChoUtilizationMixRun,
    EnergyConsumption,
    BikePowerAtBpmScatterChart,
    RunSpeedAtBpmScatterChart,
    HealthChartHeartRate,
    HealthChartSleep,
    HealthChartHeartRateVariability,

    ThresholdRunChart,
    ThresholdBikeChart,
    TimeBar,
    StressChartFormRamp,
    StressChartFitness,
    TotalHrAnalysisChart,
    WorkoutAnalysisDistanceChart,
    WorkoutAnalysisPssChart,

    //CTStressChart,
  },
  props: {
    msg: String,
  },
  data: function () {
    return {
      beginThresholds: {},
      endThresholds: {},
      filteredThresholds: [],
      health: undefined,

      // TODO: SHARED LIB
      tdChartLoading: true,
      tdiChartLoading: true,
      dChartLoading: true,
      pssChartLoading: true,
      rampChartLoading: true,
      healthChartLoading: true,
      scatterSpeedChartLoading: true,
      scatterPowerChartLoading: true,

      thresholds: undefined,

      threshold_bike_zone_1: 0.55,
      threshold_bike_zone_2: 0.75,
      threshold_bike_zone_3: 0.95,
      threshold_bike_zone_4: 1.05,
      threshold_bike_zone_5: 999999999,

      // TODO...
      threshold_hr_zone_1: 0.6,
      threshold_hr_zone_2: 0.72,
      threshold_hr_zone_3: 0.82,
      threshold_hr_zone_4: 0.92,
      threshold_hr_zone_5: 999999999,

      threshold_run_speed_zone_1: 1.35,
      threshold_run_speed_zone_2: 1.2,
      threshold_run_speed_zone_3: 1.03,
      threshold_run_speed_zone_4: 0.92,
      threshold_run_speed_zone_5: 0.01,

      hrDataDist: [],
      runDataDist: [],

      powerDataDist: [],
      active_menu: "uebersicht",

      graphLoaded: false,
      loaded: false,
      dataInterval: "day",
      customFrom: this.$moment().add(-1, "month").format("yyyy-MM-DD"),
      customTill: this.$moment().format("yyyy-MM-DD"),

      customFromMoment: this.$moment().startOf("month"),
      customTillMoment: this.$moment().endOf("month"),

      workout_run_count: 0,
      workout_bike_count: 0,
      workout_strength_count: 0,
      workout_swim_count: 0,
      workout_activity_count: 0,

      workout_run_duration: 0,
      workout_bike_duration: 0,
      workout_strength_duration: 0,
      workout_swim_duration: 0,
      workout_activity_duration: 0,

      workout_run_distance: 0,
      workout_bike_distance: 0,
      workout_swim_distance: 0,
      workout_activity_distance: 0,

      summaries: [],
      loadStart: 0,

      workouts: [],
      workouts42d: [],

      smallSummaries: [],
      totals: {},
      alltimetotals: {},
      fourtyTwoDaysPower: {},

      key: 0,
      warnPremiumExpiration: false,
    };
  },
  mounted: function () {
    this.loaded = false;
    const element = document.getElementById("auswertung");

    const _self = this;
    element.scrollIntoView({ block: "start" });

    var scrolledY = window.scrollY;

    axios.get(this.$host + "/user/me").then(function (res) {
      _self.user = res.data;

      _self.threshold_hr_zone_1 = _self.user.threshold_hr_zone_1 / 100 || 0.6;
      _self.threshold_hr_zone_2 = _self.user.threshold_hr_zone_2 / 100 || 0.72;
      _self.threshold_hr_zone_3 = _self.user.threshold_hr_zone_3 / 100 || 0.82;
      _self.threshold_hr_zone_4 = _self.user.threshold_hr_zone_4 / 100 || 0.92;
      _self.threshold_hr_zone_5 = _self.user.threshold_hr_zone_5 / 100 || 999999999;

      if (
        !_self.$moment(_self.user.premiumAccountUntil).isAfter(_self.$moment()) &&
        !_self.$moment(_self.user.freeAccountUntil).isAfter(_self.$moment())
      ) {
        _self.warnPremiumExpiration = true;
      }
    });

    if (scrolledY) {
      window.scroll(
        0,
        scrolledY - 75 - window.getComputedStyle(document.getElementById("nav-bar")).paddingTop.slice(0, -2)
      );
    }

    if (!this.$route.query.from || !this.$route.query.until) {
      this.$router.replace({
        query: {
          from: this.$moment().add(-1, "month").startOf("day").unix(),
          until: this.$moment().endOf("day").unix(),
        },
      });
    } else {
      this.customFrom = this.$moment.unix(this.$route.query.from).format("YYYY-MM-DD");
      this.customTill = this.$moment.unix(this.$route.query.until).format("YYYY-MM-DD");

      this.customFromMoment = this.$moment(this.customFrom).startOf("day");
      this.customTillMoment = this.$moment(this.customTill).endOf("day");
    }
    this.refresh();
  },
  methods: {
    hasRestingHeartRateData() {
      for (const date in this.health) {
        const data = this.health[date];

        if (data.dailyData && data.dailyData.restingHeartRateInBeatsPerMinute) {
          return true;
        }
      }

      return false;
    },

    setDateRange(period) {
      const now = this.$moment();
      switch (period) {
        case "week":
          this.customFrom = now.startOf("week").format("YYYY-MM-DD");
          this.customTill = now.endOf("week").format("YYYY-MM-DD");
          break;
        case "month":
          this.customFrom = now.startOf("month").format("YYYY-MM-DD");
          this.customTill = now.endOf("month").format("YYYY-MM-DD");
          break;
        case "quarter":
          this.customFrom = now.startOf("quarter").format("YYYY-MM-DD");
          this.customTill = now.endOf("quarter").format("YYYY-MM-DD");
          break;
        case "year":
          this.customFrom = now.startOf("year").format("YYYY-MM-DD");
          this.customTill = now.endOf("year").format("YYYY-MM-DD");
          break;
      }
      this.refresh();
    },
    previousPeriod() {
      const from = this.$moment(this.customFrom);
      const till = this.$moment(this.customTill);
      const duration = till.diff(from, "days") + 1;
      this.customFrom = from.subtract(duration, "days").format("YYYY-MM-DD");
      this.customTill = till.subtract(duration, "days").format("YYYY-MM-DD");
      this.refresh();
    },
    nextPeriod() {
      const from = this.$moment(this.customFrom);
      const till = this.$moment(this.customTill);
      const duration = till.diff(from, "days") + 1;
      this.customFrom = from.add(duration, "days").format("YYYY-MM-DD");
      this.customTill = till.add(duration, "days").format("YYYY-MM-DD");
      this.refresh();
    },

    hasSleepData() {
      for (const date in this.health) {
        const data = this.health[date];

        if (data.sleepData && data.sleepData.durationInSeconds) {
          return true;
        }
      }

      return false;
    },
    hasHRVData() {
      for (const date in this.health) {
        const data = this.health[date];

        if (data.hrvData && data.hrvData.lastNightAvg) {
          return true;
        }
      }

      return false;
    },
    pssChartRendered() {
      this.pssChartLoading = false;
    },
    scatterSpeedChartRendered() {
      this.loaded = true;
      this.scatterSpeedChartLoading = false;
    },
    scatterPowerChartRendered() {
      this.loaded = true;

      this.scatterPowerChartLoading = false;
    },
    rampChartRendered() {
      this.loaded = true;

      this.rampChartLoading = false;
    },
    healthChartRendered() {
      this.healthChartLoading = false;
    },
    tdChartRendered() {
      this.loaded = true;

      this.tdChartLoading = false;
    },
    tdiChartRendered() {
      this.loaded = true;

      this.tdiChartLoading = false;
    },
    dChartRendered() {
      this.loaded = true;

      this.dChartLoading = false;
    },
    secondsToMinPerKm(seconds) {
      return this.$moment().startOf("day").seconds(seconds).format("mm:ss");
    },
    colPssTss(pss, tss) {
      let count = 0;
      if (pss) {
        count++;
      }
      if (tss) {
        count++;
      }
      return "col-lg-" + 12 / count;
    },
    computetotalTime() {
      //let time = 0;
      //let maxHr = 0;
      const _self = this;
      this.graphLoaded = false;

      _self.totals.speed_run_zone_1_sum = 0;
      _self.totals.speed_run_zone_2_sum = 0;
      _self.totals.speed_run_zone_3_sum = 0;
      _self.totals.speed_run_zone_4_sum = 0;
      _self.totals.speed_run_zone_5_sum = 0;
      _self.totals.run_total_time = 0;

      _self.totals.time_zone_1 = 0;
      _self.totals.time_zone_2 = 0;
      _self.totals.time_zone_3 = 0;
      _self.totals.time_zone_4 = 0;
      _self.totals.time_zone_5 = 0;
      _self.totals.total_timer_time = 0;

      _self.totals.hr_zone_1 = 0;
      _self.totals.hr_zone_2 = 0;
      _self.totals.hr_zone_3 = 0;
      _self.totals.hr_zone_4 = 0;
      _self.totals.hr_zone_5 = 0;
      _self.totals.hr_total_time = 0;

      _self.totals_time_hr = 0;
      _self.totals_time_power = 0;

      _self.totals.bp_5 = 0;
      _self.totals.bp_10 = 0;
      _self.totals.bp_15 = 0;
      _self.totals.bp_30 = 0;
      _self.totals.bp_60 = 0;
      _self.totals.bp_90 = 0;
      _self.totals.bp_120 = 0;
      _self.totals.bp_180 = 0;
      _self.totals.bp_240 = 0;
      _self.totals.bp_300 = 0;
      _self.totals.bp_360 = 0;
      _self.totals.bp_420 = 0;
      _self.totals.bp_480 = 0;
      _self.totals.bp_540 = 0;
      _self.totals.bp_600 = 0;
      _self.totals.bp_720 = 0;
      _self.totals.bp_840 = 0;
      _self.totals.bp_960 = 0;
      _self.totals.bp_1080 = 0;
      _self.totals.bp_1200 = 0;
      _self.totals.bp_1500 = 0;
      _self.totals.bp_1800 = 0;
      _self.totals.bp_2100 = 0;
      _self.totals.bp_2400 = 0;
      _self.totals.bp_2700 = 0;
      _self.totals.bp_3000 = 0;
      _self.totals.bp_3300 = 0;
      _self.totals.bp_3600 = 0;
      _self.totals.bp_4200 = 0;
      _self.totals.bp_4800 = 0;
      _self.totals.bp_5400 = 0;
      _self.totals.bp_6000 = 0;
      _self.totals.bp_7200 = 0;
      _self.totals.bp_9000 = 0;
      _self.totals.bp_10800 = 0;
      _self.totals.bp_14400 = 0;
      _self.totals.bp_18000 = 0;

      _self.totals.hr_5 = 0;
      _self.totals.hr_10 = 0;
      _self.totals.hr_15 = 0;
      _self.totals.hr_30 = 0;
      _self.totals.hr_60 = 0;
      _self.totals.hr_90 = 0;
      _self.totals.hr_120 = 0;
      _self.totals.hr_180 = 0;
      _self.totals.hr_240 = 0;
      _self.totals.hr_300 = 0;
      _self.totals.hr_360 = 0;
      _self.totals.hr_420 = 0;
      _self.totals.hr_480 = 0;
      _self.totals.hr_540 = 0;
      _self.totals.hr_600 = 0;
      _self.totals.hr_720 = 0;
      _self.totals.hr_840 = 0;
      _self.totals.hr_960 = 0;
      _self.totals.hr_1080 = 0;
      _self.totals.hr_1200 = 0;
      _self.totals.hr_1500 = 0;
      _self.totals.hr_1800 = 0;
      _self.totals.hr_2100 = 0;
      _self.totals.hr_2400 = 0;
      _self.totals.hr_2700 = 0;
      _self.totals.hr_3000 = 0;
      _self.totals.hr_3300 = 0;
      _self.totals.hr_3600 = 0;
      _self.totals.hr_4200 = 0;
      _self.totals.hr_4800 = 0;
      _self.totals.hr_5400 = 0;
      _self.totals.hr_6000 = 0;
      _self.totals.hr_7200 = 0;
      _self.totals.hr_9000 = 0;
      _self.totals.hr_10800 = 0;
      _self.totals.hr_14400 = 0;
      _self.totals.hr_18000 = 0;

      _self.totals.speed_run_5 = 0;
      _self.totals.speed_run_10 = 0;
      _self.totals.speed_run_15 = 0;
      _self.totals.speed_run_30 = 0;
      _self.totals.speed_run_60 = 0;
      _self.totals.speed_run_90 = 0;
      _self.totals.speed_run_120 = 0;
      _self.totals.speed_run_180 = 0;
      _self.totals.speed_run_240 = 0;
      _self.totals.speed_run_300 = 0;
      _self.totals.speed_run_360 = 0;
      _self.totals.speed_run_420 = 0;
      _self.totals.speed_run_480 = 0;
      _self.totals.speed_run_540 = 0;
      _self.totals.speed_run_600 = 0;
      _self.totals.speed_run_720 = 0;
      _self.totals.speed_run_840 = 0;
      _self.totals.speed_run_960 = 0;
      _self.totals.speed_run_1080 = 0;
      _self.totals.speed_run_1200 = 0;
      _self.totals.speed_run_1500 = 0;
      _self.totals.speed_run_1800 = 0;
      _self.totals.speed_run_2100 = 0;
      _self.totals.speed_run_2400 = 0;
      _self.totals.speed_run_2700 = 0;
      _self.totals.speed_run_3000 = 0;
      _self.totals.speed_run_3300 = 0;
      _self.totals.speed_run_3600 = 0;
      _self.totals.speed_run_4200 = 0;
      _self.totals.speed_run_4800 = 0;
      _self.totals.speed_run_5400 = 0;
      _self.totals.speed_run_6000 = 0;
      _self.totals.speed_run_7200 = 0;
      _self.totals.speed_run_9000 = 0;
      _self.totals.speed_run_10800 = 0;
      _self.totals.speed_run_14400 = 0;
      _self.totals.speed_run_18000 = 0;

      _self.totals.speed_bike_5 = 0;
      _self.totals.speed_bike_10 = 0;
      _self.totals.speed_bike_15 = 0;
      _self.totals.speed_bike_30 = 0;
      _self.totals.speed_bike_60 = 0;
      _self.totals.speed_bike_90 = 0;
      _self.totals.speed_bike_120 = 0;
      _self.totals.speed_bike_180 = 0;
      _self.totals.speed_bike_240 = 0;
      _self.totals.speed_bike_300 = 0;
      _self.totals.speed_bike_360 = 0;
      _self.totals.speed_bike_420 = 0;
      _self.totals.speed_bike_480 = 0;
      _self.totals.speed_bike_540 = 0;
      _self.totals.speed_bike_600 = 0;
      _self.totals.speed_bike_720 = 0;
      _self.totals.speed_bike_840 = 0;
      _self.totals.speed_bike_960 = 0;
      _self.totals.speed_bike_1080 = 0;
      _self.totals.speed_bike_1200 = 0;
      _self.totals.speed_bike_1500 = 0;
      _self.totals.speed_bike_1800 = 0;
      _self.totals.speed_bike_2100 = 0;
      _self.totals.speed_bike_2400 = 0;
      _self.totals.speed_bike_2700 = 0;
      _self.totals.speed_bike_3000 = 0;
      _self.totals.speed_bike_3300 = 0;
      _self.totals.speed_bike_3600 = 0;
      _self.totals.speed_bike_4200 = 0;
      _self.totals.speed_bike_4800 = 0;
      _self.totals.speed_bike_5400 = 0;
      _self.totals.speed_bike_6000 = 0;
      _self.totals.speed_bike_7200 = 0;
      _self.totals.speed_bike_9000 = 0;
      _self.totals.speed_bike_10800 = 0;
      _self.totals.speed_bike_14400 = 0;
      _self.totals.speed_bike_18000 = 0;

      _self.totals.speed_bike_zone_1_sum = 0;
      _self.totals.speed_bike_zone_2_sum = 0;
      _self.totals.speed_bike_zone_3_sum = 0;
      _self.totals.speed_bike_zone_4_sum = 0;
      _self.totals.speed_bike_zone_5_sum = 0;
      _self.totals.bike_total_time = 0;
      _self.totals.time_total = 0;

      this.summaries.forEach(function (summary) {
        // TODO: Fuer Total nur Zeiten mit tatsächlich vorhanden Daten der Zeit (Power / HR /...) berücksichtigen

        _self.totals.time_zone_1 += summary.time_zone_1;
        _self.totals.time_zone_2 += summary.time_zone_2;
        _self.totals.time_zone_3 += summary.time_zone_3;
        _self.totals.time_zone_4 += summary.time_zone_4;
        _self.totals.time_zone_5 += summary.time_zone_5;
        if (summary.time_total) {
          _self.totals.time_total += summary.time_total;
        }

        _self.totals.hr_zone_1 += summary.hr_zone_1;
        _self.totals.hr_zone_2 += summary.hr_zone_2;
        _self.totals.hr_zone_3 += summary.hr_zone_3;
        _self.totals.hr_zone_4 += summary.hr_zone_4;
        _self.totals.hr_zone_5 += summary.hr_zone_5;
        _self.totals.hr_zone_5 += summary.hr_zone_5;
        _self.totals.hr_total_time += summary.hr_total_time;

        _self.totals.speed_run_zone_1_sum += summary.speed_run_zone_1_sum;
        _self.totals.speed_run_zone_2_sum += summary.speed_run_zone_2_sum;
        _self.totals.speed_run_zone_3_sum += summary.speed_run_zone_3_sum;
        _self.totals.speed_run_zone_4_sum += summary.speed_run_zone_4_sum;
        _self.totals.speed_run_zone_5_sum += summary.speed_run_zone_5_sum;
        _self.totals.run_total_time += summary.run_total_time;

        _self.totals.speed_bike_zone_1_sum += summary.speed_bike_zone_1_sum;
        _self.totals.speed_bike_zone_2_sum += summary.speed_bike_zone_2_sum;
        _self.totals.speed_bike_zone_3_sum += summary.speed_bike_zone_3_sum;
        _self.totals.speed_bike_zone_4_sum += summary.speed_bike_zone_4_sum;
        _self.totals.speed_bike_zone_5_sum += summary.speed_bike_zone_5_sum;
        _self.totals.bike_total_time += summary.bike_total_time;

        if (summary.sport == "Radfahren") {
          // nur radfahren, sonst kommt auch watt vom laufen
          // diese auswertung ist aber nur fuer rad
          _self.totals.bp_5 = Math.max(_self.totals.bp_5, summary.bp_5);
          _self.totals.bp_10 = Math.max(_self.totals.bp_10, summary.bp_10);
          _self.totals.bp_15 = Math.max(_self.totals.bp_15, summary.bp_15);
          _self.totals.bp_30 = Math.max(_self.totals.bp_30, summary.bp_30);
          _self.totals.bp_60 = Math.max(_self.totals.bp_60, summary.bp_60);
          _self.totals.bp_90 = Math.max(_self.totals.bp_90, summary.bp_90);
          _self.totals.bp_120 = Math.max(_self.totals.bp_120, summary.bp_120);
          _self.totals.bp_180 = Math.max(_self.totals.bp_180, summary.bp_180);
          _self.totals.bp_240 = Math.max(_self.totals.bp_240, summary.bp_240);
          _self.totals.bp_300 = Math.max(_self.totals.bp_300, summary.bp_300);
          _self.totals.bp_360 = Math.max(_self.totals.bp_360, summary.bp_360);
          _self.totals.bp_420 = Math.max(_self.totals.bp_420, summary.bp_420);
          _self.totals.bp_480 = Math.max(_self.totals.bp_480, summary.bp_480);
          _self.totals.bp_540 = Math.max(_self.totals.bp_540, summary.bp_540);
          _self.totals.bp_600 = Math.max(_self.totals.bp_600, summary.bp_600);
          _self.totals.bp_720 = Math.max(_self.totals.bp_720, summary.bp_720);
          _self.totals.bp_840 = Math.max(_self.totals.bp_840, summary.bp_840);
          _self.totals.bp_960 = Math.max(_self.totals.bp_960, summary.bp_960);
          _self.totals.bp_1080 = Math.max(_self.totals.bp_1080, summary.bp_1080);
          _self.totals.bp_1200 = Math.max(_self.totals.bp_1200, summary.bp_1200);
          _self.totals.bp_1500 = Math.max(_self.totals.bp_1500, summary.bp_1500);
          _self.totals.bp_1800 = Math.max(_self.totals.bp_1800, summary.bp_1800);
          _self.totals.bp_2100 = Math.max(_self.totals.bp_2100, summary.bp_2100);
          _self.totals.bp_2400 = Math.max(_self.totals.bp_2400, summary.bp_2400);
          _self.totals.bp_2700 = Math.max(_self.totals.bp_2700, summary.bp_2700);
          _self.totals.bp_3000 = Math.max(_self.totals.bp_3000, summary.bp_3000);
          _self.totals.bp_3300 = Math.max(_self.totals.bp_3300, summary.bp_3300);
          _self.totals.bp_3600 = Math.max(_self.totals.bp_3600, summary.bp_3600);
          _self.totals.bp_4200 = Math.max(_self.totals.bp_4200, summary.bp_4200);
          _self.totals.bp_4800 = Math.max(_self.totals.bp_4800, summary.bp_4800);
          _self.totals.bp_5400 = Math.max(_self.totals.bp_5400, summary.bp_5400);
          _self.totals.bp_6000 = Math.max(_self.totals.bp_6000, summary.bp_6000);
          _self.totals.bp_7200 = Math.max(_self.totals.bp_7200, summary.bp_7200);
          _self.totals.bp_9000 = Math.max(_self.totals.bp_9000, summary.bp_9000);
          _self.totals.bp_10800 = Math.max(_self.totals.bp_10800, summary.bp_10800);
          _self.totals.bp_14400 = Math.max(_self.totals.bp_14400, summary.bp_14400);
          _self.totals.bp_18000 = Math.max(_self.totals.bp_18000, summary.bp_18000);
        }

        _self.totals.speed_bike_5 = Math.max(_self.totals.speed_bike_5, summary.speed_bike_5);
        _self.totals.speed_bike_10 = Math.max(_self.totals.speed_bike_10, summary.speed_bike_10);
        _self.totals.speed_bike_15 = Math.max(_self.totals.speed_bike_15, summary.speed_bike_15);
        _self.totals.speed_bike_30 = Math.max(_self.totals.speed_bike_30, summary.speed_bike_30);
        _self.totals.speed_bike_60 = Math.max(_self.totals.speed_bike_60, summary.speed_bike_60);
        _self.totals.speed_bike_90 = Math.max(_self.totals.speed_bike_90, summary.speed_bike_90);
        _self.totals.speed_bike_120 = Math.max(_self.totals.speed_bike_120, summary.speed_bike_120);
        _self.totals.speed_bike_180 = Math.max(_self.totals.speed_bike_180, summary.speed_bike_180);
        _self.totals.speed_bike_240 = Math.max(_self.totals.speed_bike_240, summary.speed_bike_240);
        _self.totals.speed_bike_300 = Math.max(_self.totals.speed_bike_300, summary.speed_bike_300);
        _self.totals.speed_bike_360 = Math.max(_self.totals.speed_bike_360, summary.speed_bike_360);
        _self.totals.speed_bike_420 = Math.max(_self.totals.speed_bike_420, summary.speed_bike_420);
        _self.totals.speed_bike_480 = Math.max(_self.totals.speed_bike_480, summary.speed_bike_480);
        _self.totals.speed_bike_540 = Math.max(_self.totals.speed_bike_540, summary.speed_bike_540);
        _self.totals.speed_bike_600 = Math.max(_self.totals.speed_bike_600, summary.speed_bike_600);
        _self.totals.speed_bike_720 = Math.max(_self.totals.speed_bike_720, summary.speed_bike_720);
        _self.totals.speed_bike_840 = Math.max(_self.totals.speed_bike_840, summary.speed_bike_840);
        _self.totals.speed_bike_960 = Math.max(_self.totals.speed_bike_960, summary.speed_bike_960);
        _self.totals.speed_bike_1080 = Math.max(_self.totals.speed_bike_1080, summary.speed_bike_1080);
        _self.totals.speed_bike_1200 = Math.max(_self.totals.speed_bike_1200, summary.speed_bike_1200);
        _self.totals.speed_bike_1500 = Math.max(_self.totals.speed_bike_1500, summary.speed_bike_1500);
        _self.totals.speed_bike_1800 = Math.max(_self.totals.speed_bike_1800, summary.speed_bike_1800);
        _self.totals.speed_bike_2100 = Math.max(_self.totals.speed_bike_2100, summary.speed_bike_2100);
        _self.totals.speed_bike_2400 = Math.max(_self.totals.speed_bike_2400, summary.speed_bike_2400);
        _self.totals.speed_bike_2700 = Math.max(_self.totals.speed_bike_2700, summary.speed_bike_2700);
        _self.totals.speed_bike_3000 = Math.max(_self.totals.speed_bike_3000, summary.speed_bike_3000);
        _self.totals.speed_bike_3300 = Math.max(_self.totals.speed_bike_3300, summary.speed_bike_3300);
        _self.totals.speed_bike_3600 = Math.max(_self.totals.speed_bike_3600, summary.speed_bike_3600);
        _self.totals.speed_bike_4200 = Math.max(_self.totals.speed_bike_4200, summary.speed_bike_4200);
        _self.totals.speed_bike_4800 = Math.max(_self.totals.speed_bike_4800, summary.speed_bike_4800);
        _self.totals.speed_bike_5400 = Math.max(_self.totals.speed_bike_5400, summary.speed_bike_5400);
        _self.totals.speed_bike_6000 = Math.max(_self.totals.speed_bike_6000, summary.speed_bike_6000);
        _self.totals.speed_bike_7200 = Math.max(_self.totals.speed_bike_7200, summary.speed_bike_7200);
        _self.totals.speed_bike_9000 = Math.max(_self.totals.speed_bike_9000, summary.speed_bike_9000);
        _self.totals.speed_bike_10800 = Math.max(_self.totals.speed_bike_10800, summary.speed_bike_10800);
        _self.totals.speed_bike_14400 = Math.max(_self.totals.speed_bike_14400, summary.speed_bike_14400);
        _self.totals.speed_bike_18000 = Math.max(_self.totals.speed_bike_18000, summary.speed_bike_18000);

        _self.totals.speed_run_5 = Math.max(_self.totals.speed_run_5, summary.speed_run_5);
        _self.totals.speed_run_10 = Math.max(_self.totals.speed_run_10, summary.speed_run_10);
        _self.totals.speed_run_15 = Math.max(_self.totals.speed_run_15, summary.speed_run_15);
        _self.totals.speed_run_30 = Math.max(_self.totals.speed_run_30, summary.speed_run_30);
        _self.totals.speed_run_60 = Math.max(_self.totals.speed_run_60, summary.speed_run_60);
        _self.totals.speed_run_90 = Math.max(_self.totals.speed_run_90, summary.speed_run_90);
        _self.totals.speed_run_120 = Math.max(_self.totals.speed_run_120, summary.speed_run_120);
        _self.totals.speed_run_180 = Math.max(_self.totals.speed_run_180, summary.speed_run_180);
        _self.totals.speed_run_240 = Math.max(_self.totals.speed_run_240, summary.speed_run_240);
        _self.totals.speed_run_300 = Math.max(_self.totals.speed_run_300, summary.speed_run_300);
        _self.totals.speed_run_360 = Math.max(_self.totals.speed_run_360, summary.speed_run_360);
        _self.totals.speed_run_420 = Math.max(_self.totals.speed_run_420, summary.speed_run_420);
        _self.totals.speed_run_480 = Math.max(_self.totals.speed_run_480, summary.speed_run_480);
        _self.totals.speed_run_540 = Math.max(_self.totals.speed_run_540, summary.speed_run_540);
        _self.totals.speed_run_600 = Math.max(_self.totals.speed_run_600, summary.speed_run_600);
        _self.totals.speed_run_720 = Math.max(_self.totals.speed_run_720, summary.speed_run_720);
        _self.totals.speed_run_840 = Math.max(_self.totals.speed_run_840, summary.speed_run_840);
        _self.totals.speed_run_960 = Math.max(_self.totals.speed_run_960, summary.speed_run_960);
        _self.totals.speed_run_1080 = Math.max(_self.totals.speed_run_1080, summary.speed_run_1080);
        _self.totals.speed_run_1200 = Math.max(_self.totals.speed_run_1200, summary.speed_run_1200);
        _self.totals.speed_run_1500 = Math.max(_self.totals.speed_run_1500, summary.speed_run_1500);
        _self.totals.speed_run_1800 = Math.max(_self.totals.speed_run_1800, summary.speed_run_1800);
        _self.totals.speed_run_2100 = Math.max(_self.totals.speed_run_2100, summary.speed_run_2100);
        _self.totals.speed_run_2400 = Math.max(_self.totals.speed_run_2400, summary.speed_run_2400);
        _self.totals.speed_run_2700 = Math.max(_self.totals.speed_run_2700, summary.speed_run_2700);
        _self.totals.speed_run_3000 = Math.max(_self.totals.speed_run_3000, summary.speed_run_3000);
        _self.totals.speed_run_3300 = Math.max(_self.totals.speed_run_3300, summary.speed_run_3300);
        _self.totals.speed_run_3600 = Math.max(_self.totals.speed_run_3600, summary.speed_run_3600);
        _self.totals.speed_run_4200 = Math.max(_self.totals.speed_run_4200, summary.speed_run_4200);
        _self.totals.speed_run_4800 = Math.max(_self.totals.speed_run_4800, summary.speed_run_4800);
        _self.totals.speed_run_5400 = Math.max(_self.totals.speed_run_5400, summary.speed_run_5400);
        _self.totals.speed_run_6000 = Math.max(_self.totals.speed_run_6000, summary.speed_run_6000);
        _self.totals.speed_run_7200 = Math.max(_self.totals.speed_run_7200, summary.speed_run_7200);
        _self.totals.speed_run_9000 = Math.max(_self.totals.speed_run_9000, summary.speed_run_9000);
        _self.totals.speed_run_10800 = Math.max(_self.totals.speed_run_10800, summary.speed_run_10800);
        _self.totals.speed_run_14400 = Math.max(_self.totals.speed_run_14400, summary.speed_run_14400);
        _self.totals.speed_run_18000 = Math.max(_self.totals.speed_run_18000, summary.speed_run_18000);

        _self.totals.hr_5 = Math.max(_self.totals.hr_5, summary.hr_5);
        _self.totals.hr_10 = Math.max(_self.totals.hr_10, summary.hr_10);
        _self.totals.hr_15 = Math.max(_self.totals.hr_15, summary.hr_15);
        _self.totals.hr_30 = Math.max(_self.totals.hr_30, summary.hr_30);
        _self.totals.hr_60 = Math.max(_self.totals.hr_60, summary.hr_60);
        _self.totals.hr_90 = Math.max(_self.totals.hr_90, summary.hr_90);
        _self.totals.hr_120 = Math.max(_self.totals.hr_120, summary.hr_120);
        _self.totals.hr_180 = Math.max(_self.totals.hr_180, summary.hr_180);
        _self.totals.hr_240 = Math.max(_self.totals.hr_240, summary.hr_240);
        _self.totals.hr_300 = Math.max(_self.totals.hr_300, summary.hr_300);
        _self.totals.hr_360 = Math.max(_self.totals.hr_360, summary.hr_360);
        _self.totals.hr_420 = Math.max(_self.totals.hr_420, summary.hr_420);
        _self.totals.hr_480 = Math.max(_self.totals.hr_480, summary.hr_480);
        _self.totals.hr_540 = Math.max(_self.totals.hr_540, summary.hr_540);
        _self.totals.hr_600 = Math.max(_self.totals.hr_600, summary.hr_600);
        _self.totals.hr_720 = Math.max(_self.totals.hr_720, summary.hr_720);
        _self.totals.hr_840 = Math.max(_self.totals.hr_840, summary.hr_840);
        _self.totals.hr_960 = Math.max(_self.totals.hr_960, summary.hr_960);
        _self.totals.hr_1080 = Math.max(_self.totals.hr_1080, summary.hr_1080);
        _self.totals.hr_1200 = Math.max(_self.totals.hr_1200, summary.hr_1200);
        _self.totals.hr_1500 = Math.max(_self.totals.hr_1500, summary.hr_1500);
        _self.totals.hr_1800 = Math.max(_self.totals.hr_1800, summary.hr_1800);
        _self.totals.hr_2100 = Math.max(_self.totals.hr_2100, summary.hr_2100);
        _self.totals.hr_2400 = Math.max(_self.totals.hr_2400, summary.hr_2400);
        _self.totals.hr_2700 = Math.max(_self.totals.hr_2700, summary.hr_2700);
        _self.totals.hr_3000 = Math.max(_self.totals.hr_3000, summary.hr_3000);
        _self.totals.hr_3300 = Math.max(_self.totals.hr_3300, summary.hr_3300);
        _self.totals.hr_3600 = Math.max(_self.totals.hr_3600, summary.hr_3600);
        _self.totals.hr_4200 = Math.max(_self.totals.hr_4200, summary.hr_4200);
        _self.totals.hr_4800 = Math.max(_self.totals.hr_4800, summary.hr_4800);
        _self.totals.hr_5400 = Math.max(_self.totals.hr_5400, summary.hr_5400);
        _self.totals.hr_6000 = Math.max(_self.totals.hr_6000, summary.hr_6000);
        _self.totals.hr_7200 = Math.max(_self.totals.hr_7200, summary.hr_7200);
        _self.totals.hr_9000 = Math.max(_self.totals.hr_9000, summary.hr_9000);
        _self.totals.hr_10800 = Math.max(_self.totals.hr_10800, summary.hr_10800);
        _self.totals.hr_14400 = Math.max(_self.totals.hr_14400, summary.hr_14400);
        _self.totals.hr_18000 = Math.max(_self.totals.hr_18000, summary.hr_18000);
      });
      this.key++;
    },
    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },
    formattedTimeHH(seconds) {
      let days = 0;
      let ret = "";
      let tmpSeconds = seconds;

      while (tmpSeconds > 24 * 60 * 60) {
        days += 1;
        tmpSeconds -= 24 * 60 * 60;
      }
      if (days > 0) {
        ret += days + " ";
        if (days > 1) {
          ret += "Tage";
        } else {
          ret += "Tag";
        }
      }
      return ret + " " + this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },
    formattedTimeSlash(seconds) {
      let days = 0;
      let ret = "";
      let tmpSeconds = seconds;
      while (tmpSeconds > 24 * 60 * 60) {
        days += 1;
        tmpSeconds -= 24 * 60 * 60;
      }
      if (days > 0) {
        ret += days + "/";
      }

      return ret + this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },
    timePercentage(seconds, totalTime) {
      if (totalTime > 0) {
        return (100 / totalTime) * seconds;
      } else return 0;
    },
    hrPercentage(hr, totalTime) {
      if (totalTime > 0) {
        return (100 / totalTime) * hr;
      } else return 0;
    },
    previousWeek() {
      this.tdChartLoading = true;
      this.dChartLoading = true;
      this.tdiChartLoading = true;
      this.pssChartLoading = true;
      this.healthChartLoading = true;
      this.scatterSpeedChartLoading = true;
      this.scatterPowerChartLoading = true;
      let from = this.$moment(this.customFrom);
      let until = this.$moment(this.customTill);

      const daysBetween = until.diff(from, "days");
      this.customFrom = from.add(-daysBetween, "days").format("YYYY-MM-DD");
      this.customTill = until.add(-daysBetween, "days").format("YYYY-MM-DD");

      this.refresh();
    },
    nextWeek() {
      this.healthChartLoading = true;
      this.tdChartLoading = true;
      this.dChartLoading = true;
      this.tdiChartLoading = true;
      this.pssChartLoading = true;
      this.scatterSpeedChartLoading = true;
      this.scatterPowerChartLoading = true;

      let from = this.$moment(this.customFrom);
      let until = this.$moment(this.customTill);

      const daysBetween = until.diff(from, "days");
      this.customFrom = from.add(daysBetween, "days").format("YYYY-MM-DD");
      this.customTill = until.add(daysBetween, "days").format("YYYY-MM-DD");

      this.refresh();
    },
    colWitdthOfTotalsGraphs() {
      let num = 0;
      num += this.totals.speed_run_5 > 0 ? 1 : 0;
      //num += this.totals.speed_bike_5 > 0 ? 1 : 0;
      num += this.totals.bp_5 > 0 ? 1 : 0;
      num += this.totals.hr_5 > 0 ? 1 : 0;

      if (num == 4) {
        return 4;
      }
      if (num == 3) {
        return 4;
      }
      if (num == 2) {
        return 6;
      } else {
        return 12;
      }
    },

    sum(items, prop) {
      if (items.length > 0) {
        return items.reduce(function (a, b) {
          return a + b[prop];
        }, 0);
      } else return 0;
    },
    getFormattedWeekFrom() {
      return this.customFromMoment.format("DD.MM.YYYY");
    },
    getFormattedWeekTill() {
      return this.customTillMoment.format("DD.MM.YYYY");
    },

    async refresh() {
      const _self = this;

      this.loaded = false;

      this.tdChartLoading = true;
      this.dChartLoading = true;
      this.tdiChartLoading = true;
      this.pssChartLoading = true;
      this.healthChartLoading = true;
      this.scatterSpeedChartLoading = true;
      this.scatterPowerChartLoading = true;

      let from = undefined;
      let till = undefined;

      this.smallSummaries = [];

      this.customFromMoment = _self.$moment(this.customFrom).startOf("day");
      this.customTillMoment = _self.$moment(this.customTill).endOf("day");
      from = this.customFromMoment / 1000;
      till = this.customTillMoment / 1000;

      if (parseInt(this.$route.query.from) != parseInt(from) || parseInt(this.$route.query.until) != parseInt(till)) {
        if (this.$route.query.from > 0 && this.$route.query.until > 0) {
          this.$router.push({
            query: { from: from, until: till },
          });
        }
      } else {
        this.$nextTick(() => {
          const element = document.getElementById("auswertung");
          element.scrollIntoView({ block: "start" });

          var scrolledY = window.scrollY;

          if (scrolledY) {
            window.scroll(
              0,
              scrolledY - 75 - window.getComputedStyle(document.getElementById("nav-bar")).paddingTop.slice(0, -2)
            );
          }
        });
      }

      this.loadWorkouts();

      const healthUrl = `${this.$host}/health/from/${from * 1000}/until/${till * 1000}`;

      const [healthRes] = await Promise.all([axios.get(healthUrl)]);

      _self.health = healthRes.data;

      await this.updateThresholds();
      _self.summaries = [];
      _self.smallSummaries = [];
      axios.get(this.$host + "/workout/analysis/summary" + "/from/" + from + "/till/" + till).then(function (res) {
        _self.loaded = true;

        _self.summaries = res.data;
        _self.smallSummaries = res.data; //todo: smallSummaries sind hier gleich summaries..raus?

        // only bike and with hr under 75%
        _self.smallSummariesLIT = res.data.filter((summary) => {
          return summary.avg_heart_rate < 150;
        });

        _self.key++;

        _self.hrDataDist = [];
        for (let i = 0; i <= 21; i++) {
          _self.hrDataDist[i] = 0;
          _self.runDataDist[i] = 0;

          _self.powerDataDist[i] = 0;
        }

        for (const summary of _self.summaries) {
          for (let i = 0; i <= 21; i++) {
            if (summary.hrDataDist && summary.hrDataDist[i]) {
              if (i < 5) {
                _self.hrDataDist[0] += summary.hrDataDist[i];
              } else {
                _self.hrDataDist[i - 4] += summary.hrDataDist[i];
              }
            }
            if (summary.powerDataDist && summary.powerDataDist[i]) {
              _self.powerDataDist[i] += summary.powerDataDist[i];
            }
            if (summary.runDataDist && summary.runDataDist[i]) {
              _self.runDataDist[i] += summary.runDataDist[i];
            }
          }
        }
        _self.key++;
        _self.$nextTick(() => {
          const element = document.getElementById("auswertung");
          element.scrollIntoView({ block: "start" });

          var scrolledY = window.scrollY;

          if (scrolledY) {
            window.scroll(
              0,
              scrolledY - 75 - window.getComputedStyle(document.getElementById("nav-bar")).paddingTop.slice(0, -2)
            );
          }
        });
        _self.computetotalTime();
      });
    },

    async updateThresholds() {
      const _self = this;

      // thresholds fuer auswertung setzen
      // dafuer muessen die minimalen und maximalen Thresholds im gewählten Zeitraum gefunden werden
      if (_self.thresholds) {
        let closest_start = _self.thresholds.reduce(function (prev, curr, currentIndex) {
          let result = { treshold: curr, index: currentIndex };
          if (!prev || (prev && !prev.threshold)) {
            return { threshold: curr, index: currentIndex };
          }

          return Math.abs(_self.$moment(curr.createdAt).utc().unix() + -(_self.customFromMoment / 1000)) <
            Math.abs(_self.$moment(prev.threshold.createdAt).utc().unix() - _self.customFromMoment / 1000)
            ? result
            : prev;
        });

        let closest_end = _self.thresholds.reduce(function (prev, curr, currentIndex) {
          if (!prev || (prev && !prev.threshold)) {
            return { threshold: curr, index: currentIndex };
          }
          let result = { treshold: curr, index: currentIndex };
          return Math.abs(_self.$moment(curr.createdAt).utc().unix() + -(_self.customTillMoment / 1000)) <
            Math.abs(_self.$moment(prev.threshold.createdAt).utc().unix() - _self.customTillMoment / 1000)
            ? result
            : prev;
        });

        const relevantThresholds = _self.thresholds.slice(closest_start.index, closest_end.index + 1);

        const maxThresholdHr = relevantThresholds.reduce((prev, current) => {
          return prev > current.maxHr ? prev : current.maxHr;
        }, 0);
        const maxThresholdFtp = relevantThresholds.reduce((prev, current) => {
          return prev > current.ftp ? prev : current.ftp;
        }, 0);
        const maxThresholdRun = relevantThresholds.reduce((prev, current) => {
          return prev > current.threshold_run ? prev : current.threshold_run;
        }, 0);

        const maxThresholdWeight = relevantThresholds.reduce((prev, current) => {
          return prev > current.weight ? prev : current.weight;
        }, 0);

        const minThresholdHr = relevantThresholds.reduce((prev, current) => {
          return prev < current.maxHr || current.maxHr == 0 ? prev : current.maxHr;
        }, 9999);
        const minThresholdWeight = relevantThresholds.reduce((prev, current) => {
          return prev < current.weight || current.weight == 0 ? prev : current.weight;
        }, 9999);
        const minThresholdFtp = relevantThresholds.reduce((prev, current) => {
          return prev < current.ftp || current.ftp == 0 ? prev : current.ftp;
        }, 9999);
        const minThresholdRun = relevantThresholds.reduce((prev, current) => {
          return prev < current.threshold_run || current.threshold_run == 0 ? prev : current.threshold_run;
        }, 9999);

        _self.beginThresholds = {
          ftp: minThresholdFtp,
          threshold_run: minThresholdRun,
          maxHr: minThresholdHr,
          weight: minThresholdWeight,
        };
        _self.endThresholds = {
          ftp: maxThresholdFtp,
          threshold_run: maxThresholdRun,
          maxHr: maxThresholdHr,
          weight: maxThresholdWeight,
        };
      }
    },

    async loadWorkouts() {
      const _self = this;
      this.workouts42d = [];

      let from = this.customFromMoment;
      let till = this.customTillMoment;

      this.workouts = axios
        .get(this.$host + "/workout/" + "from/" + from + "/until/" + till + "/done")
        .then(function (res) {
          _self.workouts = res.data;

          _self.workout_run_count = 0;
          _self.workout_bike_count = 0;
          _self.workout_strength_count = 0;
          _self.workout_swim_count = 0;
          _self.workout_activity_count = 0;

          _self.workout_run_duration = 0;
          _self.workout_bike_duration = 0;
          _self.workout_strength_duration = 0;
          _self.workout_swim_duration = 0;
          _self.workout_activity_duration = 0;

          _self.workout_run_distance = 0;
          _self.workout_bike_distance = 0;
          _self.workout_strength_distance = 0;
          _self.workout_swim_distance = 0;
          _self.workout_activity_distance = 0;

          _self.workouts.forEach(function (workout) {
            if (workout.sport == "Laufen") {
              _self.workout_run_count++;
              _self.workout_run_duration += workout.durationIs;
              _self.workout_run_distance += workout.distance;
            } else if (workout.sport == "Radfahren") {
              _self.workout_bike_count++;
              _self.workout_bike_duration += workout.durationIs;
              _self.workout_bike_distance += workout.distance;
            } else if (workout.sport == "Kraft") {
              _self.workout_strength_count++;
              _self.workout_strength_duration += workout.durationIs;
            } else if (workout.sport == "Schwimmen") {
              _self.workout_swim_count++;
              _self.workout_swim_duration += workout.durationIs;
              _self.workout_swim_distance += workout.distance;
            } else if (workout.sport == "Aktivität") {
              _self.workout_activity_count++;
              _self.workout_activity_duration += workout.durationIs;
              _self.workout_activity_distance += workout.distance;
            }
          });
        });
      axios
        .get(this.$host + "/workout/" + "from/" + _self.$moment(from).add(-42, "d") + "/until/" + till + "/done")
        .then(function (res) {
          _self.workouts42d = res.data;
          _self.key++;
        });
    },
  },
  watch: {
    $route() {
      this.loaded = false;
      this.active_menu = this.$route.params.page;
      this.tdChartLoading = true;
      this.dChartLoading = true;
      this.tdiChartLoading = true;
      this.pssChartLoading = true;
      this.healthChartLoading = true;
      this.scatterSpeedChartLoading = true;
      this.scatterPowerChartLoading = true;

      // TODO: IF from and until else month / quarter
      if (this.$route.query.from && this.$route.query.until) {
        this.customFrom = this.$moment.unix(this.$route.query.from).format("YYYY-MM-DD");
        this.customTill = this.$moment.unix(this.$route.query.until).format("YYYY-MM-DD");

        this.customFromMoment = this.$moment(this.customFrom).startOf("day");
        this.customTillMoment = this.$moment(this.customTill).endOf("day");
      } else {
        this.customFromMoment = this.$moment().add(-1, "month");
        this.customTillMoment = this.$moment();

        this.customFrom = this.customFromMoment.format("YYYY-MM-DD");
        this.customTill = this.customTillMoment.format("YYYY-MM-DD");
        const element = document.getElementById("auswertung");

        this.$router.replace({
          query: {
            from: this.$moment().add(-1, "month").startOf("day").unix(),
            until: this.$moment().endOf("day").unix(),
          },
        });
        element.scrollIntoView({ block: "start" });

        var scrolledY = window.scrollY;

        if (scrolledY) {
          window.scroll(
            0,
            scrolledY - 75 - window.getComputedStyle(document.getElementById("nav-bar")).paddingTop.slice(0, -2)
          );
        }
      }

      this.refresh();
    },
  },
  created: async function () {
    this.loaded = false;

    this.tdChartLoading = true;
    this.dChartLoading = true;
    this.tdiChartLoading = true;
    this.pssChartLoading = true;
    this.scatterSpeedChartLoading = true;
    this.scatterPowerChartLoading = true;
    this.loadStart = 0;

    if (this.$route.params.page) {
      this.active_menu = this.$route.params.page;
    }

    const _self = this;
    this.smallSummaries = [];

    this.customFromMoment = _self.$moment(this.customFrom).startOf("day");
    this.customTillMoment = _self.$moment(this.customTill).endOf("day");

    axios
      .get(this.$host + "/workout/analysis/power/year")
      .then(function (res) {
        _self.alltimetotals.bp = [];
        if (res.data) {
          for (const [key, value] of Object.entries(res.data)) {
            if (key != "_id") {
              _self.alltimetotals.bp.push(value);
            }
          }
          _self.key++;
        }
      })
      .catch(function (err) {
        console.log("ERR", err);
      });

    axios
      .get(this.$host + "/workout/analysis/runspeed/year")
      .then(function (res) {
        _self.alltimetotals.runspeed = [];
        if (res.data) {
          for (const [key, value] of Object.entries(res.data)) {
            if (key != "_id") {
              _self.alltimetotals.runspeed.push(value);
            }
          }
          _self.key++;
        }
      })
      .catch(function (err) {
        console.log("ERR", err);
      });

    axios
      .get(this.$host + "/workout/analysis/bikespeed/year")
      .then(function (res) {
        _self.alltimetotals.bikespeed = [];
        if (res.data) {
          for (const [key, value] of Object.entries(res.data)) {
            if (key != "_id") {
              _self.alltimetotals.bikespeed.push(value);
            }
          }
          _self.key++;
        }
      })
      .catch(function (err) {
        console.log("ERR", err);
      });

    axios
      .get(this.$host + "/workout/analysis/hr/year")
      .then(function (res) {
        _self.alltimetotals.hr = [];
        if (res.data) {
          for (const [key, value] of Object.entries(res.data)) {
            if (key != "_id") {
              _self.alltimetotals.hr.push(value);
            }
          }
          _self.key++;
        }
      })
      .catch(function (err) {
        console.log("ERR", err);
      });

    axios
      .get(this.$host + "/workout/analysis/power/fourtytwodays")
      .then(function (res) {
        _self.fourtyTwoDaysPower.bp = [];
        if (res.data) {
          for (const [key, value] of Object.entries(res.data)) {
            if (key != "_id") {
              _self.fourtyTwoDaysPower.bp.push(value);
            }
            _self.key++;
          }
        }
      })
      .catch(function (err) {
        console.log("ERR", err);
      });

    axios
      .get(this.$host + "/workout/analysis/runspeed/fourtytwodays")
      .then(function (res) {
        _self.fourtyTwoDaysPower.runspeed = [];
        if (res.data) {
          for (const [key, value] of Object.entries(res.data)) {
            if (key != "_id") {
              _self.fourtyTwoDaysPower.runspeed.push(value);
              _self.key++;
            }
          }
        }
      })
      .catch(function (err) {
        console.log("ERR", err);
      });

    axios
      .get(this.$host + "/workout/analysis/bikespeed/fourtytwodays")
      .then(function (res) {
        _self.fourtyTwoDaysPower.bikespeed = [];
        if (res.data) {
          for (const [key, value] of Object.entries(res.data)) {
            if (key != "_id") {
              _self.fourtyTwoDaysPower.bikespeed.push(value);
              _self.key++;
            }
          }
        }
      })
      .catch(function (err) {
        console.log("ERR", err);
      });

    axios
      .get(this.$host + "/workout/analysis/hr/fourtytwodays")
      .then(function (res) {
        _self.fourtyTwoDaysPower.hr = [];
        if (res.data) {
          for (const [key, value] of Object.entries(res.data)) {
            if (key != "_id") {
              _self.fourtyTwoDaysPower.hr.push(value);
              _self.key++;
            }
          }
        }
      })
      .catch(function (err) {
        console.log("ERR", err);
      });
    _self.key++;
    this.thresholds = await (await axios.get(this.$host + "/user/thresholds")).data;
    await this.updateThresholds();
  },
};
</script>

<style scoped>
.date-selection-wrapper {
  margin-bottom: 2rem;
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.date-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.date-range-buttons {
  display: flex;
  gap: 0.5rem;
}

.date-navigation {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.date-inputs {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  background-color: #e9ecef;
  color: #495057;
  font-weight: 500;
  transition: all 0.3s ease;
}

.btn:hover {
  background-color: #ced4da;
}

.btn-icon {
  padding: 0.5rem;
  border-radius: 50%;
}

.date-separator {
  font-weight: bold;
  color: #6c757d;
}

input[type="date"] {
  border: 1px solid #ced4da;
  border-radius: 6px;
  padding: 0.5rem;
  font-size: 1rem;
  color: #495057;
  background-color: white;
  transition: border-color 0.3s ease;
}

input[type="date"]:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

@media (max-width: 768px) {
  .date-selection-container {
    flex-direction: column;
  }

  .date-range-buttons,
  .date-navigation {
    width: 100%;
  }

  .date-inputs {
    flex-direction: column;
    align-items: stretch;
  }
}
</style>
