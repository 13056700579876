import { Capacitor } from "@capacitor/core";
import { LocalNotifications } from "@capacitor/local-notifications";
import { PushNotifications } from '@capacitor/push-notifications';

class NotificationService {
  async requestPermissions() {
    if (Capacitor.getPlatform() !== 'web') {
      await LocalNotifications.requestPermissions();
    }
  }

  async showNotification(title, body) {
    if (Capacitor.getPlatform() === 'web') {
      console.log('Notification (web):', title, body);
      return;
    }

    await this.requestPermissions();
    await LocalNotifications.schedule({
      notifications: [
        {
          title: title,
          body: body,
          id: new Date().getTime(),
          schedule: { at: new Date(Date.now() + 1000) },
          sound: null,
          attachments: null,
          actionTypeId: "",
          extra: null,
        },
      ],
    });
  }

  async showWorkoutImportedNotification() {
    await this.showNotification("Workout Imported", "A new workout has been successfully imported.");
  }

  async initializePushNotifications() {
    if (Capacitor.getPlatform() === 'web') {
      console.log('Push notifications are not available on web');
      return;
    }

    await PushNotifications.requestPermissions();
    await PushNotifications.register();

    PushNotifications.addListener('registration', (token) => {
      console.log('Push registration success, token: ' + token.value);
      // Send this token to your server
    });

    PushNotifications.addListener('registrationError', (error) => {
      console.error('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener('pushNotificationReceived', (notification) => {
      console.log('Push notification received: ' + JSON.stringify(notification));
      this.handleRemoteNotification(notification);
    });

    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      console.log('Push notification action performed', notification.actionId, notification.inputValue);
    });
  }

  async handleRemoteNotification(notification) {
    // Handle different types of notifications based on the payload
    if (notification.data.type === 'new_workout') {
      await this.showNotification('New Workout Available', 'A new workout from Garmin has been received.');
      // Trigger workout import or any other necessary action
    }
    // Add more conditions for different types of notifications
  }

  async showNewGarminWorkoutNotification() {
    await this.showNotification("New Garmin Workout", "A new workout from Garmin is available for processing.");
  }

  // Add more specific notification methods here as needed
}

export default new NotificationService();