<template>
  <div class="weather-forecast" :class="{ 'single-day': singleDay }">
    <div v-for="(day, date) in forecast" :key="date" class="forecast-day">
      <h5 v-if="!singleDay">{{ formatDate(date) }}</h5>
      <div class="forecast-items">
        <div v-for="(item, index) in day" :key="index" class="forecast-item">
          <div class="forecast-item-content">
            <img :src="`https://assets.pushinglimits.club/weather/${item.icon}@2x.png`" :alt="item.text" />
            <div class="forecast-details">
              <span class="time">{{ formatTime(item.time) }}</span>
              <span class="temperature">{{ item.text.split(" ")[0] }}</span>
              <small v-if="item.wind">༄ {{ Math.round(item.wind) }}km/h</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WeatherForecast",
  props: {
    forecast: {
      type: Object,
      required: true,
    },
    singleDay: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatDate(dateString) {
      return this.$moment(dateString).format("dddd, D. MMMM");
    },
    formatTime(timeString) {
      return this.$moment(timeString, "HH:mm:ss").format("HH:mm");
    },
  },
};
</script>

<style scoped>
.weather-forecast {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  width: 100%;
}

.forecast-day {
  flex: 1;
  border-radius: 8px;
  padding: 1rem;
  padding-top: 0;
}

.forecast-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
}

.forecast-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.forecast-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.forecast-item img {
  width: 40px;
  height: 40px;
  margin-bottom: 0.5rem;
}

.forecast-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.time {
  font-weight: bold;
  font-size: 0.9em;
}

.temperature {
  font-size: 1em;
}

/* Single day styles */
.single-day .forecast-day {
  width: 100%;
}

.single-day .forecast-items {
  justify-content: space-between;
}

@media (max-width: 767px) {
  .weather-forecast {
    flex-direction: column;
  }

  .forecast-items {
    overflow-x: auto;
  }

  .forecast-item {
    min-width: 80px;
  }
}
</style>
