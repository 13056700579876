import { Plugins, Capacitor } from '@capacitor/core';
import axios from 'axios';
import LZString from 'lz-string';
import convertToFitJson from '../js/apple_fit_json_converter';
import moment from 'moment';

const { AppleWorkoutPlugin } = Plugins;

class AppleHealthService {
  static async fetchAppleWorkouts() {
    try {
      await AppleWorkoutPlugin.authorize();

      const sixWeeksAgo = new Date(Date.now() - 1000 * 60 * 60 * 24 * 42).toISOString();
      const result = await AppleWorkoutPlugin.fetchWorkoutsOnly({ lastFetchedDate: sixWeeksAgo });

      const importedWorkouts = await axios.get(`https://pushinglimits.club/api/workout/apple_workouts?from=${sixWeeksAgo}`);
      console.log("Imported workouts:", importedWorkouts.data.workouts.length);

      const importedWorkoutIds = new Set(importedWorkouts.data.workouts.map((w) => w.appleId));

      const appleWorkouts = result.workouts.map((workout) => ({
        ...workout,
        isImported: importedWorkoutIds.has(workout.id),
      }));

      const hasNewWorkouts = appleWorkouts.some((workout) => !workout.isImported);

      return { appleWorkouts, hasNewWorkouts };
    } catch (error) {
      console.error("Error fetching Apple Watch workouts:" + error, error);
      throw error;
    }
  }

  static async importWorkout(workout, name) {
    try {
      if (workout && !workout.id) {
        console.log("Workout has no id, using uuid", workout, name);
        workout.id = workout.uuid;
      }

      console.log("Importing workout " + name, workout.id, workout);
      const completeWorkout = await AppleWorkoutPlugin.fetchWorkout({ workoutId: workout.id });
      const fitJson = convertToFitJson(completeWorkout.workout);
      const compressedWorkout = LZString.compressToBase64(JSON.stringify(fitJson));

      await axios.post(`https://pushinglimits.club/api/workout/upload_apple`, {
        workout: compressedWorkout,
        appleId: workout.id,
        name: name || `${this.getWorkoutTypeName(workout.workoutActivityType)}`,
      });

      return true;
    } catch (error) {
      console.error("Error importing workout:", error);
      throw error;
    }
  }

  static getWorkoutTypeName(type) {
    const types = {
      37: "Laufen",
      24: "Laufen",
      13: "Radfahren",
      46: "Schwimmen",
      81: "Schwimmen",
      20: "Kraft",
      50: "Kraft",
      59: "Kraft",
      63: "Kraft",
    };
    return types[type] || "Aktivität";
  }

  static async syncWorkoutsToApple(force = false, thresholds) {
    try {
      if (Capacitor.getPlatform() !== 'ios') {
        return; // Exit if not on iOS
      }

      // force is true for manual sync or when there is no updated workout (i.e. on deletion)
      let workouts = [];
      if (!force) {
        workouts = await axios.get("https://pushinglimits.club/api/workout/sync_apple_workouts");
      } else {
        console.log("Force sync to Apple Health");
        workouts = await axios.get("https://pushinglimits.club/api/workout/sync_apple_workouts?force=true");
      }

      if (workouts.data && workouts.data.workouts) {
        await AppleWorkoutPlugin.requestWorkoutKitAuthorization();

        if (workouts.data.workouts.length > 0) {
          // Reschedule all workouts
          await AppleWorkoutPlugin.removeAllScheduledWorkouts();
          console.log(
            "There is at least one new or changed workout. Reschedule all " + workouts.data.workouts.length
          );
        }

        let scheduledWorkoutIds = [];
        for (const workout of workouts.data.workouts) {
          try {
            // 13 = Radfahren, 37 = Laufen
            const activityType = workout.sport == "Radfahren" ? "13" : "37";

            const options = {
              workoutName: workout.name,
              date: moment(workout.date).format("YYYY-MM-DDTHH:mm:ss[Z]"),
              useHeartRate: false,
              ftp: thresholds.ftp,
              thresholdSpeed: 3600 / thresholds.threshold_run, // in km/h
              activityType: activityType,
              location: "outdoor",
            };

            console.log("Scheduling workout with options:", options);

            const result = await AppleWorkoutPlugin.transformAndScheduleWorkout({
              data: workout.workoutFile,
              options: options,
            });

            console.log("Workout scheduled successfully!!!", result);

            scheduledWorkoutIds.push({ workout: workout._id, appleUuid: result.uuid });
          } catch (error) {
            console.error("Error scheduling workout:", error, workout);
          }
        }

        try {
          console.log("Mark scheduled workouts", scheduledWorkoutIds);

          await axios.post("https://pushinglimits.club/api/workout/sync_apple_workouts", {
            workouts: scheduledWorkoutIds,
          });
        } catch (error) {
          console.error("Error marking scheduled workouts:", error, scheduledWorkoutIds);
        }
      }
    } catch (error) {
      console.error("Error syncing workouts to Apple:", error);
      throw error;
    }
  }
}

export default AppleHealthService;